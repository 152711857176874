import { useMediaQuery } from '@mui/material';
import React from 'react';
import OtpInput from 'react-otp-input';

import { pxToRem } from 'components/theme/typography';

type Props = {
    onCodeChange: (code: string) => void;
    code: string;
};

const CodeInput = ({ onCodeChange, code }: Props) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    return (
        <OtpInput
            value={code}
            onChange={onCodeChange}
            numInputs={4}
            inputStyle={{
                width: '100%',
                paddingBlock: pxToRem(isMobile ? 22 : 24),
                paddingInline: pxToRem(isMobile ? 14 : 16),
                fontSize: pxToRem(isMobile ? 18 : 20),
                fontWeight: 700,
                lineHeight: 1.6,
                color: '#2C2D3B',
                borderRadius: pxToRem(8),
                border: '1px solid #DBDBDB',
            }}
            containerStyle={{
                marginInline: 'auto',
                marginBlock: pxToRem(24),
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: pxToRem(10),
                maxWidth: pxToRem(350),
            }}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
        />
    );
};

export default CodeInput;
