import {
    OperationsChangeOfConditionStatsDataset,
    OperationsDocumentationRateStatsDataset,
    OperationsFallDocumentationIncompleteStatsDataset,
    OperationsFallsStatsDataset,
    OperationsHospitalTransferStatsDataset,
    OperationsOverdueAssessmentStatsDataset,
    OperationsResidentNotesStatsDataset,
    OperationsShowerStatsDataset,
    OperationsUnscheduledCareStatsDataset,
} from 'types/operationsDashboard';

import { accumulator, changeOfConditionAccumulator } from 'pages/OperationsDashboard/helpers';

export const TABLE_SECTION_ACCUMULATORS = {
    DOCUMENTATION_RATE: {
        accumulator: (data: OperationsDocumentationRateStatsDataset[]) =>
            accumulator<OperationsDocumentationRateStatsDataset>(data, 'tasksCompleteCount'),
        accumulator2: (data: OperationsDocumentationRateStatsDataset[]) =>
            accumulator<OperationsDocumentationRateStatsDataset>(data, 'totalTasksCount'),
    },
    FALL_DOCUMENTATION_INCOMPLETE: {
        accumulator: (data: OperationsFallDocumentationIncompleteStatsDataset[]) =>
            accumulator<OperationsFallDocumentationIncompleteStatsDataset>(data, 'incompleteCount'),
    },
    FALLS: {
        accumulator: (data: OperationsFallsStatsDataset[]) =>
            accumulator<OperationsFallsStatsDataset>(data, 'fallCount'),
    },
    HOSPITAL_TRANSFER: {
        accumulator: (data: OperationsHospitalTransferStatsDataset[]) =>
            accumulator<OperationsHospitalTransferStatsDataset>(data, 'hospitalVisitCount'),
    },
    RESIDENT_NOTES: {
        accumulator: (data: OperationsResidentNotesStatsDataset[]) =>
            accumulator<OperationsResidentNotesStatsDataset>(data, 'residentNoteCount'),
    },
    SHOWER: {
        accumulator: (data: OperationsShowerStatsDataset[]) =>
            accumulator<OperationsShowerStatsDataset>(data, 'residentMissedShowerCount'),
    },
    UNSCHEDULED_CARE: {
        accumulator: (data: OperationsUnscheduledCareStatsDataset[]) =>
            accumulator<OperationsUnscheduledCareStatsDataset>(data, 'unscheduledCareCount'),
    },
    CHANGE_OF_CONDITION: {
        accumulator: (data: OperationsChangeOfConditionStatsDataset[]) => changeOfConditionAccumulator(data),
    },
    OVERDUE_ASSESSMENT: {
        accumulator: (data: OperationsOverdueAssessmentStatsDataset[]) =>
            accumulator<OperationsOverdueAssessmentStatsDataset>(data, 'overdueAssessmentCount'),
    },
};

export const CHART_SECTION_ACCUMULATORS = {
    DOCUMENTATION_RATE: {
        accumulator: (data: OperationsDocumentationRateStatsDataset[]) =>
            accumulator<OperationsDocumentationRateStatsDataset>(data, 'tasksCompleteCount'),
        accumulator2: (data: OperationsDocumentationRateStatsDataset[]) =>
            accumulator<OperationsDocumentationRateStatsDataset>(data, 'totalTasksCount'),
    },
    FALL_DOCUMENTATION_INCOMPLETE: {
        accumulator: (data: OperationsFallDocumentationIncompleteStatsDataset[]) =>
            accumulator<OperationsFallDocumentationIncompleteStatsDataset>(data, 'incompleteCount'),
    },
    FALLS: {
        accumulator: (data: OperationsFallsStatsDataset[]) =>
            accumulator<OperationsFallsStatsDataset>(data, 'fallCount'),
    },
    HOSPITAL_TRANSFER: {
        accumulator: (data: OperationsHospitalTransferStatsDataset[]) =>
            accumulator<OperationsHospitalTransferStatsDataset>(data, 'hospitalVisitCount'),
    },
    RESIDENT_NOTES: {
        accumulator: (data: OperationsResidentNotesStatsDataset[]) =>
            accumulator<OperationsResidentNotesStatsDataset>(data, 'residentNoteCount'),
    },
    SHOWER: {
        accumulator: (data: OperationsShowerStatsDataset[]) =>
            accumulator<OperationsShowerStatsDataset>(data, 'residentMissedShowerCount'),
    },
    UNSCHEDULED_CARE: {
        accumulator: (data: OperationsUnscheduledCareStatsDataset[]) =>
            accumulator<OperationsUnscheduledCareStatsDataset>(data, 'unscheduledCareCount'),
    },
    CHANGE_OF_CONDITION: {
        accumulator: (data: OperationsChangeOfConditionStatsDataset[]) =>
            accumulator<OperationsChangeOfConditionStatsDataset>(data, 'withoutAdlChanges'),
        accumulator2: (data: OperationsChangeOfConditionStatsDataset[]) =>
            accumulator<OperationsChangeOfConditionStatsDataset>(data, 'withAdlChanges'),
    },
    OVERDUE_ASSESSMENT: {
        accumulator: (data: OperationsOverdueAssessmentStatsDataset[]) =>
            accumulator<OperationsOverdueAssessmentStatsDataset>(data, 'overdueAssessmentCount'),
    },
};
