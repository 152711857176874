import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { pxToRem } from 'components/theme/typography';
import { Section, StandardDataset } from 'pages/OperationsDashboard/constants';

import CustomAreaChartTooltip from './CustomAreaChartTooltip';
import { useChartClick } from './helpers';

interface CustomAreaChartProps {
    data: StandardDataset[];
    section: Section;
    setSelectedStartDate?: (date: string) => void;
    selectedStartDate?: string;
}

const prepareChartData = (data: StandardDataset[]) =>
    data.map((dataset) => ({
        period: dataset.startDate,
        value: dataset.value,
    }));

const CustomAreaChart = ({ data, section, setSelectedStartDate, selectedStartDate }: CustomAreaChartProps) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const chartData = prepareChartData(data);

    const handlePointClick = !isMobile ? useChartClick(setSelectedStartDate) : undefined;

    return (
        <Box width={1} height={{ xs: pxToRem(158), lg: pxToRem(336) }}>
            <ResponsiveContainer width="99%" height="100%">
                <AreaChart data={chartData} onClick={isMobile ? undefined : handlePointClick}>
                    <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.5} />
                    <XAxis
                        dataKey="period"
                        fontSize={isMobile ? 9 : 10}
                        fontFamily="Open Sans"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={7}
                        tickCount={8}
                        tickFormatter={(date) => {
                            const [, month, day] = date.split('-');
                            return `${month}/${day}`;
                        }}
                    />
                    <YAxis
                        dataKey="value"
                        fontSize={isMobile ? 10 : 12}
                        fontFamily="Open Sans"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={isMobile ? 3 : 6}
                        width={isMobile ? 25 : 35}
                    />
                    <Tooltip content={<CustomAreaChartTooltip />} />
                    <defs>
                        <linearGradient
                            id="customGradient"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="100%"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#006B75" />
                            <stop offset="1" stopColor="#006B75" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#006B75"
                        fill="url(#customGradient)"
                        cursor="pointer"
                    />
                    {selectedStartDate && !isMobile && (
                        <ReferenceLine
                            x={selectedStartDate}
                            stroke="#91BFC4"
                            ifOverflow="extendDomain"
                            strokeWidth={2}
                            isFront
                        />
                    )}
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CustomAreaChart;
