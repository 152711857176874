import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'constants/redux';
import { throwError } from 'redux/actions/messages';

export const useLoginInitMutation = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return useMutation({
        mutationFn: async (params: { phoneNumber: string }) => {
            await api.post(`/sessions/otp/init`, {
                ...params,
                isLogin: true,
            });
            return params.phoneNumber;
        },
        onSuccess: (phoneNumber) => {
            navigate(`/login/verify?phoneNumber=${encodeURIComponent(phoneNumber)}`);
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};

export const useLoginVerifyMutation = () => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { phoneNumber: string; token: string }) => {
            await api.post(`/sessions/otp/verify`, params);
        },
        onSuccess: () => {
            // TODO - handle success login
            console.log('sign in success');
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};

export const useLoginUsernameMutation = ({ onSuccess, onError }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    type Params = {
        username: string;
        password: string;
        phoneNumber: string;
    };

    return useMutation({
        mutationFn: async (params: Params) => {
            const { data } = await api.post(`/sessions`, params);

            return data.response;
        },
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => {
            if (onError) {
                onError();
            }
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
