import { DEFAULT_SHIFT_OPTIONS, getOrderedShifts } from '@alliehealth/utils/dist/shifts';
import { Box } from '@mui/material';
import momentTz from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';

import { HistoryItem } from 'types/myRewards';
import { ReduxStore } from 'types/redux';

import { useRewardsHistoryQuery } from 'api/queries/caregiverRewards';
import { pxToRem } from 'components/theme/typography';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

import { CheckInCalendar } from '../home/CheckInCalendar';

import { ShiftDaySection } from './ShiftDaySection';

const HistoryPage = () => {
    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const orderedShiftOptions = getOrderedShifts(DEFAULT_SHIFT_OPTIONS);

    const dynamicShiftOrder = orderedShiftOptions.reduce((acc, shift, index) => {
        acc[shift.id] = index + 1;
        return acc;
    }, {});

    const {
        isLoading: rewardsHistoryIsLoading,
        isError: rewardsHistoryIsError,
        data: rewardsHistoryData,
        error: rewardsHistoryError,
        refetch: rewardsHistoryRefetch,
    } = useRewardsHistoryQuery(userId);

    const itemsByShiftDayDate: Record<string, HistoryItem[]> = {};
    rewardsHistoryData?.items.forEach((item) => {
        const shiftDayDate = item.shiftDayDate;
        if (!itemsByShiftDayDate[shiftDayDate]) {
            itemsByShiftDayDate[shiftDayDate] = [];
        }
        itemsByShiftDayDate[shiftDayDate].push(item);
    });

    const shiftDayDateItems = Object.values(itemsByShiftDayDate);
    shiftDayDateItems.sort((a, b) => momentTz(b[0].shiftDayDate).valueOf() - momentTz(a[0].shiftDayDate).valueOf());

    shiftDayDateItems.forEach((shiftDayDate) => {
        shiftDayDate.sort((a, b) => {
            if (!a.datetimeUtc && b.datetimeUtc) {
                return -1;
            }

            if (a.datetimeUtc && !b.datetimeUtc) {
                return 1;
            }

            if (!a.datetimeUtc && !b.datetimeUtc) {
                if (dynamicShiftOrder[a.shiftName] < dynamicShiftOrder[b.shiftName]) {
                    return -1;
                }

                if (dynamicShiftOrder[a.shiftName] > dynamicShiftOrder[b.shiftName]) {
                    return 1;
                }

                return 0;
            }

            return momentTz.utc(a.datetimeUtc).diff(momentTz.utc(b.datetimeUtc));
        });
    });

    const checkInByShiftDate = rewardsHistoryData?.checkInsByShiftDate ?? [];

    return (
        <PageStructure>
            {rewardsHistoryData && (
                <>
                    {checkInByShiftDate.length > 0 && <CheckInCalendar checkInByShiftDate={checkInByShiftDate} />}
                    <Box
                        sx={{
                            m: `${pxToRem(14)} ${pxToRem(14)} ${pxToRem(24)}`,
                        }}
                    >
                        {shiftDayDateItems.map((items) => (
                            <ShiftDaySection items={items} key={items[0].shiftDayDate} />
                        ))}
                    </Box>
                </>
            )}
        </PageStructure>
    );
};

export default AccessControl(HistoryPage);
