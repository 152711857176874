import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
import React from 'react';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';

type Props = {
    caregivers: CaregiverData[];
};

const AVATAR_SIZE = 20;

export const CaregiverAvatarGroup = ({ caregivers }: Props) => (
    <AvatarGroup
        max={4}
        sx={[
            caregivers.length > 4 && {
                '& .MuiAvatarGroup-avatar:first-of-type': {
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                    fontSize: pxToRem(14),
                    lineHeight: 1.7,
                    color: '#2C2D3B',
                    backgroundColor: 'transparent',
                    ml: pxToRem(-4),
                },
            },
        ]}
    >
        {caregivers.map(({ firstName, lastName, photo }, index) => (
            <Tooltip
                title={`${firstName} ${lastName}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`${firstName} ${lastName} ${index}`}
            >
                <Avatar alt={`${firstName} ${lastName}`} sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
                    <CustomAvatar
                        avatarType={AVATAR_TYPES.caregiver}
                        firstName={firstName}
                        lastName={lastName}
                        photo={photo || ''}
                        size={AVATAR_SIZE}
                    />
                </Avatar>
            </Tooltip>
        ))}
    </AvatarGroup>
);
