import React from 'react';
import {
    FaHandHoldingMedical as AssistIcon,
    FaBed as BedMobilityIcon,
    FaToilet as IncontinenceIcon,
    FaPills as MedicationChangeIcon,
    FaToiletPaper as ToiletIcon,
    FaWheelchair as TransfersIcon,
    FaWalking as VisitIcon,
    FaWeight as WeightIcon,
} from 'react-icons/fa';
import { IoPeople as EscortsIcon, IoEllipsisHorizontal as OtherIcon } from 'react-icons/io5';
import { MdRestaurant as EatingIcon } from 'react-icons/md';

import {
    DressingIcon,
    FallingIcon,
    GroomingIcon,
    InjectionIcon,
    ShoweringIcon,
    ThumbsUpIcon,
    WarningIcon,
} from 'components/Svg';

export const HIGHEST_MODULE_ORDER = 7;

export const VISIT_ID = 'visit';
export const SHOWER_ID = 'shower';
export const INCONTINENCE_CARE_ID = 'incontinence_care';
export const FALL_ID = 'fall';
export const ASSIST_CHANGES_ID = 'assist_changes';
export const EATING_ID = 'eating';
export const WEIGHT_LOSS_ID = 'weight_loss';
export const INFECTION_POTENTIAL_UTI_ID = 'infection_potential_uti';
export const MED_CHANGES_ID = 'med_changes';

export const ASSESSMENT_MODULES = [
    {
        id: VISIT_ID,
        title: 'Hospital Visits',
        subtitle: 'Visit',
        timeRange: '30d',
        hasTimeline: false,
    },
    {
        id: SHOWER_ID,
        title: 'Shower',
        subtitle: 'Showers',
        timeRange: '7d',
        hasTimeline: false,
    },
    {
        id: INCONTINENCE_CARE_ID,
        title: 'Incontinence Care',
        subtitle: 'Incontinence',
        timeRange: '24h',
        hasTimeline: false,
    },
    {
        id: FALL_ID,
        title: 'Fall',
        subtitle: 'Fall',
        timeRange: '30d',
        hasTimeline: true,
    },
    {
        id: ASSIST_CHANGES_ID,
        title: 'Assist Changes',
        subtitle: 'Change',
        timeRange: '30d',
        hasTimeline: true,
    },
    {
        id: EATING_ID,
        title: 'Eating',
        subtitle: 'Food',
        timeRange: '3d',
        hasTimeline: false,
    },
    {
        id: WEIGHT_LOSS_ID,
        title: 'Weight Loss',
        subtitle: 'Weight loss alerts in last 90d',
        hasTimeline: false,
    },
    {
        id: INFECTION_POTENTIAL_UTI_ID,
        title: 'Infection/Potential UTI',
        subtitle: 'Antibiotic added',
        timeRange: '7d',
        hasTimeline: false,
    },
    {
        id: MED_CHANGES_ID,
        title: 'Med Changes',
        subtitle: 'Change',
        timeRange: '7d',
        hasTimeline: false,
    },
];

export const REFUSED_TASKS_ID = 'refused_tasks';
export const PRN_TASKS_ID = 'prn_tasks';

export const TASKS_MODULES = [
    {
        id: REFUSED_TASKS_ID,
        title: 'Refused Tasks',
        subtitle: 'Total task',
        timeRange: '30d',
    },
    {
        id: PRN_TASKS_ID,
        title: 'PRN Tasks',
        subtitle: 'Total task',
        timeRange: '30d',
    },
];

export const RESIDENT_NOTES_ID = 'resident_notes';
export const TASK_NOTES_ID = 'task_notes';

export const NOTES_MODULES = [
    {
        id: RESIDENT_NOTES_ID,
        title: 'Resident Notes',
    },
    {
        id: TASK_NOTES_ID,
        title: 'Task Notes',
    },
];

export const ASSESSMENT_MODULES_STATUS = {
    good: {
        badgeContent: 'Good',
        color: 'primary.main',
        icon: <ThumbsUpIcon />,
        iconBgColor: '#F2F8F8',
    },
    needs_attention: {
        badgeContent: 'Needs Attention',
        color: '#EC3232',
        icon: <WarningIcon />,
        iconBgColor: '#FEF5F5',
    },
};

export const taskCategoryIconMap = {
    dressing: <DressingIcon />,
    grooming: <GroomingIcon />,
    showering: <ShoweringIcon />,
    escorts: <EscortsIcon />,
    toileting: <ToiletIcon />,
    transfers: <TransfersIcon />,
    eating: <EatingIcon />,
    bed_mobility: <BedMobilityIcon />,
    other: <OtherIcon />,
};

export const needsAttentionSummaryIconMap = {
    EATING: <EatingIcon />,
    INCONTINENCE: <IncontinenceIcon />,
    SHOWER: <ShoweringIcon />,
    VISIT: <VisitIcon />,
    FALL: <FallingIcon />,
    MEDICATION_CHANGE: <MedicationChangeIcon />,
    POTENTIAL_UTI: <InjectionIcon />,
    ASSIST_LEVEL: <AssistIcon />,
    WEIGHT_CHANGE: <WeightIcon />,
};

export type RowData =
    | Visit
    | AssessmentModuleDefaultRow
    | WeightMeasure
    | ResidentNote
    | TaskNote
    | RefusedTasksPerKeyService
    | Fall
    | MedicationChange
    | PRNTasksPerTaskName;

export type RowComponentProps = {
    id?: string;
    row?:
        | Visit
        | AssessmentModuleDefaultRow
        | WeightMeasure
        | ResidentNote
        | TaskNote
        | RefusedTasksPerKeyService
        | Fall
        | MedicationChange
        | PRNTasksPerTaskName;
};
