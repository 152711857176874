import { DEFAULT_SHIFT_OPTIONS, get24hPeriodEndingAtEndOfCurrentShift } from '@alliehealth/utils/dist/shifts';
import { Box, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { camelCase } from 'lodash';
import momentTz from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ShiftNotesCategoryResponse } from 'types/ShiftNotesCategories';
import { AddUnscheduledTask, DailyTasksCreateParams } from 'types/dailyTasks';
import { LanguageStructure } from 'types/language';
import { MessageProps } from 'types/messages';
import { ReduxStore } from 'types/redux';
import { ResidentShiftNotesCreateUpdateParams, ResidentShiftNotesReadParams } from 'types/residentShiftNotes';
import { StoredSession } from 'types/session';

import { queryClient } from 'api/queries';
import { useRewardsDetailsQuery, useRewardsHistoryQuery, useRewardsSummaryQuery } from 'api/queries/caregiverRewards';
import { useDailyTasks } from 'api/queries/tasks/dailyTasks';
import Loading from 'components/Shared/Loading';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import { getDateTz, getFormattedDateTimeMinusOneMinute } from 'lib/common';
import UnscheduledTaskDialog from 'pages/Home/taskListComponents/resident/UnscheduledTaskDialog';
import ShiftNoteDialog from 'pages/Residents/Details/components/ShiftNoteDialog';
import { createDailyTask } from 'redux/actions/dailyTasks';
import { createResidentShiftNotes, readResidentShiftNotes } from 'redux/actions/residentShiftNotes';
import { readShiftNotesCategories } from 'redux/actions/shiftNotesCategories';

const ChipsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        display: 'none',
        alignItems: 'center',
        justifyContent: { xs: 'space-around', md: 'center' },
        flexWrap: 'wrap',
    })
);

const ChipStyle = styled(Chip)(({ theme }) =>
    theme.unstable_sx({
        mx: { md: pxToRem(8) },
        color: theme.palette.common.white,
        backgroundColor: theme.palette.app.orange.main,
        borderColor: theme.palette.grey[500],
        borderRadius: theme.shape.borderRadiusSm,
        fontWeight: 'bold',
        cursor: 'pointer',
        width: { xs: '31%', md: pxToRem(224) },
        height: { xs: pxToRem(40), md: pxToRem(32) },
        [`@media (max-width: ${pxToRem(339)})`]: {
            mb: pxToRem(16),
            width: '100%',
            height: pxToRem(32),
        },
        '& .MuiChip-label': {
            overflow: 'unset',
            whiteSpace: 'normal',
            textAlign: 'center',
        },
    })
);

const AddButtonContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        width: '100%',
    })
);

const AddButtonStyle = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        gap: pxToRem(5),
        padding: pxToRem(12),
        width: { xs: '100%', md: pxToRem(300) },
        fontWeight: 600,
        fontSize: pxToRem(14),
        backgroundColor: `${theme.palette.app.green.main} !important`,
        height: { xs: pxToRem(40), md: pxToRem(40) },
        '&:last-of-type': {
            marginLeft: { xs: 0, md: pxToRem(10) },
            marginTop: { xs: pxToRem(10), md: 0 },
        },
    })
);

type Props = {
    dictionary: LanguageStructure;
    loading: boolean;
    shiftNotesCategoriesList: ShiftNotesCategoryResponse[];
    residentId: number;
    timezone: string;
    caregiverId: number;
    branchId: number;
    dispatchReadShiftNotesCategories: () => void;
    dispatchCreateResidentShiftNotes: (params: ResidentShiftNotesCreateUpdateParams) => void;
    dispatchReadResidentShiftNotes: (params: ResidentShiftNotesReadParams) => void;
    dispatchCreateDailyTask: (params: DailyTasksCreateParams) => void;
    dispatchShowAlert: (message: MessageProps) => void;
    displayPartyOnSubmit: () => void;
};

const ResidentChipsContainer = (props: Props) => {
    const {
        dictionary: { residents: residentsDictionary },
        loading,
        shiftNotesCategoriesList,
        residentId,
        timezone,
        caregiverId,
        branchId,
        dispatchReadShiftNotesCategories,
        dispatchCreateResidentShiftNotes,
        dispatchReadResidentShiftNotes,
        dispatchCreateDailyTask,
        dispatchShowAlert,
        displayPartyOnSubmit,
    } = props;
    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] = useState<boolean>(false);
    const [isUnscheduledTaskDialogOpen, setIsUnscheduledTaskDialogOpen] = useState<boolean>(false);

    // Get the user's ID and his/her Role Level ID.
    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const { userId = 0, roleLevelId = 0 } = storedSession?.sessionData || {};
    const isCaregiver = roleLevelId === 5;

    const { refetch: rewardsDetailsRefetch } = useRewardsDetailsQuery(roleLevelId === 5 ? userId : undefined);
    const { refetch: rewardsSummaryRefetch } = useRewardsSummaryQuery(roleLevelId === 5 ? userId : undefined);
    const { refetch: rewardsHistoryRefetch } = useRewardsHistoryQuery(roleLevelId === 5 ? userId : undefined);

    const { refetch: dailyTasksRefetch } = useDailyTasks({
        branchId: Number(branchId),
        date: getDateTz(timezone),
    });

    const toggleUnscheduledTaskDialog = () => setIsUnscheduledTaskDialogOpen((previousState) => !previousState);

    const handleDialogOpen = () => {
        // If the user is not a Caregiver, exit the function.
        if (!isCaregiver) {
            return;
        }

        setIsShiftNoteDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsShiftNoteDialogOpen(false);
    };

    const handleShiftNoteSubmit = async (report: string, newShiftNoteCategory: number) => {
        // Define the params to submit into the API.
        const params: ResidentShiftNotesCreateUpdateParams = {
            residentId,
            userId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
            dateTime: momentTz.tz(new Date(), timezone).format('YYYY-MM-DD HH:mm:ss'),
        };

        // Trigger the action for Creating the Resident Info Report.
        await dispatchCreateResidentShiftNotes(params);
        dailyTasksRefetch();
        rewardsDetailsRefetch();
        rewardsSummaryRefetch();
        rewardsHistoryRefetch();

        const { startPeriodInclusive, endPeriodExclusive } = get24hPeriodEndingAtEndOfCurrentShift(
            timezone,
            DEFAULT_SHIFT_OPTIONS
        );

        const updatedEndPeriodExclusive = getFormattedDateTimeMinusOneMinute(endPeriodExclusive);

        // Define the params for fetching the Resident's Info Reports.
        const shiftNotesParams = {
            residentId: Number(residentId),
            startPeriod: startPeriodInclusive,
            endPeriod: updatedEndPeriodExclusive,
        };

        displayPartyOnSubmit();
        await dispatchReadResidentShiftNotes(shiftNotesParams);
    };

    const handleUnscheduledTaskSubmit = async (formValues: AddUnscheduledTask) => {
        const { companyCarePlanId } = formValues;

        // Check if is missing any form value.
        const isFormIncomplete = !companyCarePlanId;

        // If there is any missing value, throw an error.
        if (isFormIncomplete) {
            // Set the message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: residentsDictionary.missingParamsForAddedTaskTxt,
                alertSeverity: 'error',
                status: 400,
            };

            dispatchShowAlert(message);
        } else if (!isFormIncomplete) {
            // Define the params to send to the API.
            const params: DailyTasksCreateParams = {
                companyCarePlanId,
                userId: caregiverId,
                residentId,
                taskType: 'Added',
            };

            // Trigger the action for creating the Added Task.
            await dispatchCreateDailyTask(params);

            dailyTasksRefetch();
            rewardsDetailsRefetch();
            rewardsSummaryRefetch();
            rewardsHistoryRefetch();

            displayPartyOnSubmit();

            queryClient.invalidateQueries(['residentDailyTasks']);
            queryClient.invalidateQueries(['dailyTasks']);
        }
    };

    useEffect(() => {
        if (!shiftNotesCategoriesList.length) {
            dispatchReadShiftNotesCategories();
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <ChipsContainer>
                {shiftNotesCategoriesList.map((category) => {
                    const { id, name } = category;

                    // Transform the name to camel case.
                    const categoryCode = camelCase(name);
                    return <ChipStyle key={id} label={residentsDictionary[categoryCode]} variant="outlined" />;
                })}
            </ChipsContainer>
            <AddButtonContainer>
                <AddButtonStyle onClick={toggleUnscheduledTaskDialog}>
                    <Box
                        sx={{
                            marginRight: pxToRem(4),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    {residentsDictionary.unscheduledTaskAddButton}
                </AddButtonStyle>
                <AddButtonStyle onClick={handleDialogOpen}>
                    <Box
                        sx={{
                            marginRight: pxToRem(4),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    {residentsDictionary.shiftNotesAddButton}
                </AddButtonStyle>
            </AddButtonContainer>
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                onSubmit={handleShiftNoteSubmit}
                onClose={handleDialogClose}
            />
            <UnscheduledTaskDialog
                isOpen={isUnscheduledTaskDialogOpen}
                dialogType="Create"
                onSubmit={handleUnscheduledTaskSubmit}
                onClose={toggleUnscheduledTaskDialog}
            />
        </>
    );
};

const mapStateToProps = ({ language, session, shiftNotesCategories }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone, sessionData } = session;
    const { userId: caregiverId, branchId } = sessionData;
    const { shiftNotesCategoriesList, loading } = shiftNotesCategories;

    return {
        dictionary,
        loading,
        shiftNotesCategoriesList,
        timezone,
        caregiverId,
        branchId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadShiftNotesCategories: () => dispatch(readShiftNotesCategories()),
    dispatchCreateResidentShiftNotes: (params: ResidentShiftNotesCreateUpdateParams) =>
        dispatch(createResidentShiftNotes(params)),
    dispatchReadResidentShiftNotes: (params: ResidentShiftNotesReadParams) => dispatch(readResidentShiftNotes(params)),
    dispatchCreateDailyTask: (params: DailyTasksCreateParams) => dispatch(createDailyTask(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentChipsContainer: any = connect(mapStateToProps, mapDispatchToProps)(ResidentChipsContainer);

export default ConnectedResidentChipsContainer;
