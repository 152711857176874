import { InvitationsListFiltersForm } from 'types/signupInvitations';
import { UsersListFiltersForm } from 'types/users';

export const USERS_LIST_FILTERS_FORM_INITIAL_VALUES: UsersListFiltersForm = {
    userId: null,
    roleId: null,
    emailIndex: null,
    phoneNumberIndex: null,
};

export const INVITATIONS_LIST_FILTERS_FORM_INITIAL_VALUES: InvitationsListFiltersForm = {
    invitedById: null,
    invitedToName: '',
    email: '',
    invitationStatusId: null,
};

export const USER_ROLES = {
    ADMIN: 'Admin',
    COMPANY: 'Company',
    DIRECTOR: 'Director',
    MANAGER: 'Manager',
    CAREGIVER: 'Caregiver',
    FAMILY_MEMBER: 'Family Member',
};

export const ROLE_VALUES = {
    1: USER_ROLES.ADMIN,
    2: USER_ROLES.COMPANY,
    3: USER_ROLES.DIRECTOR,
    4: USER_ROLES.MANAGER,
    5: USER_ROLES.CAREGIVER,
    6: USER_ROLES.FAMILY_MEMBER,
};

// eslint-disable-next-line no-shadow
export enum InvitationStatus {
    undefined,
    pending,
    completed,
    canceled,
}

export const INVITATION_STATUS_MAP = {
    pending: 'pending',
    completed: 'completed',
    canceled: 'canceled',
};
