import React from 'react';
import { connect } from 'react-redux';

import { ReduxStore } from 'types/redux';

import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

import BadgesSection from './home/BadgesSection';
import LastShiftPointsSummary from './home/LastShiftPointsSummary';
import RedeemSection from './home/RedeemSection';
import ThanksSection from './home/ThanksSection';

type Props = {
    userId: number;
};

const MyRewards = ({ userId }: Props) => (
    <PageStructure>
        <RedeemSection userId={userId} />
        <LastShiftPointsSummary userId={userId} />
        <BadgesSection userId={userId} />
        <ThanksSection userId={userId} />
    </PageStructure>
);

const mapStateToProps = ({ session }: ReduxStore) => {
    const {
        sessionData: { userId },
    } = session;

    return {
        userId,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedMyRewards: any = AccessControl(connect(mapStateToProps)(MyRewards));

export default ConnectedMyRewards;
