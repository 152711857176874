import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import { formatInTimeZone } from 'date-fns-tz';
import 'polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import App from 'root';
import timemachine from 'timemachine';

import Store from 'redux/Store';

import reportWebVitals from './reportWebVitals';

// importing timemachine automatically changes the datetime.
// This resets the time to the real time so we can act like it's still the same time of the day
timemachine.reset();
const realTime = formatInTimeZone(new Date(), 'America/Denver', 'HH:mm:ss');

timemachine.config({
    dateString: `November 1, 2023 ${realTime}`,
    tick: true,
});

const initializeAppflow = async () => {
    // Register event to fire each time user resumes the app
    CapacitorApp.addListener('resume', async () => {
        if (localStorage.shouldReloadApp) {
            await LiveUpdates.reload();
        } else {
            const result = await LiveUpdates.sync();
            localStorage.shouldReloadApp = result.activeApplicationPathChanged;
        }
    });

    // First sync on app load
    const result = await LiveUpdates.sync();
    localStorage.shouldReloadApp = result.activeApplicationPathChanged;
};

if (Capacitor.isNativePlatform() && !process.env.REACT_APP_ENV.includes('local')) {
    try {
        initializeAppflow();
    } catch (err) {
        console.log('Unable to initialize AppFlow');
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <IntercomProvider appId="ydfuumfb" autoBoot>
            <App />
        </IntercomProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
