import { Box, Button, Zoom } from '@mui/material';
import React from 'react';
import { HiMicrophone as MicrophoneIcon } from 'react-icons/hi';

import { pxToRem } from 'components/theme/typography';

type Props = {
    showConfirmation: boolean;
    onAllow: () => void;
};

const ActivateMicConfirmation = (props: Props) => {
    const { showConfirmation, onAllow } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: pxToRem(8),
            }}
        >
            <Box
                sx={{
                    color: '#006B75',
                    width: pxToRem(100),
                    height: pxToRem(100),
                    borderRadius: pxToRem(120),
                    backgroundColor: '#F4F4F5',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: pxToRem(24),
                }}
            >
                <Zoom
                    in={showConfirmation}
                    style={{
                        transitionDelay: showConfirmation ? '300ms' : '0ms',
                    }}
                >
                    <Box>
                        <MicrophoneIcon size={36} />
                    </Box>
                </Zoom>
            </Box>
            <Box
                sx={{
                    fontSize: pxToRem(20),
                    fontWeight: 700,
                    lineHeight: pxToRem(32),
                    marginBottom: pxToRem(8),
                    textAlign: 'center',
                }}
            >
                Activate Microphone
            </Box>
            <Box
                sx={{
                    textAlign: 'center',
                    marginBottom: pxToRem(24),
                    fontSize: pxToRem(16),
                    lineHeight: pxToRem(24),
                    fontWeight: pxToRem(400),
                }}
            >
                Instead of typing, you now can record a note in English or other languages by speaking to the
                microphone. Let us write down your words for you by allowing access to your microphone.
            </Box>
            <Box
                sx={{
                    mt: pxToRem(8),
                    display: 'flex',
                    justifyContent: 'center',
                    width: { xs: '100%', md: pxToRem(200) },
                }}
            >
                <Button
                    color="primary"
                    onClick={onAllow}
                    sx={{
                        width: '100%',
                        p: pxToRem(12),
                        fontSize: pxToRem(14),
                        flexGrow: 1,
                    }}
                >
                    Allow
                </Button>
            </Box>
        </Box>
    );
};

export default ActivateMicConfirmation;
