import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';

import { useCaregiverPhoneMutation } from 'api/queries/caregiverPhone';
import { CustomMobileDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    phoneNumber: string;
    userId: number;
};

const PhoneRequestDialog = ({ isOpen, onClose, phoneNumber, userId }: Props) => {
    const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(phoneNumber);

    const { mutate: caregiverPhoneMutation } = useCaregiverPhoneMutation(userId);

    const handlePhoneChange = (phoneNum: string) => {
        setUpdatedPhoneNumber(phoneNum);
    };

    const handlePhoneUpdate = () => {
        caregiverPhoneMutation(
            {
                phoneNumber: updatedPhoneNumber,
                phoneNumberConfirmed: true,
            },
            {
                onSuccess: () => {
                    onClose();
                },
            }
        );
    };

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            onClose={onClose}
            title="Phone Number"
            subtitle={
                <Typography
                    sx={{
                        fontSize: {
                            xs: pxToRem(12),
                            md: pxToRem(14),
                        },
                        color: '#6F6F79',
                        textAlign: 'left',
                        px: {
                            lg: pxToRem(16),
                        },
                    }}
                >
                    Great job on earning your reward points! To receive your $5 reward gift card (after you reach 500
                    points) please share your phone number below.
                </Typography>
            }
            content={
                <Box
                    sx={{
                        mt: pxToRem(16),
                    }}
                >
                    <Box
                        component={PhoneInput}
                        country="US"
                        placeholder="Enter Phone Number"
                        value={updatedPhoneNumber}
                        onChange={handlePhoneChange}
                        sx={{
                            width: '100%',
                            padding: `${pxToRem(8)} ${pxToRem(14)}`,
                            borderRadius: pxToRem(8),
                            border: '1px solid',
                            borderColor: 'border',
                            outline: 'none',
                            '&:focus': {
                                border: '2px solid',
                                borderColor: 'primary.main',
                            },
                        }}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handlePhoneUpdate}
                        fullWidth
                        sx={{
                            mt: pxToRem(16),
                            mb: pxToRem(10),
                            width: '100%',
                            p: pxToRem(12),
                            fontSize: pxToRem(14),
                            '&:disabled': {
                                bgcolor: '#C7C8CB',
                            },
                        }}
                    >
                        Save
                    </Button>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: `${pxToRem(10)} !important`,
                                md: `${pxToRem(12)} !important`,
                            },
                            color: '#6F6F79',
                            textAlign: 'left',
                        }}
                    >
                        * You can claim your earned points on the REWARDS tab. Once you&apos;ve collected 500 or more
                        points, click the REDEEM button to receive your gift card.
                    </Typography>
                </Box>
            }
        />
    );
};

export default PhoneRequestDialog;
