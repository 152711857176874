import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowModel,
    GridValidRowModel,
    gridClasses,
} from '@mui/x-data-grid';
import React from 'react';

import { OperationsLevel, OperationsPeriod } from 'types/operationsDashboard';

import { DecreaseArrow } from 'components/Svg/DecreaseArrow';
import { IncreaseArrow } from 'components/Svg/IncreaseArrow';
import { pxToRem } from 'components/theme/typography';
import {
    CHANGE_PERIOD_NAMES,
    LEVEL_COLUMN_NAMES,
    Section,
    TIME_PERIOD_NAMES,
} from 'pages/OperationsDashboard/constants';

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: '#F9FBFB',
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: theme.palette.common.white,
    },
}));

interface MyRowModel extends GridRowModel {
    id: number;
    firstColumn: number;
    valueInPeriod: number;
    changeOverPeriod: string;
}

const renderChangeCell = (dataType: Section) => (params: GridRenderCellParams<MyRowModel>) => {
    let icon: React.ReactNode = null;
    if (params.value > 0) {
        icon = <IncreaseArrow size={16} />;
    } else if (params.value < 0) {
        icon = <DecreaseArrow size={16} />;
    }

    const getColor = (currentValue: number, currentSection: Section) => {
        const isPositive = currentValue > 0;
        if (currentSection === 'DOCUMENTATION_RATE') {
            return isPositive ? 'primary.dark' : '#EC3232';
        }
        return isPositive ? '#EC3232' : 'primary.dark';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                color: getColor(params.value, dataType),
            }}
        >
            {icon}
            {Math.abs(params.value)}%
        </Box>
    );
};

const renderTimePeriodCell = (dataType: Section) => (params: GridRenderCellParams<MyRowModel>) => (
    <>
        {params.value}
        {dataType === 'DOCUMENTATION_RATE' && (
            <Box component="span" sx={{ color: '#9B9CA2', fontWeight: 400 }}>
                %
            </Box>
        )}
    </>
);

type OverallStatsTableProps = {
    rows: GridValidRowModel[];
    level: OperationsLevel;
    timePeriod: OperationsPeriod;
    dataType: Section;
};

export const OverallStatsTable = ({ rows, level, timePeriod, dataType }: OverallStatsTableProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isLargeScreen = useMediaQuery((theme: any) => theme.breakpoints.up('xl'));

    const columns: GridColDef[] = [
        {
            field: 'firstColumn',
            headerName: LEVEL_COLUMN_NAMES[level],
            sortable: false,
            width: isLargeScreen ? 200 : 120,
        },
        {
            field: 'valueInPeriod',
            headerName: TIME_PERIOD_NAMES[timePeriod],
            renderCell: renderTimePeriodCell(dataType),
            sortable: false,
            width: isLargeScreen ? 130 : 90,
        },
        {
            field: 'changeOverPeriod',
            headerName: CHANGE_PERIOD_NAMES[timePeriod],
            renderCell: renderChangeCell(dataType),
            sortable: false,
            width: isLargeScreen ? 150 : 130,
        },
    ];

    return (
        <StripedDataGrid
            hideFooter
            rows={rows}
            columns={columns}
            columnHeaderHeight={40}
            rowHeight={35}
            disableRowSelectionOnClick
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                sorting: {
                    sortModel: [
                        {
                            field: 'valueInPeriod',
                            sort: dataType === 'DOCUMENTATION_RATE' ? 'asc' : 'desc',
                        },
                    ],
                },
            }}
            disableColumnMenu
            sx={{
                border: 'none',
                '& .MuiDataGrid-columnHeaders': {
                    borderBottom: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                    color: '#9B9CA2',
                    fontSize: { xs: pxToRem(12), xl: pxToRem(14) },
                    '&:focus': {
                        outline: 'none',
                    },
                },
                '& .MuiDataGrid-cell': {
                    borderBottom: 'none',
                    fontWeight: 700,
                    fontSize: { xs: pxToRem(12), xl: pxToRem(14) },
                },
                '& .MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                    ml: { xs: 0, md: pxToRem(6) },
                },
                '& .MuiDataGrid-sortIcon': {
                    opacity: '.3 !important',
                },
                '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                },
            }}
        />
    );
};
