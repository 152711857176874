import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

const AuthDivider = () => (
    <Box
        sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            my: {
                xs: pxToRem(16),
            },
        }}
    >
        <Divider
            sx={{
                flex: 1,
            }}
        />
        <Typography
            sx={{
                mx: {
                    xs: pxToRem(12),
                },
                fontSize: {
                    xs: pxToRem(14),
                },
                lineHeight: {
                    xs: 1.45,
                },
                color: '#DEDEE0',
            }}
        >
            or
        </Typography>
        <Divider
            sx={{
                flex: 1,
            }}
        />
    </Box>
);

export default AuthDivider;
