import { DEFAULT_SHIFT_OPTIONS, getOrderedShifts, getShiftAtDateTime } from '@alliehealth/utils/dist/shifts';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import {
    AddTask,
    AddedTaskMode,
    CheckTaskMode,
    DailyTasksByTabStrict,
    DailyTasksCreateParams,
    DailyTasksShiftDetail,
    DailyTasksShiftPeriod,
    ResidentTasks,
    TaskToUpdate,
    UpdateDailyTasksParams,
} from 'types/dailyTasks';
import { LanguageStructure } from 'types/language';
import { MessageProps } from 'types/messages';
import { StoredSession } from 'types/session';
import { UserResponse } from 'types/users';

import PhoneRequestDialog from './components/PhoneRequestDialog';
import {
    LAUNDRY_COMPANY_CARE_PLAN_IDS,
    SHOWER_COMPANY_CARE_PLAN_IDS,
} from '@alliehealth/utils/dist/constants/care-plans.constants';
import { useRewardsDetailsQuery, useRewardsHistoryQuery, useRewardsSummaryQuery } from 'api/queries/caregiverRewards';
import { useDailyTasks } from 'api/queries/tasks/dailyTasks';
import notificationSound from 'assets/notification-sound.mp3';
import { CustomTabPanel as TabPanel } from 'components/Custom';
import CustomConfetti from 'components/Custom/CustomConfetti';
import { ShiftSelector, ZoneSelector } from 'components/Filtering';
import Loading from 'components/Shared/Loading';
import SortButton from 'components/Shared/Sorting/SortButton';
import SortDialog from 'components/Shared/Sorting/SortDialog';
import { pxToRem } from 'components/theme/typography';
import { HOME_FILTERS, PROFILE, SIGNUP } from 'constants/localStorage';
import AccessControl from 'helpers/AccessControl';
import AddedTaskDialog from 'pages/Home/components/AddedTaskDialog';
import CheckTaskDialog from 'pages/Home/components/CheckTaskDialog';
import TaskNotesDialog from 'pages/Home/components/TaskNotesDialog';
import PageStructure from 'pages/PageStructure';
import { createDailyTask, updateDailyTasks, updateShiftPeriod } from 'redux/actions/dailyTasks';
import { setSortBy, setSortOrder } from 'redux/actions/filters';
import { hideAlert, showAlert } from 'redux/actions/messages';
import { toggleResidentParty } from 'redux/actions/residents';
import { updateInvitationStatus } from 'redux/actions/signupInvitations';
import { readUser } from 'redux/actions/users';

import { ResidentRows as ConfirmedResidentRows } from './confirmedTaskListComponents/ResidentRows';
import { ResidentRows as PendingResidentRows } from './taskListComponents/ResidentRows';

const TopFiltersContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', md: 'flex-end' },
        flexWrap: 'wrap',
        columnGap: { xs: 1, md: 2 },
    })
);

const TasksContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(80),
        position: 'relative',
        width: '100%',
    })
);

const AddButtonStyle = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        p: 0,
        borderRadius: '50%',
        minWidth: 'unset',
        width: pxToRem(72),
        height: pxToRem(72),
        position: 'fixed',
        right: 0,
        bottom: pxToRem(90),
        border: '2px solid #fff',
    })
);

const AddIconStyle = styled(AddIcon)(({ theme }) =>
    theme.unstable_sx({
        fontSize: pxToRem(30),
    })
);

type Props = {
    dictionary: LanguageStructure;
    timezone: string;
    zoneId: number;
    sortBy: string;
    sortOrder: string;
    displayParty: boolean;
    user: UserResponse;
    dispatchUpdateShiftPeriod: (shiftPeriod: DailyTasksShiftPeriod) => void;
    dispatchCreateDailyTask: (params: DailyTasksCreateParams) => void;
    dispatchUpdateDailyTasks: ({ tasks }: UpdateDailyTasksParams) => void;
    dispatchShowAlert: (message: MessageProps) => void;
    dispatchHideAlert: () => void;
    dispatchUpdateInvitationStatus: (invitationId: number) => void;
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
    dispatchToggleResidentParty: () => void;
    dispatchReadUser: (userId: number) => void;
};

const Home = (props: Props) => {
    const {
        dictionary: { home, error: errorDictionary },
        timezone,
        zoneId,
        sortBy,
        sortOrder,
        displayParty,
        user,
        dispatchUpdateShiftPeriod,
        dispatchCreateDailyTask,
        dispatchUpdateDailyTasks,
        dispatchShowAlert,
        dispatchHideAlert,
        dispatchUpdateInvitationStatus,
        dispatchSetSortBy,
        dispatchSetSortOrder,
        dispatchToggleResidentParty,
        dispatchReadUser,
    } = props;

    const {
        shift: { id: currentShift },
        shiftDayDate: currentShiftDayDate,
    } = getShiftAtDateTime(new Date(), timezone, DEFAULT_SHIFT_OPTIONS);

    const orderedShifts = getOrderedShifts(DEFAULT_SHIFT_OPTIONS).map(({ id }) => id) as string[] | number[];

    const currentShiftDateTzMoment = moment(currentShiftDayDate);

    const [datepickerDate, setDatepickerDate] = useState<Moment>(currentShiftDateTzMoment);
    const [shift, setShift] = useState<string | number>(currentShift);
    const [selectedBranchId, setSelectedBranchId] = useState<string>();
    const [tasksByTab, setTasksByTab] = useState<DailyTasksByTabStrict>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedRecord, setSelectedRecord] = useState<DailyTasksShiftDetail>();
    const [isAddedTaskDialogOpen, setIsAddedTaskDialogOpen] = useState<boolean>(false);
    const [addedTaskDialogType, setAddedTaskDialogType] = useState<AddedTaskMode>('Create');
    const [isCheckTaskDialogOpen, setIsCheckTaskDialogOpen] = useState<boolean>(false);
    const [checkTaskDialogType, setCheckTaskDialogType] = useState<CheckTaskMode>('Complete');
    const [isTaskNotesDialogOpen, setIsTaskNotesDialogOpen] = useState<boolean>(false);
    const [selectedTaskNotes, setSelectedTaskNotes] = useState<string>();
    const [selectedResidentId, setSelectedResidentId] = useState<string>('-1');
    const [playCoinSound] = useSound(notificationSound);
    const [addedTaskDefaultResidentId, setAddedTaskDefaultResidentId] = useState<number>();
    const [isSortDialogOpen, setIsSortDialogOpen] = useState<boolean>(false);
    const [isPhoneRequestDialogOpen, setIsPhoneRequestDialogOpen] = useState(false);

    // Verify if there is a request for updating a Sign-up Invitation.
    const invitationId = Number(localStorage.getItem(SIGNUP));

    if (invitationId > 0) {
        // Update the Invitation Status.
        dispatchUpdateInvitationStatus(invitationId);

        // Destroy the request for updating the Sign-up Invitation.
        localStorage.removeItem(SIGNUP);
    }

    // Get the user's ID, his/her Role and Company/Branch IDs.
    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const { branchId = 0, userId = 0, roleLevelId = 0 } = storedSession?.sessionData || {};
    const isDirector = roleLevelId === 4;
    const isCaregiver = roleLevelId === 5;
    const isAllowed = isDirector || isCaregiver;

    const { refetch: rewardsDetailsRefetch } = useRewardsDetailsQuery(isCaregiver ? userId : undefined);
    const { refetch: rewardsSummaryRefetch } = useRewardsSummaryQuery(isCaregiver ? userId : undefined);
    const { refetch: rewardsHistoryRefetch } = useRewardsHistoryQuery(isCaregiver ? userId : undefined);

    const {
        data: dailyTasksList,
        isLoading: dailyTasksListIsLoading,
        isError: dailyTasksListIsError,
        refetch: dailyTasksRefetch,
    } = useDailyTasks({
        branchId: Number(selectedBranchId),
        date: datepickerDate.format('YYYY-MM-DD'),
    });

    const permissionError = () => {
        // Set the message to display into the alert.
        const message: MessageProps = {
            open: true,
            message: errorDictionary.onlyDirectorsCareGivers,
            alertSeverity: 'error',
            status: 400,
        };

        dispatchShowAlert(message);
    };

    const handleFilterChange = (filter: string) => (newValue: string) => {
        if (filter === 'branch') {
            setSelectedBranchId(newValue);
        } else if (filter === 'shift') {
            setShift(newValue);
        } else if (filter === 'resident') {
            setSelectedResidentId(newValue);
        } else {
            // when zone filter is changed unselect the currently selected resident
            setSelectedResidentId('-1');
        }
    };

    const handleAddTaskSubmit = async (formValues: AddTask) => {
        // If the user is not a allowed to perform this action, throw an error and exit the function.
        if (!isAllowed) {
            permissionError();
            return;
        }

        // Extract the provided values.
        const { companyCarePlanId, residentId } = formValues;

        // Check if is missing any form value.
        const isFormIncomplete = !companyCarePlanId || !residentId;

        // If there is any missing value, throw an error.
        if (isFormIncomplete) {
            // Set the message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: home.missingParamsForAddedTask,
                alertSeverity: 'error',
                status: 400,
            };

            dispatchShowAlert(message);
        } else if (!isFormIncomplete && addedTaskDialogType === 'Create') {
            // Define the params to send to the API.
            const params: DailyTasksCreateParams = {
                companyCarePlanId,
                userId,
                residentId,
                taskType: 'Added',
            };

            // Update the current Shift Period.
            const {
                shift: { startTimeInclusive, endTimeExclusive },
            } = getShiftAtDateTime(new Date(), timezone, DEFAULT_SHIFT_OPTIONS);

            dispatchUpdateShiftPeriod({ startTimeInclusive, endTimeExclusive });

            // Trigger the action for creating the Added Task.
            await dispatchCreateDailyTask(params);

            dailyTasksRefetch();
            rewardsDetailsRefetch();
            rewardsSummaryRefetch();
            rewardsHistoryRefetch();

            dispatchToggleResidentParty();
            playCoinSound();
        }
    };

    const handleAddedTaskDialogOpen = () => {
        // If the user is not a Caregiver, throw an error and exit the function.
        if (!isCaregiver) {
            permissionError();
            return;
        }

        setAddedTaskDialogType('Create');
        setIsAddedTaskDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsAddedTaskDialogOpen(false);
        setIsCheckTaskDialogOpen(false);
        setIsTaskNotesDialogOpen(false);
        setSelectedTaskNotes('');
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCompleteTaskClick = async ({ task }: { task: TaskToUpdate }) => {
        // If the user is not a allowed to perform this action, exit the function.
        if (!isAllowed) {
            return;
        }

        // Update the current Shift Period.
        const {
            shift: { startTimeInclusive, endTimeExclusive },
        } = getShiftAtDateTime(new Date(), timezone, DEFAULT_SHIFT_OPTIONS);

        dispatchUpdateShiftPeriod({ startTimeInclusive, endTimeExclusive });

        // Trigger the action for updating the Task as Complete.

        dispatchUpdateDailyTasks({
            tasks: [task],
        });

        if (isCaregiver && task.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }

        dailyTasksRefetch();
        rewardsDetailsRefetch();
        rewardsSummaryRefetch();
        rewardsHistoryRefetch();
    };

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const togglePhoneRequestDialog = () => setIsPhoneRequestDialogOpen((prevState) => !prevState);

    const taskRecordSorter = (a: DailyTasksShiftDetail, b: DailyTasksShiftDetail) => {
        const aDateTime = moment(`${a.taskDate}T${a.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        const bDateTime = moment(`${b.taskDate}T${b.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        if (aDateTime.isBefore(bDateTime)) {
            return -1;
        }

        if (aDateTime.isAfter(bDateTime)) {
            return 1;
        }

        return 0;
    };

    const handleSortButtonClick = () => {
        setIsSortDialogOpen(true);
    };

    const handleSortDialogClose = () => {
        setIsSortDialogOpen(false);
    };

    const handleSortOptions = (selectedSortBy: string, selectedSortOrder: string) => {
        if (sortBy !== selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }
        if (sortOrder !== selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    const hasTaskWithCarePlanIds = (tasks: DailyTasksShiftDetail[], carePlanIds: Set<number>): boolean =>
        tasks.some((task) => carePlanIds.has(task.companyCarePlanId));

    const sortByShowerAndLaundry = (residentsTasksList: ResidentTasks[], isAscending: boolean): ResidentTasks[] => {
        const sortedArray = orderBy(
            residentsTasksList,
            [
                (item) => hasTaskWithCarePlanIds(item.tasks, new Set(SHOWER_COMPANY_CARE_PLAN_IDS)),
                (item) => hasTaskWithCarePlanIds(item.tasks, new Set(LAUNDRY_COMPANY_CARE_PLAN_IDS)),
            ],
            [isAscending ? 'desc' : 'asc', isAscending ? 'desc' : 'asc']
        );

        return sortedArray;
    };

    const handleSort = (
        residentsTasksList: ResidentTasks[],
        selectedSortBy: keyof ResidentTasks | 'shower&Laundry',
        isAscending: boolean
    ): ResidentTasks[] => {
        if (selectedSortBy === 'shower&Laundry') {
            return sortByShowerAndLaundry(residentsTasksList, isAscending);
        }

        if (selectedSortBy === 'roomNumber') {
            return orderBy(residentsTasksList, (item) => Number(item[selectedSortBy]), isAscending ? 'asc' : 'desc');
        }

        return orderBy(residentsTasksList, selectedSortBy, isAscending ? 'asc' : 'desc');
    };

    useEffect(() => {
        dispatchReadUser(userId);
        setSelectedBranchId(branchId.toString());
    }, []);

    useEffect(() => {
        if (!user?.userId || user.roleId !== 5) return;
        if (user?.phoneNumberConfirmed === false) {
            togglePhoneRequestDialog();
        }
    }, [user?.userId, user?.phoneNumberConfirmed]);

    useEffect(() => {
        if (shift && zoneId) {
            const newTasksToShow: DailyTasksShiftDetail[] =
                dailyTasksList && dailyTasksList[shift]?.length
                    ? cloneDeep(dailyTasksList[shift])
                          .filter((task) => task.zoneId === zoneId)
                          .filter((task) => {
                              if (selectedResidentId === '-1') {
                                  return true;
                              }

                              return task.resident.id.toString() === selectedResidentId;
                          })
                    : [];

            const pendingTasks = newTasksToShow.filter((task) => task.taskStatus === 'Undocumented');
            const confirmedTasks = newTasksToShow.filter((task) => task.taskStatus !== 'Undocumented');
            pendingTasks.sort(taskRecordSorter);
            confirmedTasks.sort(taskRecordSorter);

            setTasksByTab([
                {
                    key: 0,
                    tabName: 'pending',
                    tasks: pendingTasks,
                },
                {
                    key: 1,
                    tabName: 'nonPending',
                    tasks: confirmedTasks,
                },
            ]);

            // Update the filters into the localStorage.
            const newFilters = {
                date: datepickerDate.format('YYYY-MM-DD HH:mm:ss'),
                shift,
            };
            localStorage.setItem(HOME_FILTERS, JSON.stringify(newFilters));
        }
    }, [
        datepickerDate.format('YYYY-MM-DD'),
        shift,
        zoneId,
        selectedBranchId,
        JSON.stringify(dailyTasksList),
        selectedResidentId,
    ]);

    useEffect(() => {
        if (dailyTasksListIsLoading) {
            const message: MessageProps = {
                open: true,
                message: 'Loading',
                alertSeverity: 'info',
                status: 200,
            };

            // dispatchShowAlert(message);
        } else {
            dispatchHideAlert();
        }
    }, [dailyTasksListIsLoading]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // HACK ASCENT: not showing loading so the page doesn't scroll to the top again immediately
    if (!currentShiftDateTzMoment || (isCaregiver && !selectedBranchId)) {
        return <Loading />;
    }

    const selectedDate = datepickerDate.format('YYYY-MM-DD');

    const handleDateChange = (newDate: Date | null) => {
        const newDateMoment = moment(newDate);
        setDatepickerDate(newDateMoment);
    };

    const renderPhoneRequestDialog = !!user?.userId;

    return (
        <PageStructure>
            {/* DEBUG */}
            {/* <Typography variant="subtitle1">Distance: {distance}</Typography> */}
            <TopFiltersContainer>
                {isDirector && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box
                            sx={{
                                width: '100%',
                                marginBottom: pxToRem(8),
                                '& .MuiTextField-root': {
                                    width: '100%',
                                    '& .MuiInputBase-input': {
                                        px: pxToRem(12),
                                        py: pxToRem(8),
                                        width: '100%',
                                    },
                                },
                            }}
                        >
                            <MobileDatePicker
                                label={home.date}
                                inputFormat="MM/dd/yyyy"
                                value={datepickerDate}
                                onChange={handleDateChange}
                                maxDate={currentShiftDateTzMoment.toDate()}
                                renderInput={(params) => (
                                    <TextField
                                        {...{
                                            ...params,
                                            inputProps: {
                                                ...params.inputProps,
                                                value:
                                                    params.inputProps?.value ===
                                                    currentShiftDateTzMoment.format('MM/DD/YYYY')
                                                        ? 'Today'
                                                        : params.inputProps?.value,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </LocalizationProvider>
                )}
                <Box sx={{ flex: 1 }}>
                    <ShiftSelector
                        width="100%"
                        currentShift={shift}
                        shiftOptionsOrder={orderedShifts}
                        fullWidth
                        onChange={handleFilterChange('shift')}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ZoneSelector
                        width="100%"
                        branchId={Number(selectedBranchId)}
                        fullWidth
                        onChange={handleFilterChange('zoneId')}
                    />
                </Box>
                <Box
                    sx={{
                        mt: { md: pxToRem(8) },
                        mb: { xs: pxToRem(12), md: pxToRem(16) },
                        width: { xs: '100%', md: 'unset' },
                    }}
                >
                    <SortButton onClick={handleSortButtonClick} />
                </Box>
            </TopFiltersContainer>
            {isEmpty(tasksByTab) && <Typography variant="subtitle1">{home.noTasks}</Typography>}
            {!isEmpty(tasksByTab) && (
                <TasksContainer>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        {tasksByTab.map((tabTask) => {
                            const { key, tabName, tasks } = tabTask;
                            return (
                                <Tab
                                    key={key}
                                    label={`${home[tabName]} (${tasks.length})`}
                                    sx={{
                                        ...(isMobile ? { flex: 1 } : {}),
                                        fontSize: pxToRem(14),
                                        fontWeight: 700,
                                    }}
                                />
                            );
                        })}
                    </Tabs>
                    {tasksByTab.map((tabTask) => {
                        const { key, tasks } = tabTask;
                        const residentsTasks: {
                            [index: number]: ResidentTasks;
                        } = {};
                        tasks.forEach((task) => {
                            if (!residentsTasks[task.resident.id]) {
                                residentsTasks[task.resident.id] = {
                                    ...task.resident,
                                    tasks: [],
                                };
                            }

                            residentsTasks[task.resident.id].tasks.push(task);
                        });

                        const residentsTasksList = Object.values(residentsTasks);

                        const defaultResidentsTasksList = orderBy(
                            residentsTasksList,
                            (item) => Number(item.roomNumber),
                            sortOrder === 'asc' ? 'asc' : 'desc'
                        );

                        const residentsTasksListSorted = handleSort(
                            defaultResidentsTasksList,
                            sortBy as keyof ResidentTasks | 'shower&Laundry',
                            sortOrder === 'asc'
                        );

                        return (
                            <TabPanel key={key} value={selectedTab} index={key}>
                                {selectedTab === 0 ? (
                                    <PendingResidentRows
                                        residentTasksList={residentsTasksListSorted}
                                        date={selectedDate}
                                        shift={shift}
                                        branchId={branchId}
                                        setSelectedResidentId={setAddedTaskDefaultResidentId}
                                    />
                                ) : (
                                    <ConfirmedResidentRows
                                        residentTasksList={residentsTasksListSorted}
                                        date={selectedDate}
                                        branchId={branchId}
                                    />
                                )}
                            </TabPanel>
                        );
                    })}
                </TasksContainer>
            )}
            {isCaregiver && (
                <AddButtonStyle variant="contained" color="primary" onClick={handleAddedTaskDialogOpen}>
                    <AddIconStyle />
                </AddButtonStyle>
            )}
            <AddedTaskDialog
                isOpen={isAddedTaskDialogOpen}
                dailyTaskRecord={selectedRecord}
                defaultResidentId={addedTaskDefaultResidentId}
                dialogType={addedTaskDialogType}
                onSubmit={handleAddTaskSubmit}
                onClose={handleCloseDialog}
            />
            <CheckTaskDialog
                isOpen={isCheckTaskDialogOpen}
                dailyTaskRecord={selectedRecord}
                dialogType={checkTaskDialogType}
                onSubmit={handleCompleteTaskClick}
                onClose={handleCloseDialog}
            />
            <TaskNotesDialog isOpen={isTaskNotesDialogOpen} taskNote={selectedTaskNotes} onClose={handleCloseDialog} />
            <SortDialog
                isOpen={isSortDialogOpen}
                onClose={handleSortDialogClose}
                onSort={handleSortOptions}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <CustomConfetti activate={displayParty} stop={dispatchToggleResidentParty} />
            {renderPhoneRequestDialog && (
                <PhoneRequestDialog
                    isOpen={isPhoneRequestDialogOpen}
                    onClose={togglePhoneRequestDialog}
                    phoneNumber={user?.phoneNumber}
                    userId={user?.userId}
                />
            )}
        </PageStructure>
    );
};

const mapStateToProps = ({ language, session, filters, residents, users }) => {
    const { dictionary } = language;
    const { timezone } = session;
    const { displayParty } = residents;
    const {
        caregiverApp: { zoneId, sortBy, sortOrder },
    } = filters;
    const { user } = users;

    return {
        dictionary,
        timezone,
        zoneId,
        sortBy,
        sortOrder,
        displayParty,
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateShiftPeriod: (shiftPeriod: DailyTasksShiftPeriod) => dispatch(updateShiftPeriod(shiftPeriod)),
    dispatchCreateDailyTask: (params: DailyTasksCreateParams) => dispatch(createDailyTask(params)),
    dispatchUpdateDailyTasks: ({ tasks }: UpdateDailyTasksParams) => dispatch(updateDailyTasks({ tasks })),
    dispatchShowAlert: (message: MessageProps) => dispatch(showAlert(message)),
    dispatchHideAlert: () => dispatch(hideAlert()),
    dispatchUpdateInvitationStatus: (invitationId: number) => dispatch(updateInvitationStatus(invitationId, 2)),
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) => dispatch(setSortOrder(sortOrder)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
    dispatchReadUser: (userId: number) => dispatch(readUser(userId)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedHome: any = AccessControl(connect(mapStateToProps, mapDispatchToProps)(Home));

export default ConnectedHome;
