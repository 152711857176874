import { api } from 'api';

import { CompanyCarePlanResponse, CompanyCarePlansListReadParams } from 'types/companyCarePlans';

import { formatApiParams, getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { throwError } from 'redux/actions/messages';
import 'redux/reducers/companyCarePlans';

/**
 * Fetches the list of Company Care Plans based on a Company.
 * @param jsonParams the JSON object with the params for fetching the Company Care Plans list.
 */
export const readCompanyCarePlans =
    (jsonParams: CompanyCarePlansListReadParams, actionType = actionTypes.COMPANY_CARE_PLAN_READ_LIST) =>
    async (dispatch) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert = actionType === actionTypes.COMPANY_CARE_PLAN_READ_LIST;

            // Enable the loading process.
            dispatch(loading(actionTypes.COMPANY_CARE_PLAN_LOADING, true, shouldHideAlert));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Fetch the Residents List.
            const companyCarePlansList: CompanyCarePlanResponse[] = (
                await api.get('/company-care-plans', {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response;

            dispatch({
                type: actionType,
                payload: companyCarePlansList,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.COMPANY_CARE_PLAN_LOADING, false));
        }
    };
