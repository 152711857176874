import { Box, Button, TextField } from '@mui/material';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CompanyCarePlanResponse, CompanyCarePlansListReadParams } from 'types/companyCarePlans';
import { AddUnscheduledTask } from 'types/dailyTasks';
import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ResidentsListReadParams } from 'types/residents';
import { StoredSession } from 'types/session';

import { useResidentsQuery } from 'api/queries/residents';
import { CustomAutocomplete, CustomDialog } from 'components/Custom';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import { ADD_UNSCHEDULED_TASK_INITIAL_VALUES } from 'constants/home';
import { PROFILE } from 'constants/localStorage';
import { readCompanyCarePlans } from 'redux/actions/companyCarePlans';

// keep in sync with src/pages/Home/components/AddedTaskDialog.tsx
const POSSIBLE_FRAUD_USER_IDS = [285];

type Props = {
    companyCarePlansList: CompanyCarePlanResponse[];
    dictionary: LanguageStructure;
    isOpen: boolean;
    userId: number;
    dispatchReadCompanyCarePlans: (params: CompanyCarePlansListReadParams) => void;
    onSubmit: (formData: AddUnscheduledTask) => void;
    onClose: () => void;
};

const UnscheduledTaskDialog = (props: Props) => {
    const {
        companyCarePlansList,
        dictionary: { home, shared },
        isOpen,
        userId,
        dispatchReadCompanyCarePlans,
        onSubmit,
        onClose,
    } = props;
    const [form, setForm] = useState<AddUnscheduledTask>(ADD_UNSCHEDULED_TASK_INITIAL_VALUES);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [taskOptions, setTaskOptions] = useState<DropdownStructure[]>([]);

    const [queryParams, setQueryParams] = useState<ResidentsListReadParams | null>(null);

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery(queryParams);

    // Get the user's Company and Branch IDs.
    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const { companyId = 0, branchId = 0 } = storedSession?.sessionData || {};

    const handleDropdownChange = (fieldName: string) => (optionValue: DropdownValue) => {
        setForm({
            ...form,
            [fieldName]: optionValue,
        });
    };

    const handleSubmitClick = () => {
        onSubmit(form);
        onClose();
    };

    useEffect(() => {
        if (!companyCarePlansList?.length) {
            // Set the params to send to the API.
            const params = {
                companyId,
            };

            // Trigger the action for fetching the Company Care Plans corresponding to the User's Company ID.
            dispatchReadCompanyCarePlans(params);
        }

        if (!residentsList?.length) {
            // Set the params to send to the API.
            const params = {
                branchId,
            };

            setQueryParams(params);
        }
    }, []);

    useEffect(() => {
        if (companyCarePlansList.length) {
            // Define the Tasks options to show.
            const newOptions: DropdownStructure[] = companyCarePlansList
                .filter((companyCarePlan) => companyCarePlan.taskPoints !== null)
                .map((companyCarePlan) => ({
                    label: companyCarePlan.taskName,
                    value: companyCarePlan.companyCarePlanId,
                }));
            setTaskOptions(newOptions);
        }
    }, [JSON.stringify(companyCarePlansList)]);

    const isPotentialFraudUser = POSSIBLE_FRAUD_USER_IDS.includes(userId);
    const isAddButtonDisabled = !form.companyCarePlanId || (isPotentialFraudUser && (!startTime || !endTime));

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <CustomDialog
            closeable
            open={isOpen}
            title={home.addTask}
            width="100%"
            onClose={onClose}
            content={
                <>
                    <CustomAutocomplete
                        label={home.serviceProvided}
                        value={form.companyCarePlanId}
                        options={taskOptions}
                        fullWidth
                        required
                        onChange={handleDropdownChange('companyCarePlanId')}
                    />
                    {isPotentialFraudUser && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: pxToRem(8),
                                }}
                            >
                                <DesktopTimePicker
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        />
                                    )}
                                    value={startTime}
                                    label="Start Time"
                                    onChange={(newValue) => {
                                        setStartTime(newValue);
                                    }}
                                />
                                <DesktopTimePicker
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        />
                                    )}
                                    label="End Time"
                                    value={endTime}
                                    onChange={(newValue) => {
                                        setEndTime(newValue);
                                    }}
                                    minTime={startTime}
                                />
                            </Box>
                        </LocalizationProvider>
                    )}
                </>
            }
            actions={
                <>
                    <Button variant="outlined" color="secondary" onClick={onClose}>
                        {shared.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isAddButtonDisabled}
                        onClick={handleSubmitClick}
                    >
                        {shared.add}
                    </Button>
                </>
            }
        />
    );
};

const mapStateToProps = ({ companyCarePlans, language, session }) => {
    const { companyCarePlansList } = companyCarePlans;
    const { dictionary } = language;
    const {
        sessionData: { userId },
    } = session;

    return {
        companyCarePlansList,
        dictionary,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadCompanyCarePlans: (params: CompanyCarePlansListReadParams) => dispatch(readCompanyCarePlans(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedUnscheduledTaskDialog: any = connect(mapStateToProps, mapDispatchToProps)(UnscheduledTaskDialog);

export default ConnectedUnscheduledTaskDialog;
