import { api } from 'api';

import { ErrorProps } from 'types/messages';
import {
    UserCreateParams,
    UserResponse,
    UserUpdateParams,
    UserUpdatePasswordParams,
    UsersReadParams,
} from 'types/users';

import { formatApiParams, getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { handleError, throwError } from 'redux/actions/messages';
import 'redux/reducers/users';

/**
 * Fetches the list of Users based on the provided params.
 * @param jsonParams the JSON object with the params for fetching the Users list.
 */
export const readUsers =
    (jsonParams?: UsersReadParams, actionType = actionTypes.USER_READ_LIST) =>
    async (dispatch, getState) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert = actionType === actionTypes.USER_READ_LIST;

            // Enable the loading process.
            dispatch(loading(actionTypes.USER_LOADING, true, shouldHideAlert));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = jsonParams ? formatApiParams(jsonParams) : undefined;

            // Get the list of the Users that match with the provided params.
            const serverResponse: UserResponse[] = (
                await api.get('users', {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response;

            // Updates the State in Redux.
            dispatch({
                type: actionType,
                payload: serverResponse,
            });
        } catch (error) {
            // Get the Error dictionary from the language state.
            const {
                language: {
                    dictionary: { error: errorDictionary },
                },
            } = getState();

            const errorCode = error.response?.data?.code;
            const errorShort = error.response?.data?.short;
            const errorMessage =
                errorCode === 400 && errorShort === 'missing'
                    ? errorDictionary.missingRoleId
                    : errorDictionary.generalError;

            const errorProps: ErrorProps = {
                error,
                consoleMessage: errorMessage,
                alertMessage: errorMessage,
            };

            dispatch(handleError(errorProps));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.USER_LOADING, false));
        }
    };

/**
 * Fetches a single User based on the provided User ID.
 * @param userId the ID of the User to find.
 */
export const readUser =
    (userId: number, skipLoading = false) =>
    async (dispatch, getState) => {
        try {
            if (!skipLoading) {
                // Enable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, true));
            }

            // Get the token.
            const token = getToken();

            // Get the data for the requested user.
            const serverResponse: UserResponse = (
                await api.get(`users/${userId}`, {
                    headers: {
                        authorization: token,
                    },
                })
            ).data.response;

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.USER_READ,
                payload: serverResponse,
            });
        } catch (error) {
            // Get the Error dictionary from the language state.
            const {
                language: {
                    dictionary: { error: errorDictionary },
                },
            } = getState();

            const errorCode = error.response.data.code;
            const errorShort = error.response.data.short;
            const errorMessage =
                errorCode === 400 && errorShort === 'missing'
                    ? errorDictionary.missingUserId
                    : errorDictionary.userNotFound;

            const errorProps: ErrorProps = {
                error,
                consoleMessage: errorMessage,
                alertMessage: errorMessage,
            };

            dispatch(handleError(errorProps));
        } finally {
            if (!skipLoading) {
                // Disable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, false));
            }
        }
    };

/**
 * Clears the user's state by removing any data stored into the 'user' property.
 */
export const clearUserDetails = () => ({
    type: actionTypes.USER_CLEAR_DETAILS,
});

/**
 * Clears the action status from the user's state by removing the 'actionSuccess' property.
 */
export const clearUserActionStatus = () => ({
    type: actionTypes.USER_CLEAR_ACTION_STATUS,
});

/**
 * Creates a new User based on the provided params.
 * @param jsonParams the JSON object with the params for creating a new User.
 */
export const createUser =
    (jsonParams: UserCreateParams, skipLoading = false) =>
    async (dispatch) => {
        try {
            if (!skipLoading) {
                // Enable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, true));
            }

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Create the new User.
            await api.post('users', params, {
                headers: {
                    authorization: token,
                },
            });

            // Define the params for fetching the users.
            const { roleId, companyId, branchId } = jsonParams;
            const usersJsonParams: UsersReadParams = {
                roleId,
                companyId,
                branchId,
            };

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.USER_CREATE,
                payload: true,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.USER_CREATE,
                payload: false,
            });
        } finally {
            if (!skipLoading) {
                // Disable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, false));
            }
        }
    };

/**
 * Updates a User info based on the provided params.
 * @param userId the ID for the User to find and update.
 * @param jsonParams the JSON object with the params for updating an existing User.
 */
export const updateUser =
    (userId: number, jsonParams: UserUpdateParams, skipLoading = false) =>
    async (dispatch, getState) => {
        try {
            if (!skipLoading) {
                // Enable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, true));
            }

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Update the existing user.
            await api.put(`users/${userId}`, params, {
                headers: {
                    authorization: token,
                },
            });

            // Get the data from the current User.
            const {
                users: { user },
            } = getState();

            // Update the corresponding values for the User.
            const { firstName, lastName, dateOfBirth, email, phoneNumber } = jsonParams;
            const updatedUser: UserResponse = {
                ...user,
                firstName,
                lastName,
                dateOfBirth,
                email,
                phoneNumber,
            };

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.USER_UPDATE_SUCCESS,
                payload: updatedUser,
            });
        } catch (error) {
            dispatch({
                type: actionTypes.USER_UPDATE_FAIL,
            });
        } finally {
            if (!skipLoading) {
                // Disable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, false));
            }
        }
    };

/**
 * Updates a User info based on the provided params.
 * @param userId the ID for the User to find and update.
 * @param jsonParams the JSON object with the params for updating the user's password.
 */
export const updatePassword =
    (userId: number, jsonParams: UserUpdatePasswordParams, skipLoading = false) =>
    async (dispatch, getState) => {
        try {
            if (!skipLoading) {
                // Enable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, true));
            }

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Update the existing user.
            await api.put(`passwords/${userId}`, params, {
                headers: {
                    authorization: token,
                },
            });

            // Get the data from the current User.
            const {
                users: { user },
            } = getState();

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.USER_PASSWORD_UPDATE,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            if (!skipLoading) {
                // Disable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, false));
            }
        }
    };

/**
 * Removes a User based on the provided User ID.
 * @param params it can handle the following props:
 * @param userId the ID of the User to find and delete.
 */
export const deleteUser =
    (userId: number, skipLoading = false) =>
    async (dispatch) => {
        try {
            if (!skipLoading) {
                // Enable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, true));
            }

            // Get the token.
            const token = getToken();

            // Delete the requested user.
            await api.delete(`users/${userId}`, {
                headers: {
                    authorization: token,
                },
            });

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.USER_DELETE,
                payload: userId,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            if (!skipLoading) {
                // Disable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, false));
            }
        }
    };

/**
 * Fetches the list of Roles based on the current User Role.
 */
export const readRoles = () => async (dispatch, getState) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.USER_LOADING, true, false));

        // Get the token.
        const token = getToken();

        // Get the list of the Users that match with the provided params.
        const serverResponse: UserResponse[] = (
            await api.get('users/roles', {
                headers: {
                    authorization: token,
                },
            })
        ).data.response;

        // Updates the State in Redux.
        dispatch({
            type: actionTypes.USER_READ_ROLES_LIST,
            payload: serverResponse,
        });
    } catch (error) {
        // Get the Error dictionary from the language state.
        const {
            language: {
                dictionary: { error: errorDictionary },
            },
        } = getState();

        const errorMessage = errorDictionary.generalError;

        const errorProps: ErrorProps = {
            error,
            consoleMessage: errorMessage,
            alertMessage: errorMessage,
        };

        dispatch(handleError(errorProps));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.USER_LOADING, false));
    }
};

/**
 * Verifies if an email exists into the DB.
 * @param email the email to check if exists or not.
 */
export const verifyEmail = (email: string) => async (dispatch) => {
    try {
        // Get the token.
        const token = getToken();

        // Get the lconfirmation about if the provided email exists or not.
        const emailExists: boolean = (
            await api.get(`users/email/${email}`, {
                headers: {
                    authorization: token,
                },
            })
        ).data.response;

        // Updates the State in Redux.
        dispatch({
            type: actionTypes.USER_EMAIL_CHECK,
            payload: emailExists,
        });
    } catch (error) {
        dispatch(throwError(error));
    }
};

/**
 * Get a user's data if the provided email exists into the DB.
 * @param email the email to check if exists or not.
 */
export const readUserIdByEmail = (email: string) => async (dispatch) => {
    try {
        // Get the lconfirmation about if the provided email exists or not.
        const userId: number = (await api.get(`passwords/${email}`, {})).data.response;

        // Updates the State in Redux.
        dispatch({
            type: actionTypes.USER_BY_EMAIL,
            payload: userId,
        });
    } catch (error) {
        dispatch(throwError(error));
    }
};
