import { Button } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    label: string;
    onClick: () => void;
    isDisabled: boolean;
};

const AuthButton = ({ label, onClick, isDisabled }: Props) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <Button
            variant="contained"
            onClick={handleClick}
            fullWidth
            disabled={isDisabled}
            disableRipple
            sx={{
                py: pxToRem(16),
                fontSize: {
                    xs: pxToRem(16),
                },
                lineHeight: {
                    xs: 1.5,
                },
                backgroundColor: 'primary.dark',
                boxShadow: 'none',
                '&:disabled': {
                    bgcolor: '#61A3A9',
                    color: 'common.white',
                    boxShadow: 'none',
                },
                '&:hover, &:focus': {
                    boxShadow: 'none',
                },
                '&:focus-visible': {
                    outline: '1px solid #FFFFFF',
                    outlineOffset: '-3px',
                },
            }}
        >
            {label}
        </Button>
    );
};

export default AuthButton;
