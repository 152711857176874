import { orderBy } from 'lodash';
import { useMemo } from 'react';

import { BranchesByRegionResponse } from 'types/companyInfo';
import {
    OperationsLevel,
    OperationsOverallStatsDataset,
    OperationsOverallStatsResponse,
} from 'types/operationsDashboard';

import { OverallStatsTable } from '../components/Tables/OverallStatsTable';

import { OVERALL_SECTIONS_MAP, Section, StandardDataset } from '../constants';
import {
    calculateAmountForPeriod,
    calculatePercentage,
    calculatePercentageChange,
    findNameById,
    getFilteredData,
} from '../helpers';

import { CHART_SECTION_ACCUMULATORS, TABLE_SECTION_ACCUMULATORS } from './accumulators';

const groupByLevel = (datasets: OperationsOverallStatsDataset[], level: 'region' | 'branch') => {
    const idKey = level === 'region' ? 'regionId' : 'branchId';

    const result = datasets.reduce(
        (acc, dataset) => {
            const id = dataset[idKey];

            const existingItem = acc.find((item) => item.id === id);

            if (existingItem) {
                existingItem.datasets.push(dataset);
            } else {
                acc.push({
                    id,
                    datasets: [dataset],
                });
            }

            return acc;
        },
        [] as { id: number; datasets: OperationsOverallStatsDataset[] }[]
    );

    return result;
};

const groupLevelDatasets = (
    levelDatasets: { id: number; datasets: OperationsOverallStatsDataset[] }[],
    accumulatorFn: (datasets: OperationsOverallStatsDataset[]) => number
) =>
    levelDatasets.map((group) => ({
        id: group.id,
        datasets: group.datasets.reduce((acc: StandardDataset[], dataset) => {
            // Find if there is already an entry for this startDate
            const existing = acc.find((d) => d.startDate === dataset.startDate);

            if (existing) {
                existing.value += accumulatorFn([dataset]);
            } else {
                acc.push({
                    startDate: dataset.startDate,
                    value: accumulatorFn([dataset]),
                });
            }

            return acc;
        }, []),
    }));

const calculatePercentageArray = (
    orderedGroupedLevelDatasets: { id: number; datasets: StandardDataset[] }[],
    orderedGroupedLevelDatasets2: { id: number; datasets: StandardDataset[] }[]
) =>
    orderedGroupedLevelDatasets.map((group) => {
        const correspondingGroup = orderedGroupedLevelDatasets2.find((g) => g.id === group.id);

        return {
            id: group.id,
            datasets: group.datasets.map((dataset) => {
                const totalDataset = correspondingGroup?.datasets.find((d) => d.startDate === dataset.startDate);
                const totalValue = totalDataset ? totalDataset.value : 0;
                const percentage = totalValue > 0 ? calculatePercentage(dataset.value, totalValue) : 0;

                return {
                    startDate: dataset.startDate,
                    value: percentage,
                };
            }),
        };
    });

const transformDataForMuiGrid = (
    orderedGroupedLevelDatasets: { id: number; datasets: StandardDataset[] }[],
    branchesByRegion: BranchesByRegionResponse,
    currentLevel: OperationsLevel,
    selectedStartDate?: string,
    orderedGroupedLevelDatasets2?: { id: number; datasets: StandardDataset[] }[]
) => {
    const data = orderedGroupedLevelDatasets2
        ? calculatePercentageArray(orderedGroupedLevelDatasets, orderedGroupedLevelDatasets2)
        : orderedGroupedLevelDatasets;
    const result = data.map(({ id, datasets }) => {
        let lastDataset = datasets[1];
        let previousToLastDataset = datasets[2];

        if (selectedStartDate) {
            const selectedDatasetIdx = datasets.findIndex(({ startDate }) => startDate === selectedStartDate);
            if (selectedDatasetIdx !== -1) {
                lastDataset = datasets[selectedDatasetIdx];
                previousToLastDataset = datasets[selectedDatasetIdx + 1];
            }
        }

        return {
            id,
            firstColumn: findNameById(id, branchesByRegion, currentLevel) || '-',
            valueInPeriod: lastDataset.value,
            changeOverPeriod: calculatePercentageChange(lastDataset.value, previousToLastDataset.value).toString(),
        };
    });
    return result;
};

const groupDatasets = (
    originalDatasets: OperationsOverallStatsDataset[],
    accumulatorFn: (datasets: OperationsOverallStatsDataset[]) => number
): StandardDataset[] => {
    const groupedData = originalDatasets.reduce((acc: Record<string, number>, dataset) => {
        const { startDate } = dataset;

        if (acc[startDate]) {
            acc[startDate] += accumulatorFn([dataset]);
        } else {
            acc[startDate] = accumulatorFn([dataset]);
        }

        return acc;
    }, {});

    return Object.entries(groupedData).map(([startDate, value]) => ({
        startDate,
        value,
    }));
};

const processedChartOverallData = (
    data: OperationsOverallStatsResponse | undefined,
    dataType: Section,
    regionId: string
) => {
    if (!data) {
        return null;
    }

    const accumulators = CHART_SECTION_ACCUMULATORS[dataType];
    const accumulator = accumulators.accumulator;
    const accumulator2 = ('accumulator2' in accumulators && accumulators.accumulator2) || null;

    const filteredData = getFilteredData(data, regionId, dataType);

    const groupedDatasets = groupDatasets(filteredData, accumulator);

    const groupedDatasets2 = accumulator2 ? groupDatasets(filteredData, accumulator2) : [];

    const orderedDatasets = orderBy(groupedDatasets, 'startDate', 'asc');

    const orderedDatasets2 = orderBy(groupedDatasets2, 'startDate', 'asc');

    return {
        data: orderedDatasets,
        data2: orderedDatasets2.length > 0 ? orderedDatasets2 : undefined,
        section: dataType,
    };
};

const preprocessOverallData = (
    data: OperationsOverallStatsResponse,
    dataType: Section,
    regionId: string,
    level: OperationsLevel
) => {
    const accumulators = TABLE_SECTION_ACCUMULATORS[dataType];
    const accumulator = accumulators.accumulator;
    const accumulator2 = ('accumulator2' in accumulators && accumulators.accumulator2) || null;

    let datasets = data.sections.find(({ section }) => section === dataType)?.datasets || [];

    if (Number(regionId) > 0) {
        datasets = datasets.filter((dataset) => dataset.regionId === Number(regionId));
    }

    const levelDatasets: {
        id: number;
        datasets: OperationsOverallStatsDataset[];
    }[] = groupByLevel(datasets, level === 'company' ? 'region' : 'branch');

    const groupedLevelDatasets: {
        id: number;
        datasets: StandardDataset[];
    }[] = groupLevelDatasets(levelDatasets, accumulator);

    const groupedLevelDatasets2: {
        id: number;
        datasets: StandardDataset[];
    }[] = accumulator2 ? groupLevelDatasets(levelDatasets, accumulator2) : [];

    const orderedGroupedLevelDatasets = groupedLevelDatasets.map((group) => ({
        id: group.id,
        datasets: orderBy(group.datasets, 'startDate', 'desc'),
    }));

    const orderedGroupedLevelDatasets2 = accumulator2
        ? groupedLevelDatasets2.map((group) => ({
              id: group.id,
              datasets: orderBy(group.datasets, 'startDate', 'desc'),
          }))
        : undefined;

    return {
        orderedGroupedLevelDatasets,
        orderedGroupedLevelDatasets2,
    };
};

const processedTableOverallData = (
    data: OperationsOverallStatsResponse | undefined,
    dataType: Section,
    regionId: string,
    startDate: string | undefined,
    branchesByRegion: BranchesByRegionResponse | undefined,
    level: OperationsLevel
) => {
    if (!data || !branchesByRegion) {
        return null;
    }

    const { orderedGroupedLevelDatasets, orderedGroupedLevelDatasets2 } = preprocessOverallData(
        data,
        dataType,
        regionId,
        level
    );

    const rows = transformDataForMuiGrid(
        orderedGroupedLevelDatasets,
        branchesByRegion,
        level,
        startDate,
        orderedGroupedLevelDatasets2
    );

    return {
        rows,
    };
};

export const getStartDateOptions = (
    data: OperationsOverallStatsResponse | undefined,
    dataType: Section,
    regionId: string,
    level: OperationsLevel
) => {
    if (!data) {
        return null;
    }

    const { orderedGroupedLevelDatasets } = preprocessOverallData(data, dataType, regionId, level);

    // remove the last start date since we can't show the time over time change
    const startDateOptions = orderedGroupedLevelDatasets[0].datasets.map(({ startDate }) => startDate).slice(0, -1);

    return startDateOptions;
};

const getOverallStat = (data: OperationsOverallStatsResponse | undefined, dataType: Section, regionId: string) => {
    if (!data) {
        return '0';
    }

    return String(
        calculateAmountForPeriod(
            getFilteredData(data, regionId, dataType),
            dataType,
            1 // previous period (last week, last month, etc.)
        )
    );
};

export const useOverallDataItems = (
    data: OperationsOverallStatsResponse | undefined,
    branchesByRegion: BranchesByRegionResponse | undefined,
    dataType: Section,
    level: OperationsLevel,
    regionId: string,
    startDate: string | undefined
) => {
    const { ChartComponent } = OVERALL_SECTIONS_MAP[dataType];
    const chartProps = useMemo(
        () => processedChartOverallData(data, dataType, regionId),
        [JSON.stringify(data), dataType, regionId]
    );

    const tableProps = useMemo(
        () => processedTableOverallData(data, dataType, regionId, startDate, branchesByRegion, level),
        [JSON.stringify(data), JSON.stringify(branchesByRegion), dataType, regionId, level, startDate]
    );

    const overallStat = useMemo(
        () => getOverallStat(data, dataType, regionId),
        [JSON.stringify(data), dataType, regionId, level]
    );

    if (!data || chartProps === null || tableProps === null) {
        return {};
    }

    return {
        OverallChartComponent: ChartComponent,
        overallChartProps: chartProps,
        OverallTableComponent: OverallStatsTable,
        overallTableProps: {
            ...tableProps,
            level,
            dataType,
        },
        overallStat,
    };
};
