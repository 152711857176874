import { Box } from '@mui/material';
import React from 'react';

import { NestedRoutes } from 'types/routes';

import { pxToRem } from 'components/theme/typography';
import {
    BASE_PATH,
    DESKTOP_HEADER_HEIGHT,
    HOME_PATH,
    LOGIN_PATH,
    LOGIN_USERNAME_PATH,
    LOGIN_VERIFY_PATH,
    MANAGER_TASK_LIST,
    MOBILE_BOTTOM_MENU_HEIGHT,
    MOBILE_HEADER_HEIGHT,
    MY_REWARDS_HISTORY_PATH,
    MY_REWARDS_PATH,
    OPERATIONS_SNAPSHOTS_PATH,
    PERFORMANCE_SNAPSHOTS_PATH,
    PRIVACY_POLICY,
    RESIDENTS_PATH,
    RESIDENT_DASHBOARD_PATH,
    RESIDENT_DETAILS_PATH,
    SIGNUP_PATH,
    SIGNUP_VERIFY_PATH,
    TERMS_OF_SERVICE,
    USERS_PROFILE_PATH,
} from 'constants/app';
import Home from 'pages/Home';
import Login from 'pages/Login';
import { LoginNew } from 'pages/LoginNew';
import { CodeVerificationStep } from 'pages/LoginNew/CodeVerificationStep';
import { UsernameLoginPage } from 'pages/LoginNew/UsernameLoginPage';
import MyRewards from 'pages/MyRewards';
import MyRewardsHistory from 'pages/MyRewards/HistoryPage';
import { OperationsDashboard } from 'pages/OperationsDashboard';
import PageNotFound from 'pages/PageNotFound';
import { PerformanceDashboard } from 'pages/PerformanceDashboard';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import { ResidentDashboard } from 'pages/ResidentDashboard';
import Residents from 'pages/Residents';
import ResidentDetails from 'pages/Residents/Details';
import { Signup } from 'pages/Signup';
import TermsOfService from 'pages/TermsOfService';
import UserProfile from 'pages/Users/Profile';

const Wrapper = ({ grayStyles = {}, children, stackedHeader = false }) => (
    <Box
        sx={[
            {
                pt: { xs: pxToRem(8), md: 0 },
                backgroundColor: grayStyles,
                minHeight: {
                    xs: `calc(100vh - ${pxToRem(MOBILE_HEADER_HEIGHT + MOBILE_BOTTOM_MENU_HEIGHT)})`,
                    lg: `calc(100vh - ${pxToRem(DESKTOP_HEADER_HEIGHT)})`,
                },
            },
            !stackedHeader && {
                px: { xs: pxToRem(8), md: pxToRem(16) },
                pt: { md: pxToRem(16) },
                pb: { xs: pxToRem(8), md: pxToRem(16) },
            },
        ]}
    >
        {children}
    </Box>
);

const AllGrayWrapper = ({ children }) => (
    <Wrapper
        grayStyles={{
            xs: 'app.grey.main',
        }}
    >
        {children}
    </Wrapper>
);

const OperationsDashboardWrapper = ({ children }) => (
    <Wrapper
        stackedHeader
        grayStyles={{
            xs: 'primary.dark',
            lg: 'common.white',
        }}
    >
        {children}
    </Wrapper>
);

const WhiteWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

export const AppRoutes: NestedRoutes = {
    titleId: 'navigator',
    path: BASE_PATH,
    Component: Login,
    Wrapper: WhiteWrapper,
    children: [
        {
            titleId: 'login',
            path: LOGIN_PATH,
            Component: LoginNew,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'verify',
                    path: LOGIN_VERIFY_PATH,
                    Component: CodeVerificationStep,
                    Wrapper: WhiteWrapper,
                },
                {
                    titleId: 'usernameLogin',
                    path: LOGIN_USERNAME_PATH,
                    Component: UsernameLoginPage,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'signup',
            path: SIGNUP_PATH,
            Component: Signup,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'verify',
                    path: SIGNUP_VERIFY_PATH,
                    Component: CodeVerificationStep,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'privacyPolicy',
            path: PRIVACY_POLICY,
            Component: PrivacyPolicy,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'termsOfService',
            path: TERMS_OF_SERVICE,
            Component: TermsOfService,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'dashboards',
            path: OPERATIONS_SNAPSHOTS_PATH,
            Component: OperationsDashboard,
            Wrapper: OperationsDashboardWrapper,
            children: [
                {
                    titleId: 'residentDetails',
                    path: RESIDENT_DASHBOARD_PATH,
                    Component: ResidentDashboard,
                    Wrapper: AllGrayWrapper,
                },
                {
                    titleId: 'performanceSnapshots',
                    path: PERFORMANCE_SNAPSHOTS_PATH,
                    Component: PerformanceDashboard,
                    Wrapper: AllGrayWrapper,
                },
            ],
        },
        {
            titleId: 'home',
            path: HOME_PATH,
            Component: Home,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'managerTaskList',
            path: MANAGER_TASK_LIST,
            Component: Home,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'residents',
            path: RESIDENTS_PATH,
            Component: Residents,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'residentDetails',
                    path: RESIDENT_DETAILS_PATH,
                    Component: ResidentDetails,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'myRewards',
            path: MY_REWARDS_PATH,
            Component: MyRewards,
            Wrapper: WhiteWrapper,
            children: [
                {
                    titleId: 'rewardsHistory',
                    path: MY_REWARDS_HISTORY_PATH,
                    Component: MyRewardsHistory,
                    Wrapper: WhiteWrapper,
                },
            ],
        },
        {
            titleId: 'userProfile',
            path: USERS_PROFILE_PATH,
            Component: UserProfile,
            Wrapper: WhiteWrapper,
        },
        {
            titleId: 'pageNotFound',
            path: '*',
            Component: PageNotFound,
            Wrapper: WhiteWrapper,
        },
    ],
};
