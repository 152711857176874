import { CalendarMonthOutlined as CalendarIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import momentTz from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CustomDate, DateRange } from 'types/common';
import { DashboardFilters } from 'types/dashboard';
import { ReduxStore } from 'types/redux';

import DateRangeDialog from 'components/Shared/DateRangeDialog';
import { pxToRem } from 'components/theme/typography';
import { displayDateRange } from 'lib/dashboard';
import { updateFilters } from 'redux/actions/dashboards';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    boxContainer: {
        borderRadius: pxToRem(8),
        borderWidth: pxToRem(1),
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
    },
}));

const DateRangeContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(8),
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: pxToRem(240),
        height: pxToRem(48),
    })
);

const DateRangeText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexGrow: 1,
    })
);

const DateRangeIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        display: 'flex',
    })
);

const IndependentDateRangeIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(4),
        mr: pxToRem(8),
    })
);

type Props = {
    filters: DashboardFilters;
    timezone: string;
    showFullDateRange: boolean;
    dispatchUpdateDashboardFilters: (newFilters: DashboardFilters) => void;
};

const DatePickerContainer = (props: Props) => {
    const classes = useStyles();
    const { filters, timezone, showFullDateRange, dispatchUpdateDashboardFilters } = props;
    const [openDateRangeDialog, setOpenDateRangeDialog] = useState<boolean>(false);

    const handleDateRangeDialogOpen = () => {
        setOpenDateRangeDialog(true);
    };

    const handleDateRangeDialogClose = () => {
        setOpenDateRangeDialog(false);
    };

    const handleDateRangeConfirm = (newDateRange: [CustomDate, CustomDate]) => {
        const [startDate, endDate] = newDateRange;

        // Define the new filters for Dashboard page.
        const newFilters: DashboardFilters = {
            ...filters,
            dateRange: {
                startDate,
                endDate,
            },
        };

        // Update the filters for the Dashboard.
        dispatchUpdateDashboardFilters(newFilters);
    };

    useEffect(() => {
        const previousDayDateTime = new Date(momentTz.tz(new Date(), timezone).subtract(1, 'day').format());
        const newDateRange: DateRange = {
            startDate: previousDayDateTime,
            endDate: previousDayDateTime,
        };

        // Define the new filters for Dashboard page.
        const newFilters: DashboardFilters = {
            ...filters,
            dateRange: newDateRange,
        };

        // Update the filters for the Dashboard.
        dispatchUpdateDashboardFilters(newFilters);
    }, []);

    if (!filters.dateRange) {
        return null;
    }

    return (
        <>
            {showFullDateRange ? (
                <DateRangeContainer className={classes.boxContainer} onClick={handleDateRangeDialogOpen}>
                    <DateRangeText variant="body2">
                        {displayDateRange(filters.dateRange.startDate, filters.dateRange.endDate)}
                    </DateRangeText>
                    <DateRangeIconContainer>
                        <CalendarIcon />
                    </DateRangeIconContainer>
                </DateRangeContainer>
            ) : (
                <IndependentDateRangeIconContainer
                    sx={{ display: 'flex' }}
                    className={classes.boxContainer}
                    onClick={handleDateRangeDialogOpen}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        {displayDateRange(filters.dateRange.startDate, filters.dateRange.endDate, true)}
                    </Box>
                    <CalendarIcon />
                </IndependentDateRangeIconContainer>
            )}
            <DateRangeDialog
                open={openDateRangeDialog}
                startDate={filters.dateRange.startDate}
                endDate={filters.dateRange.endDate}
                disableFuture
                onClose={handleDateRangeDialogClose}
                onConfirm={handleDateRangeConfirm}
            />
        </>
    );
};

const mapStateToProps = ({ dashboards, session }: ReduxStore) => {
    const { filters } = dashboards;
    const { timezone } = session;

    return {
        filters,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateDashboardFilters: (newFilters: DashboardFilters) => dispatch(updateFilters(newFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerContainer);
