import { Box, Typography } from '@mui/material';
import Pluralize from 'pluralize';
import React from 'react';
import { IoChevronForwardSharp as ChevronRightIcon } from 'react-icons/io5';

import { pxToRem } from 'components/theme/typography';

type Props = {
    titleNum?: string;
    title: string;
    stat?: number;
    subtitle?: string;
    openRewardTypeDetailsDialog: () => void;
};

const PointItem = ({ titleNum, title, stat, subtitle, openRewardTypeDetailsDialog }: Props) => {
    let subtitleText = (
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(11),
                    md: pxToRem(12),
                },
                color: '#6F6F79',
            }}
        >
            {subtitle}
        </Typography>
    );

    if (stat !== undefined) {
        subtitleText = (
            <Typography
                sx={{
                    fontSize: {
                        xs: pxToRem(11),
                        md: pxToRem(12),
                    },
                    color: 'primary.main',
                    fontWeight: 700,
                }}
            >
                {`${stat} ${Pluralize('point', stat)}`}
            </Typography>
        );
    }

    return (
        <Box
            onClick={openRewardTypeDetailsDialog}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: pxToRem(16),
                borderRadius: pxToRem(8),
                border: '1px solid #F4F4F5',
                backgroundColor: '#F8F8F9',
                color: '#2C2D3B',
                cursor: {
                    lg: 'pointer',
                },
            }}
        >
            <Box
                sx={{
                    fontSize: pxToRem(14),
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 700,
                        display: 'inline',
                        fontSize: {
                            xs: pxToRem(14),
                        },
                    }}
                >
                    {titleNum}
                </Typography>
                {` ${title}`}
                {subtitleText}
            </Box>

            <Box
                sx={{
                    width: pxToRem(36),
                    height: pxToRem(36),
                    borderRadius: '50%',
                    color: '#006B75',
                    backgroundColor: '#C2DBDE',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ChevronRightIcon size={20} />
            </Box>
        </Box>
    );
};

export default PointItem;
