import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
};

export const DecreaseArrow = ({ className, size }: Props) => (
    <Base className={className} size={size} viewBox="0 0 16 16">
        <path
            d="M12.7507 5.49999V12C12.7507 12.1989 12.6717 12.3897 12.531 12.5303C12.3904 12.671 12.1996 12.75 12.0007 12.75H5.50068C5.30176 12.75 5.111 12.671 4.97035 12.5303C4.82969 12.3897 4.75068 12.1989 4.75068 12C4.75068 11.8011 4.82969 11.6103 4.97035 11.4697C5.111 11.329 5.30176 11.25 5.50068 11.25H10.1882L3.47005 4.52999C3.40029 4.46022 3.34495 4.3774 3.30719 4.28625C3.26943 4.1951 3.25 4.0974 3.25 3.99874C3.25 3.79948 3.32915 3.60838 3.47005 3.46749C3.61095 3.32659 3.80204 3.24744 4.0013 3.24744C4.20056 3.24744 4.39165 3.32659 4.53255 3.46749L11.2507 10.1875V5.49999C11.2507 5.30107 11.3297 5.11031 11.4703 4.96966C11.611 4.829 11.8018 4.74999 12.0007 4.74999C12.1996 4.74999 12.3904 4.829 12.531 4.96966C12.6717 5.11031 12.7507 5.30107 12.7507 5.49999Z"
            fill="currentColor"
        />
    </Base>
);
