import { PaletteColor, SimplePaletteColorOptions, TypeAction } from '@mui/material';
import { alpha } from '@mui/material/styles';

import {
    CustomColor,
    PaletteActivityIndicatorColors,
    PaletteAppColors,
    PaletteChartColors,
    PaletteGoogleColors,
    PaletteInstagramColors,
    PaletteNavbar,
    PaletteNavigation,
    PaletteSocialMediaColors,
} from 'types/theme';

export const THEME_COLOR_COMMON = {
    black: '#000000',
    white: '#ffffff',
};

export const THEME_COLOR_GREY: CustomColor = {
    100: '#f9fafb',
    200: '#f4f4f5',
    300: '#dfe3e8',
    400: '#c4cdd5',
    500: '#919eab',
    600: '#637381',
    700: '#454f5b',
    800: '#212b36',
    900: '#161c24',
    500_8: alpha('#919eab', 0.08),
    500_12: alpha('#919eab', 0.12),
    500_16: alpha('#919eab', 0.16),
    500_24: alpha('#919eab', 0.24),
    500_32: alpha('#919eab', 0.32),
    500_40: alpha('#919eab', 0.4),
    500_48: alpha('#919eab', 0.48),
    500_56: alpha('#919eab', 0.56),
    500_80: alpha('#919eab', 0.8),
};

export const THEME_COLOR_BLUE: SimplePaletteColorOptions = {
    light: '#6666ff',
    main: '#0000cc',
};

export const THEME_COLOR_GREEN: SimplePaletteColorOptions = {
    light: '#8bc473',
    main: '#11a263',
    dark: '#0b683f',
};

export const THEME_COLOR_ORANGE: SimplePaletteColorOptions = {
    light: '#ffd166',
    main: '#FA9C7A',
    dark: '#e09e00',
};

export const THEME_COLOR_YELLOW = '#fceb2a';

export const THEME_APP_COLORS: PaletteAppColors = {
    green: {
        light: '#478e94',
        main: '#006b75',
    },
    grey: {
        light: '#f9fbfb',
        main: '#f7f7fa',
    },
    orange: {
        light: '#fcd7b1',
        main: '#fa9c7a',
    },
};

export const THEME_COLOR_PRIMARY: PaletteColor = {
    light: alpha(THEME_APP_COLORS.green.light as string, 0.7),
    main: THEME_APP_COLORS.green.light as string,
    dark: THEME_APP_COLORS.green.main as string,
    contrastText: THEME_COLOR_COMMON.white,
};

export const THEME_COLOR_SECONDARY: PaletteColor = {
    light: alpha(THEME_APP_COLORS.orange.light as string, 0.7),
    main: THEME_APP_COLORS.orange.light as string,
    dark: THEME_APP_COLORS.orange.main as string,
    contrastText: THEME_COLOR_GREY[800],
};

export const THEME_COLOR_INFO: PaletteColor = {
    light: '#74caff',
    main: '#1890ff',
    dark: '#0c53b7',
    contrastText: THEME_COLOR_COMMON.white,
};

export const THEME_COLOR_SUCCESS: PaletteColor = {
    light: '#aaf27f',
    main: '#54d62c',
    dark: '#229a16',
    contrastText: THEME_COLOR_GREY[800],
};

export const THEME_COLOR_WARNING: PaletteColor = {
    light: '#ffe16a',
    main: '#ffc107',
    dark: '#b7b103',
    contrastText: THEME_COLOR_GREY[800],
};

export const THEME_COLOR_ERROR: PaletteColor = {
    light: '#ffa48d',
    main: '#ff4842',
    dark: '#b72136',
    contrastText: THEME_COLOR_COMMON.white,
};

export const THEME_COLOR_NAVIGATION: PaletteNavigation = {
    background: THEME_APP_COLORS.grey.main,
    selected: THEME_APP_COLORS.green.main,
    unselected: THEME_COLOR_GREY[600],
};

const THEME_COLOR_GOOGLE: PaletteGoogleColors = {
    blue: '#4285F4',
    green: '#0f9d58',
    red: '#db4437',
    yellow: '#f4b400',
};

const THEME_COLOR_INSTAGRAM: PaletteInstagramColors = {
    blue: {
        light: '#405de6',
        main: '#5851db',
    },
    orange: {
        light: '#f77737',
        main: '#f56040',
        dark: '#fd1d1d',
    },
    pink: {
        main: '#e1306c',
        dark: '#c13584',
    },
    purple: {
        main: '#833ab4',
    },
    yellow: {
        light: '#ffdc80',
        main: '#fcaf45',
    },
};

export const THEME_COLOR_SOCIAL_MEDIA: PaletteSocialMediaColors = {
    facebook: '#3c5a99',
    google: { ...THEME_COLOR_GOOGLE },
    instagram: { ...THEME_COLOR_INSTAGRAM },
    twitter: '#55acee',
    youtube: '#ff0000',
    whatsapp: '#20B038',
};

export const THEME_COLOR_ACTIONS: TypeAction = {
    active: THEME_COLOR_GREY[600],
    activatedOpacity: 0.16,
    disabled: THEME_COLOR_GREY[500_80],
    disabledBackground: THEME_COLOR_GREY[500_24],
    disabledOpacity: 0.48,
    hover: THEME_COLOR_GREY[500_8],
    hoverOpacity: 0.08,
    focus: THEME_COLOR_GREY[500_24],
    focusOpacity: 0.16,
    selected: THEME_COLOR_GREY[500_16],
    selectedOpacity: 0.16,
};

export const THEME_COLOR_NAVBAR: PaletteNavbar = {
    background: '#0e2e47',
    contrastText: THEME_COLOR_COMMON.white,
};

export const THEME_COLOR_CHARTS: PaletteChartColors = {
    incomplete: '#FA9C7A',
    overdue: '#EC3232',
};

export const THEME_COLOR_SHADOW: string = alpha(THEME_COLOR_COMMON.black, 0.85);
export const THEME_COLOR_BORDER = '#cccccc';
export const THEME_COLOR_TABLE_HEADER = '#dce7e9';
export const THEME_FONT_FAMILY = 'Open Sans, sans-serif';
export const THEME_COLOR_BADGE = '#0CA3B1';

export const THEME_COLOR_ACTIVITY_INDICATOR: PaletteActivityIndicatorColors = {
    complete: '#006B75',
    incomplete: '#2C2D3B',
    completeBackground: '#C2DBDE',
    incompleteBackground: '#F4F4F5',
    tasksCompletedBackground: '#F2F8F8',
};

export const THEME_COLOR_SELECTOR = {
    deselected: '#dedee0',
    selected: '#30878f',
};

export const DIALOG_OVERRIDES = {
    '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiTypography-root.MuiDialogTitle-root': {
        p: 0,
    },
    '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root': {
        p: 0,
    },
    '& .MuiDialog-container': {
        '& .MuiPaper-root.MuiDialog-paper': {
            m: {
                xs: 0,
                md: '1rem',
            },
        },
    },
};
