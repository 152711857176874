import { AbilityTuple, MongoAbility, MongoQuery, createMongoAbility } from '@casl/ability';
import * as Sentry from '@sentry/react';
import { concat } from 'lodash';
import { ActionResourceSchema, Permit, permitState } from 'permit-fe-sdk';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { useBranchesByRegionQuery } from 'api/queries/companyInfo';

import { USER_ACTIONS } from './constants';

const genericActionToUserActions = (
    action: ActionResourceSchema,
    branchIds: number[],
    regionIds: number[],
    companyId: number
) => {
    let ids: number[] = [];
    switch (action.resource) {
        case 'Company':
            ids = [companyId];
            break;
        case 'Region':
            ids = regionIds;
            break;
        default:
            ids = branchIds;
    }

    return ids.map((id) => ({
        ...action,
        resource: `${action.resource}:${id}`,
    }));
};

// Create Context
export const AbilityContext = createContext<MongoAbility<AbilityTuple, MongoQuery> | undefined>(undefined);

export const AbilityLoader = ({ children }) => {
    const {
        isSignedIn,
        sessionData: { userId },
    } = useSelector((state: ReduxStore) => state.session);
    const [ability, setAbility] = useState<MongoAbility<AbilityTuple, MongoQuery> | undefined>(undefined);
    const { data: branchesByRegionData, isError: branchesByRegionDataIsError } = useBranchesByRegionQuery(!!isSignedIn);

    useEffect(() => {
        if (branchesByRegionDataIsError) {
            Sentry.captureException(new Error(branchesByRegionDataIsError.toString()));
        }
    }, [branchesByRegionDataIsError]);

    useEffect(() => {
        const getAbility = async (user: number, branchIds: number[], regionIds: number[], companyId: number) => {
            const permit = Permit({
                loggedInUser: user.toString(),
                backendUrl: `${process.env.REACT_APP_BASE_SERVER_URL}sessions/permission-check`,
            });

            const actions = concat(
                ...USER_ACTIONS.map((action) => genericActionToUserActions(action, branchIds, regionIds, companyId))
            );

            // TODO: fill out the actual permissions
            await permit.loadLocalStateBulk(actions);

            const caslConfig = permitState.getCaslJson();

            return caslConfig && caslConfig.length ? createMongoAbility(caslConfig) : undefined;
        };

        if (isSignedIn && branchesByRegionData) {
            const branchIds = branchesByRegionData.branches.map(({ branchId }) => branchId);
            const regionIds = branchesByRegionData.regions.map(({ regionId }) => regionId);
            getAbility(userId, branchIds, regionIds, branchesByRegionData.companyId).then((caslAbility) => {
                setAbility(caslAbility);
            });
        }
    }, [isSignedIn, userId, branchesByRegionData]);

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
