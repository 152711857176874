import { DEFAULT_SHIFT_OPTIONS, getShiftAtDateTime } from '@alliehealth/utils/dist/shifts';
import {
    Assignment as AssignmentIcon,
    CheckCircleOutlined as CheckIcon,
    CancelOutlined as RejectIcon,
} from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { addMinutes, format, isWithinInterval, set } from 'date-fns';
import moment from 'moment';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import { DailyTasksByTab, DailyTasksUpdateParams } from 'types/dailyTasks';
import { LanguageStructure } from 'types/language';
import { MessageProps } from 'types/messages';
import { ReduxStore } from 'types/redux';
import { ResidentDailyTasksResponse } from 'types/residents';
import { StoredSession } from 'types/session';
import { TaskStatusesReadParams, TaskStatusesResponse } from 'types/taskStatuses';

import AllTasksCompletedIndicator from './components/AllTasksCompletedIndicator';
import EarlyInShiftConfirmationDialog from './components/EarlyInShiftConfirmationDialog';
import { useResidentDailyTaskMutation, useResidentDailyTasksQuery } from 'api/queries/residents';
import { useDocumentationActionsQuery } from 'api/queries/tasks/documentationActions';
import notificationSound from 'assets/notification-sound.mp3';
import { CustomTabPanel as TabPanel } from 'components/Custom';
import EditTaskStatusDialog from 'components/Shared/EditTaskStatusDialog';
import Loading from 'components/Shared/Loading';
import NurseCallsDialog from 'components/Shared/NurseCallsDialog';
import { AddTaskNotesDialog } from 'components/Shared/Task/AddTaskNotesDialog';
import { TaskDetails } from 'components/Shared/Task/TaskDetails';
import { TaskNotesViewDialog as InstructionsViewDialog } from 'components/Shared/Task/TaskNotesViewDialog';
import { TaskRowContainer } from 'components/Shared/Task/TaskRowContainer';
import { pxToRem } from 'components/theme/typography';
import { CHECKBOX_WIDTH, EARLY_IN_SHIFT_MINUTES } from 'constants/home';
import { NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL, PROFILE } from 'constants/localStorage';
import { DIALOG_TYPE } from 'constants/shared';
import { showAlert } from 'redux/actions/messages';
import { toggleResidentParty } from 'redux/actions/residents';
import { readTaskStatuses } from 'redux/actions/tasksStatuses';

const TabsStyle = styled(Tabs)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        '& .MuiButtonBase-root.MuiTab-root': {
            width: '50%',
        },
    })
);

const TasksContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        mb: pxToRem(32),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    })
);

const TaskIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.app.green.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: pxToRem(32),
        height: pxToRem(32),
        borderRadius: '50%',
    })
);

const TaskDescriptionContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(40 + CHECKBOX_WIDTH)})`,
    })
);

const CheckboxContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pl: pxToRem(8),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.app.green.main,
        width: pxToRem(CHECKBOX_WIDTH),
    })
);

type Props = {
    dictionary: LanguageStructure;
    residentId: number;
    timezone: string;
    dispatchShowAlert: (message: MessageProps) => void;
    dispatchToggleResidentParty: () => void;
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) => void;
    taskStatusesList: TaskStatusesResponse[];
    residentName: string;
};

const ResidentTasksContainer = (props: Props) => {
    const {
        dictionary: { home, error: errorDictionary },
        residentId,
        timezone,
        taskStatusesList,
        residentName,
        dispatchShowAlert,
        dispatchToggleResidentParty,
        dispatchReadTaskStatuses,
    } = props;
    const [selectedRecord, setSelectedRecord] = useState<ResidentDailyTasksResponse>();
    const [isEditTaskStatusDialogOpen, setIsEditTaskStatusDialogOpen] = useState<boolean>(false);
    const [isEarlyInShiftConfirmationDialogOpen, setIsEarlyInShiftConfirmationDialogOpen] = useState<boolean>(false);
    const [dialogType, setDialogType] = useState<DIALOG_TYPE>(DIALOG_TYPE.reject);
    const [selectedInstructions, setSelectedInstructions] = useState<string>();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [tasksByTab, setTasksByTab] = useState<DailyTasksByTab>([]);
    const [playCoinSound] = useSound(notificationSound);
    const [viewInstructionsIdNotes, setViewInstructionsIdNotes] = useState<number | null>(null);
    const [showTaskIdAddNotesDialog, setShowTaskIdAddNotesDialog] = useState<number | null>(null);
    const [selectedTaskStatusId, setSelectedTaskStatusId] = useState<number>(0);
    const [taskIdCompletionOptions, setTaskIdCompletionOptions] = useState<{
        [key: string]: number;
    }>({});
    const [handleCompleteTaskParams, setHandleCompleteTaskParams] = useState<{
        dailyTaskRecordId: number;
        params: DailyTasksUpdateParams;
    } | null>(null);
    const [handleEditTaskStatusDialogOpenParams, setHandleEditTaskStatusDialogOpenParams] = useState<{
        newDailyTaskRecord: ResidentDailyTasksResponse;
        newDialogType: DIALOG_TYPE;
    } | null>(null);
    const [isNurseCallsDialogOpen, setIsNurseCallsDialogOpen] = useState<boolean>(false);

    const [taskIdNotes, setTaskIdNotes] = useState<{ [key: number]: string }>({});

    const { mutateAsync: residentDailyTaskMutation } = useResidentDailyTaskMutation();

    const {
        data: residentDailyTasks,
        isLoading: residentDailyTasksIsLoading,
        isError: residentDailyTasksIsError,
    } = useResidentDailyTasksQuery(residentId);

    const pendingTaskRecords = residentDailyTasks?.pendingTaskRecords || [];
    const confirmedTaskRecords = residentDailyTasks?.confirmedTaskRecords || [];

    const {
        shift: { id: currentShift },
        shiftDayDate: currentDate,
    } = getShiftAtDateTime(new Date(), timezone, DEFAULT_SHIFT_OPTIONS);

    const pendingShiftTasks = useMemo(
        () => pendingTaskRecords?.filter((task) => task.taskShift === currentShift) || [],
        [currentShift, pendingTaskRecords]
    );

    const getCurrentTimeAndShiftStartTime = () => {
        const currentTime = new Date();
        const {
            shift: { startTimeInclusive },
        } = getShiftAtDateTime(currentTime, timezone, DEFAULT_SHIFT_OPTIONS);

        const [hours] = startTimeInclusive.split(':');

        const shiftStartTime = set(currentTime, {
            hours: Number(hours),
            minutes: 0,
            seconds: 0,
        });
        return { currentTime, shiftStartTime };
    };

    const checkIfEarlyInShift = () => {
        const noEarlyInShiftConfirmationUntil = JSON.parse(
            localStorage.getItem(NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL) as string
        );
        if (noEarlyInShiftConfirmationUntil && noEarlyInShiftConfirmationUntil.expire > new Date().getTime()) {
            return false;
        }

        const { currentTime, shiftStartTime: shiftIntervalStart } = getCurrentTimeAndShiftStartTime();

        const shiftIntervalEnd = addMinutes(shiftIntervalStart, EARLY_IN_SHIFT_MINUTES);
        return isWithinInterval(currentTime, {
            start: shiftIntervalStart,
            end: shiftIntervalEnd,
        });
    };

    const { refetch: documentationActionsRefetch } = useDocumentationActionsQuery({
        residentId,
        date: currentDate,
        shift: currentShift,
    });

    const checkDocumentationActions = async () => {
        const { data } = await documentationActionsRefetch();
        if (data?.showNurseCallsActions) setIsNurseCallsDialogOpen(true);
    };

    // Get the user's ID and his/her Role Level ID.
    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const { userId = 0, roleLevelId = 0 } = storedSession?.sessionData || {};
    const isDirector = roleLevelId === 4;
    const isCaregiver = roleLevelId === 5;
    const isAllowed = isDirector || isCaregiver;

    const permissionError = () => {
        // Set the message to display into the alert.
        const message: MessageProps = {
            open: true,
            message: errorDictionary.onlyDirectorsCareGivers,
            alertSeverity: 'error',
            status: 400,
        };

        dispatchShowAlert(message);
    };

    const editTaskStatusDialogOpen = (newDailyTaskRecord: ResidentDailyTasksResponse, newDialogType: DIALOG_TYPE) => {
        setSelectedRecord(newDailyTaskRecord);

        setDialogType(newDialogType);

        setIsEditTaskStatusDialogOpen(true);

        setHandleEditTaskStatusDialogOpenParams(null);
    };

    const handleEditTaskStatusDialogOpen = (
        newDailyTaskRecord: ResidentDailyTasksResponse,
        newDialogType: DIALOG_TYPE
    ) => {
        if (!isAllowed) {
            permissionError();
            return;
        }

        if (checkIfEarlyInShift()) {
            setHandleEditTaskStatusDialogOpenParams({
                newDailyTaskRecord,
                newDialogType,
            });
            setHandleCompleteTaskParams(null);
            toggleEarlyInShiftConfirmationDialog();
            return;
        }

        editTaskStatusDialogOpen(newDailyTaskRecord, newDialogType);
    };

    const toggleEarlyInShiftConfirmationDialog = () => {
        setIsEarlyInShiftConfirmationDialogOpen((prevState) => !prevState);
    };

    const handleDialogClose = () => {
        setViewInstructionsIdNotes(null);
        setShowTaskIdAddNotesDialog(null);
        setIsEditTaskStatusDialogOpen(false);
        setSelectedInstructions('');
    };

    const getParams = (taskId: number, taskStatusId: number, notes?: string) => {
        const params: DailyTasksUpdateParams = {
            userId: Number(userId),
            taskStatusId,
            caregiverNotes: notes || taskIdNotes[taskId],
            taskCompletionSelection: taskIdCompletionOptions[taskId]?.toString(),
        };

        return params;
    };

    const completeTask = async (dailyTaskRecordId: number, params: DailyTasksUpdateParams) => {
        // Mark the user has viewed the Resident's Profile.
        params.residentProfileViewed = true; // eslint-disable-line no-param-reassign

        // Trigger the action for updating the Task as Complete.
        residentDailyTaskMutation({
            taskId: dailyTaskRecordId,
            residentId,
            jsonParams: params,
        }).then(checkDocumentationActions);

        if (params.caregiverNotes) {
            setTaskIdNotes({
                ...taskIdNotes,
                [dailyTaskRecordId]: params.caregiverNotes,
            });
        }

        // if this is the last task to be complete for the shift then show the resident party
        if (pendingShiftTasks.length === 1 && pendingShiftTasks[0].dailyTaskRecordId === dailyTaskRecordId) {
            dispatchToggleResidentParty();
        }

        if (isCaregiver && params.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }
        setHandleCompleteTaskParams(null);
    };

    const handleCompleteTaskClick = (dailyTaskRecordId: number, params: DailyTasksUpdateParams) => {
        // If the user is not a Caregiver, exit the function.
        if (!isAllowed) {
            return;
        }

        if (checkIfEarlyInShift()) {
            setHandleCompleteTaskParams({ dailyTaskRecordId, params });
            setHandleEditTaskStatusDialogOpenParams(null);
            toggleEarlyInShiftConfirmationDialog();
            return;
        }

        completeTask(dailyTaskRecordId, params);
    };

    const handleRejectDialogSubmit = async (selectedValue: number, notes: string) => {
        const { dailyTaskRecordId } = selectedRecord as ResidentDailyTasksResponse;

        if (!isAllowed) {
            return;
        }

        const params = getParams(dailyTaskRecordId, selectedValue, notes);

        if (notes) {
            setTaskIdNotes({
                ...taskIdNotes,
                [dailyTaskRecordId]: notes,
            });
        }

        params.residentProfileViewed = true; // eslint-disable-line no-param-reassign

        residentDailyTaskMutation({
            taskId: dailyTaskRecordId,
            residentId,
            jsonParams: params,
        }).then(checkDocumentationActions);

        if (pendingShiftTasks.length === 1 && pendingShiftTasks[0].dailyTaskRecordId === dailyTaskRecordId) {
            dispatchToggleResidentParty();
        }
    };

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const taskRecordSorter = (a: ResidentDailyTasksResponse, b: ResidentDailyTasksResponse) => {
        const aDateTime = moment(`${a.taskDate}T${a.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        const bDateTime = moment(`${b.taskDate}T${b.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        if (aDateTime.isBefore(bDateTime)) {
            return -1;
        }

        if (aDateTime.isAfter(bDateTime)) {
            return 1;
        }

        return 0;
    };

    const toggleNurseCallsDialog = () => {
        setIsNurseCallsDialogOpen((prevState) => !prevState);
    };

    useEffect(() => {
        if (!taskStatusesList.length) {
            const params: TaskStatusesReadParams = {
                startingFromId: 1,
            };

            dispatchReadTaskStatuses(params);
        }
    }, []);

    useEffect(() => {
        if (pendingTaskRecords) {
            pendingTaskRecords.sort(taskRecordSorter);
        }

        if (confirmedTaskRecords) {
            confirmedTaskRecords.sort(taskRecordSorter);
        }

        setTasksByTab([
            {
                key: 0,
                tabName: 'pending',
                tasks: pendingTaskRecords,
            },
            {
                key: 1,
                tabName: 'nonPending',
                tasks: confirmedTaskRecords,
            },
        ]);
    }, [pendingTaskRecords, confirmedTaskRecords]);

    if (residentDailyTasksIsLoading) {
        return <Loading />;
    }

    if (residentDailyTasksIsError || !residentDailyTasks) {
        return null;
    }

    const updateConfirmedTaskNotes = (dailyTaskRecordId: number, params: DailyTasksUpdateParams) => {
        residentDailyTaskMutation({
            taskId: dailyTaskRecordId,
            residentId,
            jsonParams: params,
        });
    };

    const onSubmitEditAddTaskNotes = (taskNotes) => {
        // To make TS happy that it isn't null
        if (!showTaskIdAddNotesDialog) {
            return;
        }

        const newTaskNotes = {
            ...taskIdNotes,
            [showTaskIdAddNotesDialog]: taskNotes,
        };

        setTaskIdNotes(newTaskNotes);

        if (selectedTaskStatusId !== 1) {
            updateConfirmedTaskNotes(showTaskIdAddNotesDialog, {
                userId: Number(userId),
                taskStatusId: selectedTaskStatusId,
                caregiverNotes: taskNotes,
                taskCompletionSelection: taskIdCompletionOptions[showTaskIdAddNotesDialog]?.toString(),
            });
        }

        setShowTaskIdAddNotesDialog(null);
    };

    const renderTasks = (isPendingTab: boolean, noTasksLeft: boolean, taskList: ResidentDailyTasksResponse[]) => {
        if (isPendingTab && noTasksLeft) {
            return <AllTasksCompletedIndicator residentName={residentName} />;
        }

        return taskList.map((taskRecord: ResidentDailyTasksResponse) => {
            const {
                dailyTaskRecordId,
                taskName,
                taskTime,
                taskStatusId,
                taskNotes: instructions,
                caregiverNotes: userTaskNotes,
                taskCompletionOptions,
            } = taskRecord;

            const onCompletionOptionsChange = (taskId, completionValue) => {
                setTaskIdCompletionOptions({
                    ...taskIdCompletionOptions,
                    [taskId]: completionValue,
                });
            };

            const completionOptionValue = taskIdCompletionOptions[dailyTaskRecordId];

            const ICON_SIZE = { height: pxToRem(50), width: pxToRem(50) };

            const ICON_COLORS = {
                pendingTab: {
                    complete: 'app.green.main',
                    reject: 'common.black',
                },
                confirmedTab: {
                    complete: 'app.green.main',
                    reject: 'error.main',
                    disabled: 'grey.300',
                },
            };

            const confirmedIconColor = (statusId: number, isReject?: boolean) => {
                if (isReject && statusId === 2) {
                    return ICON_COLORS.confirmedTab.disabled;
                }
                if (isReject) {
                    return ICON_COLORS.confirmedTab.reject;
                }
                if (statusId === 2) {
                    return ICON_COLORS.confirmedTab.complete;
                }
                return ICON_COLORS.confirmedTab.disabled;
            };

            return (
                <TaskRowContainer key={dailyTaskRecordId}>
                    <TaskIconContainer>
                        <AssignmentIcon />
                    </TaskIconContainer>
                    <TaskDescriptionContainer>
                        <TaskDetails
                            taskName={taskName}
                            taskTime={taskTime}
                            taskNotes={instructions}
                            userTaskNotes={taskIdNotes[dailyTaskRecordId] || userTaskNotes}
                            taskCompletionOptions={taskCompletionOptions}
                            onToggleNotes={() => {
                                setSelectedInstructions(instructions);
                                setViewInstructionsIdNotes(dailyTaskRecordId);
                            }}
                            onToggleEditAddTaskNotes={() => {
                                setSelectedTaskStatusId(taskStatusId);
                                setShowTaskIdAddNotesDialog(dailyTaskRecordId);
                                if (!taskIdNotes[dailyTaskRecordId]) {
                                    setTaskIdNotes({
                                        ...taskIdNotes,
                                        [dailyTaskRecordId]: userTaskNotes,
                                    });
                                }
                            }}
                            onCompletionOptionsChange={(completionValue) =>
                                onCompletionOptionsChange(dailyTaskRecordId, completionValue)
                            }
                            completionOptionValue={completionOptionValue}
                        />
                    </TaskDescriptionContainer>
                    <CheckboxContainer>
                        <CheckIcon
                            onClick={() => {
                                if (isPendingTab) {
                                    handleCompleteTaskClick(dailyTaskRecordId, getParams(dailyTaskRecordId, 2));
                                } else {
                                    if (taskStatusId !== 2) {
                                        return;
                                    }

                                    handleEditTaskStatusDialogOpen(taskRecord, DIALOG_TYPE.edit);
                                }
                            }}
                            sx={{
                                color: isPendingTab
                                    ? ICON_COLORS.pendingTab.complete
                                    : confirmedIconColor(taskStatusId),
                                ...ICON_SIZE,
                            }}
                        />
                        <RejectIcon
                            onClick={() => {
                                if (taskStatusId === 2) {
                                    return;
                                }

                                handleEditTaskStatusDialogOpen(
                                    taskRecord,
                                    isPendingTab ? DIALOG_TYPE.reject : DIALOG_TYPE.edit
                                );
                            }}
                            sx={{
                                color: isPendingTab
                                    ? ICON_COLORS.pendingTab.reject
                                    : confirmedIconColor(taskStatusId, true),
                                ...ICON_SIZE,
                            }}
                        />
                    </CheckboxContainer>
                </TaskRowContainer>
            );
        });
    };

    return (
        <>
            <TasksContainer>
                <TabsStyle value={selectedTab} onChange={handleTabChange}>
                    {tasksByTab.map((tabTask) => {
                        const { key, tabName } = tabTask;
                        return <Tab key={key} label={home[tabName]} />;
                    })}
                </TabsStyle>
                {tasksByTab.map((tabTask) => {
                    const { key, tasks } = tabTask;

                    return (
                        <TabPanel key={key} value={selectedTab} index={key}>
                            {renderTasks(key === 0, tasks.length === 0, tasks)}
                        </TabPanel>
                    );
                })}
            </TasksContainer>
            <EditTaskStatusDialog
                isOpen={isEditTaskStatusDialogOpen}
                onClose={handleDialogClose}
                onSubmit={handleRejectDialogSubmit}
                dialogType={dialogType}
                selectedRecord={selectedRecord}
                taskNotes={
                    selectedRecord?.dailyTaskRecordId ? taskIdNotes[selectedRecord.dailyTaskRecordId] : undefined
                }
            />
            <EarlyInShiftConfirmationDialog
                isOpen={isEarlyInShiftConfirmationDialogOpen}
                onClose={toggleEarlyInShiftConfirmationDialog}
                currentShift={currentShift}
                onAccept={() => {
                    toggleEarlyInShiftConfirmationDialog();
                    const { shiftStartTime } = getCurrentTimeAndShiftStartTime();
                    localStorage.setItem(
                        NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL,
                        JSON.stringify({
                            expire: addMinutes(shiftStartTime, EARLY_IN_SHIFT_MINUTES).getTime(),
                        })
                    );

                    if (handleCompleteTaskParams) {
                        completeTask(handleCompleteTaskParams.dailyTaskRecordId, handleCompleteTaskParams.params);
                        return;
                    }
                    if (handleEditTaskStatusDialogOpenParams) {
                        editTaskStatusDialogOpen(
                            handleEditTaskStatusDialogOpenParams.newDailyTaskRecord,
                            handleEditTaskStatusDialogOpenParams.newDialogType
                        );
                    }
                }}
            />
            <InstructionsViewDialog
                isOpen={viewInstructionsIdNotes !== null}
                taskNote={selectedInstructions ?? ''}
                onClose={handleDialogClose}
            />
            <AddTaskNotesDialog
                isOpen={showTaskIdAddNotesDialog !== null}
                taskNote={showTaskIdAddNotesDialog ? taskIdNotes[showTaskIdAddNotesDialog] : ''}
                onSubmit={onSubmitEditAddTaskNotes}
                onClose={handleDialogClose}
            />
            <NurseCallsDialog
                isOpen={isNurseCallsDialogOpen}
                onClose={() => {
                    toggleNurseCallsDialog();
                }}
                residentId={residentId}
                selectedDate={currentDate}
            />
        </>
    );
};

const mapStateToProps = ({ language, session, taskStatuses }: ReduxStore) => {
    const { dictionary } = language;
    const {
        timezone,
        sessionData: { branchId },
    } = session;
    const { taskStatusesList } = taskStatuses;

    return {
        dictionary,
        taskStatusesList,
        timezone,
        branchId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchShowAlert: (message: MessageProps) => dispatch(showAlert(message)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) => dispatch(readTaskStatuses(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentTasksContainer: any = connect(mapStateToProps, mapDispatchToProps)(ResidentTasksContainer);

export default ConnectedResidentTasksContainer;
