import { orderBy } from 'lodash';

import { BranchesByRegionResponse } from 'types/companyInfo';
import {
    OperationsChangeOfConditionStatsDataset,
    OperationsDocumentationRateStatsDataset,
    OperationsLevel,
    OperationsOverallStatsDataset,
    OperationsOverallStatsResponse,
} from 'types/operationsDashboard';

import { Section } from './constants';

export const calculatePercentage = (partialValue: number, totalValue: number, decimalPlaces = 2): number => {
    if (totalValue === 0) return 0;
    if (totalValue > 0) {
        const percentage = (partialValue / totalValue) * 100;
        return parseFloat(percentage.toFixed(decimalPlaces));
    }
    return 0;
};

export const calculatePercentageChange = (current: number, previous: number, decimalPlaces = 2): number => {
    if (previous === 0) return 0;
    if (previous > 0) {
        const percentage = ((current - previous) / previous) * 100;
        const roundedPercentage = parseFloat(percentage.toFixed(decimalPlaces));
        if (roundedPercentage === 0) return 0;
        return roundedPercentage;
    }
    return 0;
};

type SummarizedDocumentationRateDataItem = {
    startDate: string;
    tasksCompleteCount: number;
    totalTasksCount: number;
};

const sumDocumentationRateCountsByDate = (
    data: OperationsDocumentationRateStatsDataset[]
): SummarizedDocumentationRateDataItem[] => {
    const summedByDate = data.reduce((acc, { startDate, tasksCompleteCount, totalTasksCount }) => {
        if (!acc[startDate]) {
            acc[startDate] = {
                startDate,
                tasksCompleteCount,
                totalTasksCount,
            };
        } else {
            acc[startDate].tasksCompleteCount += tasksCompleteCount;
            acc[startDate].totalTasksCount += totalTasksCount;
        }
        return acc;
    }, {});

    return Object.values(summedByDate);
};

type SummarizedDataItem = {
    startDate: string;
    value: number;
};

const sumCountsByDate = (data: OperationsOverallStatsDataset[], propName: string): SummarizedDataItem[] => {
    const summedByDate = data.reduce(
        (acc, item) => {
            const { startDate } = item;
            const count = item[propName] || 0;

            if (!acc[startDate]) {
                acc[startDate] = {
                    startDate,
                    value: count,
                };
            } else {
                acc[startDate].value += count;
            }

            return acc;
        },
        {} as Record<string, SummarizedDataItem>
    );

    return Object.values(summedByDate);
};

// If region is not selected, show data for all regions. Otherwise, filter data by region
export const getFilteredData = (
    data: OperationsOverallStatsResponse,
    selectedRegion: string,
    selectedSection: Section
) => {
    const sectionData = data.sections.find((item) => item.section === selectedSection)?.datasets;

    if (!sectionData) return [];

    if (selectedRegion === 'all') {
        return orderBy(sectionData, 'startDate', 'desc');
    }

    const filteredData = sectionData.filter(({ regionId }) => regionId === Number(selectedRegion));

    return orderBy(filteredData, 'startDate', 'desc');
};

// Calculates the amount for the module header
export const calculateAmountForPeriod = (
    data,
    section: Section,
    periodIndex: number // 0 = current, 1 = previous, 2 = 2 periods ago
) => {
    switch (section) {
        case 'DOCUMENTATION_RATE': {
            const aggregatedCountsByDate = sumDocumentationRateCountsByDate(data);

            return `${calculatePercentage(
                aggregatedCountsByDate[periodIndex].tasksCompleteCount,
                aggregatedCountsByDate[periodIndex].totalTasksCount
            )}%`;
        }
        case 'FALL_DOCUMENTATION_INCOMPLETE': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'incompleteCount');

            return aggregatedCountsByDate[periodIndex].value;
        }
        case 'FALLS': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'fallCount');

            return aggregatedCountsByDate[periodIndex].value;
        }
        case 'HOSPITAL_TRANSFER': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'hospitalVisitCount');

            return aggregatedCountsByDate[periodIndex].value;
        }
        case 'RESIDENT_NOTES': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'residentNoteCount');

            return aggregatedCountsByDate[periodIndex].value;
        }
        case 'SHOWER': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'residentMissedShowerCount');

            return aggregatedCountsByDate[periodIndex].value;
        }
        case 'UNSCHEDULED_CARE': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'unscheduledCareCount');

            return aggregatedCountsByDate[periodIndex].value;
        }
        case 'CHANGE_OF_CONDITION': {
            const withAdlChangesTotal = sumCountsByDate(data, 'withAdlChanges')[periodIndex].value;

            const withoutAdlChangesTotal = sumCountsByDate(data, 'withoutAdlChanges')[periodIndex].value;
            return withAdlChangesTotal + withoutAdlChangesTotal;
        }
        case 'OVERDUE_ASSESSMENT': {
            const aggregatedCountsByDate = sumCountsByDate(data, 'overdueAssessmentCount');

            return aggregatedCountsByDate[periodIndex].value;
        }

        default:
            return 0;
    }
};

// Returns the name of the branch or region based on the id and current level
export const findNameById = (id: number, branchesByRegionData: BranchesByRegionResponse, level: OperationsLevel) => {
    // If we are at the company level, we need to find the region name
    if (level === 'company') {
        if (branchesByRegionData.regions.length === 0) return '';
        return branchesByRegionData.regions.find((item) => item.regionId === id)?.regionName;
    }

    // If we are at the region level, we need to find the branch name
    return branchesByRegionData.branches.find((item) => item.branchId === id)?.branchName;
};

export const accumulator = <T>(datasets: T[], propName: string): number =>
    datasets.reduce((acc, dataset) => acc + (dataset[propName] || 0), 0);

export const changeOfConditionAccumulator = (datasets: OperationsChangeOfConditionStatsDataset[]): number => {
    const totalWithAdlChanges = accumulator(datasets, 'withAdlChanges');
    const totalWithoutAdlChanges = accumulator(datasets, 'withoutAdlChanges');
    return totalWithAdlChanges + totalWithoutAdlChanges;
};
