import { api } from 'api';

import {
    OperationsBranchStatsParams,
    OperationsBranchStatsResponse,
    OperationsOverallStatsParams,
    OperationsOverallStatsResponse,
} from 'types/operationsDashboard';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { getToken } from 'lib/common';

export const useOperationsOverallStatsQuery = (jsonParams: OperationsOverallStatsParams) =>
    useErrorHandledQuery<OperationsOverallStatsResponse>(
        ['operations-overall-stats', jsonParams],
        async (): Promise<OperationsOverallStatsResponse> => {
            const { data } = await api.get('/analytics/operations/overall-stats', {
                headers: { authorization: getToken() },
                params: jsonParams,
            });
            return data.response;
        },
        { enabled: !!jsonParams, staleTime: 300000 }
    );

export const useOperationsBranchStatsQuery = (jsonParams?: OperationsBranchStatsParams) =>
    useErrorHandledQuery<OperationsBranchStatsResponse>(
        ['operations-community-stats', jsonParams],
        async (): Promise<OperationsBranchStatsResponse> => {
            const { data } = await api.get('/analytics/operations/branch-stats', {
                headers: { authorization: getToken() },
                params: jsonParams,
            });
            return data.response;
        },
        { enabled: !!jsonParams, staleTime: 300000 }
    );
