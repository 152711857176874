import {
    PhoneAndroid as AndroidIcon,
    Badge as BadgeIcon,
    Cake as CakeIcon,
    Email as EmailIcon,
    Google as GoogleIcon,
    PhoneIphone as IphoneIcon,
    Password as PasswordIcon,
    Person as UserIcon,
} from '@mui/icons-material';
import { Box, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { formatPhoneNumber } from 'react-phone-number-input';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import { UserResponse } from 'types/users';

import { CustomAvatar } from 'components/Custom';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import { AVATAR_TYPES } from 'constants/shared';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';
import { readUser } from 'redux/actions/users';

const TopContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    })
);

const UserPhotoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pr: pxToRem(16),
        width: pxToRem(72),
    })
);

const UserMainDataContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(72)})`,
    })
);

const RoleLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(4),
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            mr: pxToRem(8),
            color: theme.palette.app.orange.main,
            fontSize: pxToRem(18),
        },
    })
);

const BirthDateLabel = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(4),
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            mr: pxToRem(8),
            color: theme.palette.app.orange.main,
            fontSize: pxToRem(18),
        },
    })
);

const UserInfoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    })
);

const UserRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
    })
);

const IconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.app.green.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: pxToRem(32),
        height: pxToRem(32),
        borderRadius: '50%',
    })
);

const RowDescription = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% - ${pxToRem(40)})`,
    })
);

const DescriptionText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        overflowWrap: 'anywhere',
    })
);

type Props = {
    loading: boolean;
    user: UserResponse;
    dispatchReadUser: (userId: number) => void;
};

const Profile = (props: Props) => {
    const { loading, user, dispatchReadUser } = props;
    const { role, username, firstName, lastName, phoneNumber, picture, userId, branch } = user || {};
    const PhoneIcon = isIOS ? IphoneIcon : AndroidIcon;

    const userFullName = `${firstName} ${lastName}`;
    const phoneNumberFormatted = phoneNumber ? formatPhoneNumber(`+1${phoneNumber}`) : '';

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Get the user id from the stored data.
            const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
            const { userId: sessionUserId } = storedSession?.sessionData || {};

            // Fetch the information from the user.
            dispatchReadUser(sessionUserId);
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <PageStructure>
            <TopContainer>
                <UserPhotoContainer>
                    {!!user && (
                        <CustomAvatar
                            avatarType={role.toLowerCase() as AVATAR_TYPES}
                            firstName={firstName}
                            lastName={lastName}
                            fullName={userFullName}
                            photo={picture}
                            size={64}
                        />
                    )}
                </UserPhotoContainer>
                <UserMainDataContainer>
                    <Typography variant="h2">{userFullName}</Typography>
                    <RoleLabel variant="h4">
                        <BadgeIcon />
                        {branch}
                    </RoleLabel>
                </UserMainDataContainer>
            </TopContainer>
            <UserInfoContainer>
                <UserRow>
                    <IconContainer>
                        <UserIcon />
                    </IconContainer>
                    <RowDescription>
                        <DescriptionText variant="body2">{username}</DescriptionText>
                    </RowDescription>
                </UserRow>
                <UserRow>
                    <IconContainer>
                        <PasswordIcon />
                    </IconContainer>
                    <RowDescription>
                        <DescriptionText variant="body2">● ● ● ● ● ● ● ●</DescriptionText>
                    </RowDescription>
                </UserRow>
                <UserRow>
                    <IconContainer>
                        <PhoneIcon
                            onClick={() => {
                                window.location.href = `https://x9sga5864u6.typeform.com/to/TbzTHxJc#user_id=${userId}`;
                            }}
                        />
                    </IconContainer>
                    <RowDescription>
                        <DescriptionText variant="body2">{phoneNumberFormatted}</DescriptionText>
                    </RowDescription>
                </UserRow>
            </UserInfoContainer>
        </PageStructure>
    );
};

const mapStateToProps = ({ users }: ReduxStore) => {
    const { loading, user } = users;

    return {
        loading,
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUser: (userId: number) => dispatch(readUser(userId)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedProfile: any = AccessControl(connect(mapStateToProps, mapDispatchToProps)(Profile));

export default ConnectedProfile;
