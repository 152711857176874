import { ShiftNotesCategoriesState } from 'types/redux';

import { INFO_REPORT_CATEGORIES_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const shiftNotesCategories = (state: ShiftNotesCategoriesState = INFO_REPORT_CATEGORIES_DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.SHIFT_NOTES_CATEGORY_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.SHIFT_NOTES_CATEGORY_READ_LIST: {
            return {
                ...state,
                shiftNotesCategoriesList: action.payload,
            };
        }
        default:
            return state;
    }
};

export default shiftNotesCategories;
