import { camelCase, omit } from 'lodash';
import { isDesktop } from 'react-device-detect';

import { MenuMap } from 'types/common';
import { LanguageStructure } from 'types/language';
import { NestedRoutes, Route } from 'types/routes';

import { MENU_MAP, SUB_PAGES_MENU_MAP } from 'constants/app';
import { AppRoutes } from 'helpers/AppRoutes';

const recursiveRouterHelper = (routesList: Route[], subroute: NestedRoutes, parentPath: string) => {
    if (!subroute) {
        return null;
    }

    const curretPath = `${parentPath}${subroute.path}`;

    const { titleId, Component, Wrapper } = subroute;
    routesList.push({
        titleId,
        path: curretPath,
        Component,
        Wrapper,
    });

    if (subroute.children) {
        subroute.children.map((page) => recursiveRouterHelper(routesList, page, curretPath));
    }

    return routesList;
};

export const routesList = (): Route[] => recursiveRouterHelper([], AppRoutes, '') || [];

export const getPathTitle = (dictionary: LanguageStructure, path: string) => {
    if (path === '/') {
        return '';
    }

    const pathItems = path.split('/');
    const page: string = camelCase(pathItems[1]);
    const titleProperty = pathItems.length === 2 ? 'pageTitle' : 'pageDetailsTitle';
    const appRoutes = routesList();
    const pageId = appRoutes.find((route) => route.path === path)?.titleId;
    const dictionaryKey = pageId || page;

    return dictionary[dictionaryKey] && dictionary[dictionaryKey][titleProperty]
        ? dictionary[dictionaryKey][titleProperty]
        : '';
};

export const isSubpage = (path: string) => {
    const page: string = camelCase(path.split('/')[1]);
    const pageHasSublevels = path.split('/').length > 2;
    const desktopSubPagesMap: MenuMap = omit(SUB_PAGES_MENU_MAP, ['users', 'userCompany', 'userProfile']);
    const subPagesMap: MenuMap = isDesktop ? desktopSubPagesMap : SUB_PAGES_MENU_MAP;
    const isCurrentPageASubpage =
        Object.values(subPagesMap).findIndex((subPagePath) => subPagePath.includes(page)) > -1;
    const isCurrentPageAMainMenuElement = Object.values(MENU_MAP).indexOf(path) > -1;

    return pageHasSublevels && (!isCurrentPageAMainMenuElement || isCurrentPageASubpage);
};

export const getParentPage = (path: string) => {
    const parentPage: string = path.split('/')[1];
    const parentPageId: string = camelCase(parentPage);

    return parentPageId in MENU_MAP ? parentPage : '';
};
