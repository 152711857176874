import { queryClient } from '.';
import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';

import { DailyTasksUpdateParams, TaskToUpdate } from 'types/dailyTasks';
import {
    ResidentDailyTasksResponse,
    ResidentResponse,
    ResidentsListReadParams,
    ResidentsListResponse,
} from 'types/residents';

import { useAppDispatch } from 'constants/redux';
import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, getToken } from 'lib/common';
import { throwError } from 'redux/actions/messages';

// Queries the list of Residents based on branch ID.
export const useResidentsQuery = (jsonParams: ResidentsListReadParams | null) => {
    const params = jsonParams ? formatApiParams(jsonParams) : null;

    return useErrorHandledQuery<ResidentsListResponse[]>(
        ['residents', jsonParams],
        async (): Promise<ResidentsListResponse[]> => {
            const { data } = await api.get('/residents', {
                headers: { authorization: getToken() },
                params,
            });
            return data.response;
        },
        {
            enabled: !!jsonParams?.branchId,
            staleTime: 300000,
        }
    );
};

// Queries a Resident based on resident ID.
export const useResidentQuery = (residentId: number) =>
    useErrorHandledQuery<ResidentResponse>(
        ['resident', residentId],
        async (): Promise<ResidentResponse> => {
            const { data } = await api.get(`/residents/${residentId}`, {
                headers: { authorization: getToken() },
            });
            return data.response;
        },
        {
            enabled: !!residentId,
            staleTime: 300000,
        }
    );

const processResidentDailyTasks = (residentDailyTasks: ResidentDailyTasksResponse[]) => ({
    ...residentDailyTasks,
    pendingTaskRecords: residentDailyTasks.filter((task) => task.taskStatus === 'Undocumented'),
    confirmedTaskRecords: residentDailyTasks.filter((task) => task.taskStatus !== 'Undocumented'),
});

// Queries the list of Resident Daily Tasks based on resident ID.
export const useResidentDailyTasksQuery = (residentId: number | undefined) =>
    useErrorHandledQuery(
        ['residentDailyTasks', residentId],
        async () => {
            const { data } = await api.get(`/tasks/resident/${residentId}`, {
                headers: { authorization: getToken() },
            });

            const { pendingTasks, response: residentDailyTasks, totalRecords: totalTasks } = data;

            return {
                pendingTasks,
                totalTasks,
                ...processResidentDailyTasks(residentDailyTasks),
            };
        },
        {
            enabled: !!residentId,
            staleTime: 300000,
        }
    );

export const useResidentDailyTaskMutation = () => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { taskId: number; residentId: number; jsonParams: DailyTasksUpdateParams }) => {
            const { taskId, jsonParams } = params;
            const task: TaskToUpdate = {
                taskId,
                taskStatusId: jsonParams.taskStatusId,
                caregiverNotes: jsonParams.caregiverNotes || null,
            };
            await api.put(`/tasks`, [task], {
                headers: { authorization: getToken() },
            });
        },
        onSuccess: async (data, variables) => {
            const { residentId } = variables;
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: ['residentDailyTasks', residentId],
                    exact: false,
                    refetchType: 'active',
                }),
                queryClient.invalidateQueries({
                    queryKey: ['resident', residentId],
                    exact: false,
                    refetchType: 'active',
                }),
                queryClient.invalidateQueries({
                    queryKey: ['residents'],
                    exact: false,
                    refetchType: 'active',
                }),
                queryClient.invalidateQueries({
                    queryKey: ['dailyTasks'],
                    exact: false,
                    refetchType: 'active',
                }),
            ]);
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
