import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ZoneResponse, ZonesListReadParams } from 'types/zones';

import { CustomSelect } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { setCaregiverZoneId } from 'redux/actions/filters';
import { readZones } from 'redux/actions/zones';

type Props = {
    branchId: number;
    dictionary: LanguageStructure;
    fullWidth?: boolean;
    maxWidth?: number | string;
    width?: number | string;
    zonesList: ZoneResponse[];
    zoneId?: number;
    dispatchReadZones: (params: ZonesListReadParams) => void;
    dispatchSetCaregiverZoneId: (zoneId: number) => void;
    onChange?: (newZoneId: string) => void;
};

const ZoneSelectorFilter = (props: Props) => {
    const {
        branchId,
        dictionary: { home },
        fullWidth,
        maxWidth = pxToRem(264),
        width = '48%',
        zonesList,
        zoneId,
        dispatchReadZones,
        dispatchSetCaregiverZoneId,
        onChange,
    } = props;
    const [currentBranchId, setCurrentBranchId] = useState<number>();

    const handleFilterChange = (newValue: string) => {
        dispatchSetCaregiverZoneId(parseInt(newValue, 10));
        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        if (branchId && branchId !== currentBranchId) {
            // Update the value for the current Branch ID.
            setCurrentBranchId(branchId);

            // Define the params for fetching the Zones list.
            const zonesParams = {
                branchId,
            };

            // Fetch the Zones list based on the Branch ID.
            dispatchReadZones(zonesParams);
        }
    }, [branchId, currentBranchId]);

    const zoneOptions = zonesList.map((zone) => ({
        label: zone.zoneName,
        value: zone.zoneId.toString(),
    }));

    const currentZone = useMemo(
        () => zoneOptions.find(({ value }) => value === zoneId?.toString())?.value,
        [zoneId, zoneOptions]
    );

    useEffect(() => {
        if (zoneOptions?.length && (!zoneId || !currentZone)) {
            dispatchSetCaregiverZoneId(parseInt(zoneOptions[0].value, 10));
        }
    }, [zoneId, currentZone, zoneOptions]);

    return (
        <Box
            sx={{
                width,
                maxWidth,
            }}
        >
            <CustomSelect
                id="zone"
                label={home.zone}
                value={!zoneId || !currentZone ? '' : currentZone}
                options={zoneOptions}
                fullWidth={fullWidth}
                onChange={handleFilterChange}
            />
        </Box>
    );
};

const mapStateToProps = ({ zones, language, filters }) => {
    const { zonesList } = zones;
    const { dictionary } = language;
    const {
        caregiverApp: { zoneId },
    } = filters;

    return {
        zonesList,
        dictionary,
        zoneId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadZones: (params: ZonesListReadParams) => dispatch(readZones(params)),
    dispatchSetCaregiverZoneId: (zoneId: number) => dispatch(setCaregiverZoneId(zoneId)),
});

export const ZoneSelector = connect(mapStateToProps, mapDispatchToProps)(ZoneSelectorFilter);
