import { Box, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    amount: string | number;
    subtitle: React.ReactElement;
};

export const ModuleSubtitle = ({ amount, subtitle }: Props) => (
    <Box
        sx={{
            py: { xs: pxToRem(8), lg: pxToRem(20) },
            px: { xs: pxToRem(12), lg: pxToRem(20) },
            borderBottom: '1px solid #ECECF3',
        }}
    >
        <Typography
            variant="h2"
            sx={{
                fontSize: { xs: pxToRem(16), lg: pxToRem(24) },
                fontWeight: 'bold',
                lineHeight: { xs: 1.5, lg: 1 },
                color: '#2C2D3B',
                mb: pxToRem(4),
            }}
        >
            {amount}
        </Typography>
        <Typography
            sx={{
                fontSize: { lg: pxToRem(14) },
                color: '#6F6F79',
                lineHeight: 1.45,
            }}
        >
            {subtitle}
        </Typography>
    </Box>
);
