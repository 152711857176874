import { Close as CloseIcon } from '@mui/icons-material';
import { Alert, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';

import { MessageProps } from 'types/messages';

import { pxToRem } from 'components/theme/typography';
import { hideAlert } from 'redux/actions/messages';

const AlertStyle = styled(Alert)(({ theme }) =>
    theme.unstable_sx({
        position: 'fixed',
        top: 0,
        marginTop: 'env(safe-area-inset-top)',
        borderRadius: pxToRem(8),
        width: '100%',
        zIndex: 10000,
    })
) as typeof Alert;

type Props = {
    messageProps: MessageProps;
    dispatchHideAlert: () => void;
};

const Messages = (props: Props) => {
    const {
        messageProps: { open, message, alertSeverity: severity },
        dispatchHideAlert,
    } = props;

    const handleHideAlert = () => {
        dispatchHideAlert();
    };

    if (!open) {
        return null;
    }

    return (
        <AlertStyle
            variant="filled"
            severity={severity}
            action={
                <IconButton color="inherit" size="small" onClick={handleHideAlert}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            {message}
        </AlertStyle>
    );
};

const mapStateToProps = ({ messages }) => {
    const { ...messageProps } = messages;

    return {
        messageProps,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchHideAlert: () => dispatch(hideAlert()),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedMessages: any = connect(mapStateToProps, mapDispatchToProps)(Messages);

export default ConnectedMessages;
