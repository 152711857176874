import { Box, Typography } from '@mui/material';
import React from 'react';
import { TooltipProps } from 'recharts';

import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { pxToRem } from 'components/theme/typography';
import { Section } from 'pages/OperationsDashboard/constants';

interface CustomLineChartTooltipProps extends TooltipProps<ValueType, NameType> {
    section: Section;
}

const CustomLineChartTooltip = ({ active, payload, section }: CustomLineChartTooltipProps) => {
    if (active && payload && payload.length) {
        const { period } = payload[0].payload;
        const { value } = payload[0];

        const [, month, day] = period.split('-');
        const formattedPeriod = `${month}/${day}`;

        return (
            <Box
                sx={{
                    border: 'none',
                    backgroundColor: '#FFF',
                    borderRadius: pxToRem(8),
                    boxShadow: '0px 4px 4px rgba(139, 139, 139, 0.25)',
                    fontFamily: 'Open Sans',
                    color: '#6F6F79',
                    px: pxToRem(8),
                    py: pxToRem(4),
                }}
            >
                <Typography sx={{ fontSize: { xs: pxToRem(12) } }}>Period: {formattedPeriod}</Typography>
                <Typography sx={{ fontSize: { xs: pxToRem(12) }, fontWeight: 'bold' }}>
                    {value}
                    {section === 'DOCUMENTATION_RATE' ? '%' : ''}
                </Typography>
            </Box>
        );
    }

    return null;
};

export default CustomLineChartTooltip;
