import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { pxToRem } from 'components/theme/typography';
import { OVERALL_SECTIONS_MAP, Section } from 'pages/OperationsDashboard/constants';

type Props = {
    selectedSection: string;
    setSelectedSection: (section: string) => void;
};

export const CategorySelector = ({ selectedSection, setSelectedSection }) => {
    const [isCategoriesExpanded, setIsCategoriesExpanded] = useState(false);

    const selectableCategories = isCategoriesExpanded
        ? Object.entries(OVERALL_SECTIONS_MAP)
        : Object.entries(OVERALL_SECTIONS_MAP).slice(0, 3);

    const handleCategoriesToggle = () => setIsCategoriesExpanded(!isCategoriesExpanded);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: pxToRem(62),
                left: 0,
                right: 0,
                borderTop: '1px solid #F4F4F5',
                boxShadow: '0px -2px 4px 0px rgba(44, 45, 59, 0.04)',
                backgroundColor: '#fff',
                zIndex: 12,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: pxToRem(4),
                        flexWrap: 'wrap',
                        p: pxToRem(8),
                    }}
                >
                    {selectableCategories.map(([key, value]) => (
                        <Button
                            key={key}
                            variant={selectedSection === key ? 'contained' : 'outlined'}
                            onClick={() => setSelectedSection(key as Section)}
                        >
                            {value.title}
                        </Button>
                    ))}
                </Box>
                <Box
                    sx={{
                        padding: pxToRem(8),
                    }}
                >
                    {isCategoriesExpanded ? (
                        <IoIosArrowDown onClick={handleCategoriesToggle} />
                    ) : (
                        <IoIosArrowUp onClick={handleCategoriesToggle} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
