import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    note: string;
    sx?: SxProps<Theme>;
};

export const Note = ({ sx = [], note }: Props) => (
    <Box
        sx={[
            {
                background: 'linear-gradient(90deg, #F4F4F5 0%, rgba(244, 244, 245, 0.00) 100%)',
            },
            ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        px={pxToRem(10)}
        py={pxToRem(8)}
        borderLeft={(theme) => `2px solid ${theme.palette.grey[300]}`}
    >
        <Typography
            fontSize={{
                xs: pxToRem(10),
                md: pxToRem(11),
            }}
            lineHeight={1.3}
        >
            {note}
        </Typography>
    </Box>
);
