import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_TYPES } from 'constants/shared';
import { needsAttentionSummaryIconMap } from 'pages/ResidentDashboard/constants';

import { AssessmentDetailsCard } from './AssessmentDetailsCard';
import { ResidentInfoBadge } from './ResidentInfoBadge';

type Props = {
    name: { firstName: string; lastName: string };
    photo: string;
    roomNumber: string;
    lastEvaluationDate: string;
    isOnHospice: boolean;
    codeStatus: string;
    careType: string;
    assistLevel: string;
    needsAttentionSummary: NeedsAttentionItem[];
};

export const ResidentDashboardHeader = ({
    name: { firstName, lastName },
    photo,
    roomNumber,
    lastEvaluationDate,
    isOnHospice,
    codeStatus,
    careType,
    assistLevel,
    needsAttentionSummary,
}: Props) => {
    const getFormattedDate = (date: string) => {
        const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
        const formattedDate = format(parsedDate, 'MM/dd/yy');
        return formattedDate;
    };

    const residentInfoBadgesData = [
        ...(codeStatus ? [{ label: codeStatus }] : []),
        ...(assistLevel ? [{ label: assistLevel }] : []),
        ...(careType ? [{ label: careType }] : []),
        ...(isOnHospice ? [{ label: 'Hospice' }] : []),
        ...(lastEvaluationDate
            ? [
                  {
                      label: 'Last Evaluation',
                      date: getFormattedDate(lastEvaluationDate),
                  },
              ]
            : []),
    ];

    return (
        <Card variant="outlined">
            <Box display={{ md: 'flex' }} justifyContent="space-between" alignItems="center" p={3}>
                <Box display="flex" alignItems="center" gap={pxToRem(12)} mb={{ xs: 2, md: 0 }}>
                    <CustomAvatar
                        avatarType={AVATAR_TYPES.resident}
                        firstName={firstName}
                        lastName={lastName}
                        photo={photo || ''}
                        size={72}
                    />
                    <Box>
                        <Typography component="h2" textAlign="left">
                            <Typography component="span" variant="h4">
                                {firstName} {lastName}
                            </Typography>{' '}
                            <Typography component="span" variant="body2" color="#6F6F79">
                                (Room&nbsp;{roomNumber})
                            </Typography>
                        </Typography>
                        {/* ! COMMENTED: missing data */}
                        {/* <Typography
                            fontSize={{ xs: pxToRem(12), md: pxToRem(14) }}
                            fontWeight={700}
                            color="#EC3232"
                            bgcolor="#FEF5F5"
                            px={pxToRem(8)}
                            py={pxToRem(4)}
                            mt={pxToRem(4)}
                            display="inline-block"
                            borderRadius={pxToRem(4)}
                        >
                            {}
                        </Typography> */}
                    </Box>
                </Box>

                <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap" justifyContent={{ md: 'flex-end' }}>
                    {residentInfoBadgesData.map(({ label, date }) => (
                        <ResidentInfoBadge key={label} label={label} date={date} />
                    ))}
                </Stack>
            </Box>
            {needsAttentionSummary.length > 0 && (
                <>
                    <Divider />
                    <Box p={3}>
                        <Typography fontSize={{ xs: pxToRem(14), md: pxToRem(16) }} fontWeight={700} mb={pxToRem(16)}>
                            Summary in last 30 days
                        </Typography>
                        <Box
                            display="grid"
                            gridTemplateColumns={{
                                md: 'repeat(2, 1fr)',
                                lg: 'repeat(4, 1fr)',
                            }}
                            gap={2}
                        >
                            {needsAttentionSummary.map(({ id, name, value }) => (
                                <AssessmentDetailsCard
                                    key={id}
                                    label={name}
                                    amount={value}
                                    icon={needsAttentionSummaryIconMap[id]}
                                />
                            ))}
                        </Box>
                    </Box>
                </>
            )}
        </Card>
    );
};
