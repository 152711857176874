import { Action, DailyTasksState } from 'types/redux';

import { DAILY_TASKS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const dailyTasks = (state: DailyTasksState = DAILY_TASKS_DEFAULT_STATE, action: Action) => {
    switch (action.type) {
        case actionTypes.DAILY_TASKS_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.DAILY_TASKS_READ_LIST:
        case actionTypes.DAILY_TASKS_CREATE:
        case actionTypes.DAILY_TASKS_UPDATE: {
            return {
                ...state,
                dailyTasksList: action.payload,
            };
        }
        case actionTypes.DAILY_TASKS_UPDATE_SHIFT_PERIOD: {
            return {
                ...state,
                shiftPeriod: action.payload,
            };
        }
        default:
            return state;
    }
};

export default dailyTasks;
