import React, { useEffect, useState } from 'react';

import { useResidentQuery } from 'api/queries/residents';
import { CustomMobileDialog } from 'components/Custom/CustomMobileDialog';
import Loading from 'components/Shared/Loading';

import NurseCallsCheckbox from './NurseCallsCheckbox';
import NurseCallsCheckboxPrompt from './NurseCallsCheckboxPrompt';

type Props = {
    isOpen: boolean;
    residentId: number;
    selectedDate: string;
    onClose: () => void;
};

const NurseCallsDialog = (props: Props) => {
    const { isOpen, onClose, residentId, selectedDate } = props;

    const [showPrompt, setShowPrompt] = useState(true);

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(residentId);

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const residentName = `${residentData?.firstName} ${residentData?.lastName}`;

    const promptTitle = `Great job completing all ${residentName}'s tasks!`;
    const checkboxTitle = `Select ${residentName}'s call light requests this shift`;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={showPrompt ? promptTitle : checkboxTitle}
            onClose={onClose}
            width="100%"
            content={
                showPrompt ? (
                    <NurseCallsCheckboxPrompt residentName={residentName} onAccept={() => setShowPrompt(false)} />
                ) : (
                    <NurseCallsCheckbox residentId={residentId} onClose={onClose} selectedDate={selectedDate} />
                )
            }
        />
    );
};

export default NurseCallsDialog;
