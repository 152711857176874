import { Box, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';
import { IoCloseOutline as CloseIcon } from 'react-icons/io5';

import { pxToRem } from 'components/theme/typography';
import { DIALOG_OVERRIDES } from 'constants/theme';

type Props = {
    open: boolean;
    onClose: () => void;
    title?: string | ReactNode;
    subtitle?: ReactNode;
    content?: ReactNode;
    actions?: ReactNode;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    fullWidth?: boolean;
    closeable?: boolean;
    showDividers?: boolean;
    disableBackdropClick?: boolean;
};

const CustomSimpleDialog = ({
    open,
    onClose,
    title,
    subtitle,
    content,
    actions,
    maxWidth = 'md',
    fullWidth,
    closeable,
    showDividers,
    disableBackdropClick,
}: Props) => {
    if (!open) {
        return null;
    }

    const handleClose = (event: React.SyntheticEvent, reason: string) => {
        if (disableBackdropClick && reason === 'backdropClick') {
            return;
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            sx={{
                ...DIALOG_OVERRIDES,
                '& .MuiDialog-paper': {
                    p: {
                        xs: pxToRem(24),
                    },
                    minWidth: {
                        xs: pxToRem(320),
                        lg: pxToRem(400),
                    },
                },
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gap: {
                        xs: pxToRem(24),
                    },
                }}
            >
                {/* TITLE SECTION */}
                <Box>
                    {(title || closeable) && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: title ? 'space-between' : 'flex-end',
                                mb: closeable ? 0 : 1,
                            }}
                        >
                            {title && <DialogTitle>{title}</DialogTitle>}
                            {closeable && (
                                <IconButton
                                    onClick={onClose}
                                    sx={{
                                        border: '1px solid #DEDEE0',
                                    }}
                                    size="small"
                                >
                                    <CloseIcon size={pxToRem(24)} color="#6F6F79" />
                                </IconButton>
                            )}
                        </Box>
                    )}
                    {subtitle && subtitle}
                </Box>
                {/* CONTENT SECTION */}
                {content && <DialogContent dividers={showDividers}>{content}</DialogContent>}
                {/* ACTIONS SECTION */}
                {actions && <Box>{actions}</Box>}
            </Box>
        </Dialog>
    );
};

export default CustomSimpleDialog;
