import { UsersState } from 'types/redux';

import { USERS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const users = (state: UsersState = USERS_DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.USER_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.USER_READ_LIST: {
            return {
                ...state,
                usersList: action.payload,
            };
        }
        case actionTypes.USER_READ:
        case actionTypes.USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case actionTypes.USER_UPDATE_FAIL: {
            return {
                ...state,
                actionSuccess: false,
            };
        }
        case actionTypes.USER_CREATE: {
            return {
                ...state,
                actionSuccess: action.payload,
            };
        }
        case actionTypes.USER_DELETE: {
            const usersList = state.usersList ? state.usersList.filter((user) => user.userId === action.payload) : [];

            return {
                ...state,
                usersList,
            };
        }
        case actionTypes.USER_READ_ROLES_LIST: {
            return {
                ...state,
                rolesList: action.payload,
            };
        }
        case actionTypes.USER_READ_ROLE: {
            return {
                ...state,
                role: action.payload,
            };
        }
        case actionTypes.USER_BY_EMAIL: {
            return {
                ...state,
                userId: action.payload,
            };
        }
        case actionTypes.USER_EMAIL_CHECK: {
            return {
                ...state,
                emailExists: action.payload,
            };
        }
        case actionTypes.USER_CLEAR_DETAILS: {
            return {
                ...state,
                user: undefined,
                userId: undefined,
            };
        }
        case actionTypes.USER_CLEAR_ACTION_STATUS: {
            return {
                ...state,
                actionSuccess: undefined,
            };
        }
        case actionTypes.SESSION_SIGN_OUT:
            return {
                ...USERS_DEFAULT_STATE,
            };
        default:
            return state;
    }
};

export default users;
