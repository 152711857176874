import { useMutation } from '@tanstack/react-query';
import { api } from 'api';

import { getToken } from 'lib/common';

export const useUserDeviceMetadataMutation = () =>
    useMutation({
        mutationFn: async ({ token }: { token: string }) => {
            await api.post(
                '/users/device-metadata',
                { pushNotificationToken: token },
                {
                    headers: {
                        authorization: getToken(),
                    },
                }
            );
        },
    });
