import { Box, Button, TextField } from '@mui/material';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CompanyCarePlanResponse, CompanyCarePlansListReadParams } from 'types/companyCarePlans';
import { AddTask, AddedTaskMode, DailyTasksShiftDetail } from 'types/dailyTasks';
import { DropdownStructure, DropdownValue } from 'types/inputs';
import { LanguageStructure } from 'types/language';
import { ResidentsListReadParams } from 'types/residents';
import { StoredSession } from 'types/session';

import { useResidentsQuery } from 'api/queries/residents';
import { CustomAutocomplete, CustomDialog } from 'components/Custom';
import Loading from 'components/Shared/Loading';
import { pxToRem } from 'components/theme/typography';
import { ADD_TASK_INITIAL_VALUES } from 'constants/home';
import { PROFILE } from 'constants/localStorage';
import { readCompanyCarePlans } from 'redux/actions/companyCarePlans';

// keep in sync with src/pages/Home/taskListComponents/resident/UnscheduledTaskDialog.tsx
const POSSIBLE_FRAUD_USER_IDS = [285];

type Props = {
    companyCarePlansList: CompanyCarePlanResponse[];
    dailyTaskRecord: DailyTasksShiftDetail;
    dialogType: AddedTaskMode;
    dictionary: LanguageStructure;
    isOpen: boolean;
    defaultResidentId?: number;
    userId: number;
    dispatchReadCompanyCarePlans: (params: CompanyCarePlansListReadParams) => void;
    onSubmit: (formData: AddTask) => void;
    onClose: () => void;
};

const AddedTaskDialog = (props: Props) => {
    const {
        companyCarePlansList,
        dailyTaskRecord = {} as DailyTasksShiftDetail,
        dialogType,
        dictionary: { home, shared },
        isOpen,
        defaultResidentId,
        userId,
        dispatchReadCompanyCarePlans,
        onSubmit,
        onClose,
    } = props;
    const [form, setForm] = useState<AddTask>(ADD_TASK_INITIAL_VALUES);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [taskOptions, setTaskOptions] = useState<DropdownStructure[]>([]);
    const [residentOptions, setResidentOptions] = useState<DropdownStructure[]>([]);
    const [queryParams, setQueryParams] = useState<ResidentsListReadParams | null>(null);

    const {
        data: residentsList,
        isLoading: residentsListIsLoading,
        isError: residentsListIsError,
    } = useResidentsQuery(queryParams);

    const { companyCarePlanId: storedCompanyCarePlanId, taskAddedForResidentId: storedResidentId } = dailyTaskRecord;

    // Get the user's Company and Branch IDs.
    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const { companyId = 0, branchId = 0 } = storedSession?.sessionData || {};

    useEffect(() => {
        if (defaultResidentId) {
            setForm({
                ...form,
                ['residentId']: defaultResidentId,
            });
        }
    }, [defaultResidentId]);

    const handleDropdownChange = (fieldName: string) => (optionValue: DropdownValue) => {
        setForm({
            ...form,
            [fieldName]: optionValue,
        });
    };

    const handleSubmitClick = () => {
        onSubmit(form);
        onClose();
    };

    useEffect(() => {
        if (!companyCarePlansList?.length) {
            // Set the params to send to the API.
            const params = {
                companyId,
            };

            // Trigger the action for fetching the Company Care Plans corresponding to the User's Company ID.
            dispatchReadCompanyCarePlans(params);
        }

        if (!residentsList?.length) {
            // Set the params to send to the API.
            const params = {
                branchId,
            };

            setQueryParams(params);
        }
    }, []);

    useEffect(() => {
        if (companyCarePlansList.length) {
            // Define the Tasks options to show.
            const newOptions: DropdownStructure[] = companyCarePlansList
                .filter((companyCarePlan) => companyCarePlan.taskPoints !== null)
                .map((companyCarePlan) => ({
                    label: companyCarePlan.taskName,
                    value: companyCarePlan.companyCarePlanId,
                }));
            setTaskOptions(newOptions);

            // Initialize the selected Task with the first one from the list.
            setForm({
                ...form,
                companyCarePlanId: newOptions[0].value,
            });
        }

        if (residentsList?.length) {
            // Define the Tasks options to show.
            const newOptions: DropdownStructure[] = residentsList.map((resident) => ({
                label: `${resident.firstName} ${resident.lastName} - Room ${resident.roomNumber}`,
                value: resident.residentId,
            }));

            newOptions.sort((a, b) => {
                const firstNameA = a.label.split(' ')[0];
                const firstNameB = b.label.split(' ')[0];
                return firstNameA.localeCompare(firstNameB);
            });
            setResidentOptions(newOptions);

            // Initialize the selected Task with the first one from the list.
            setForm({
                ...form,
                residentId: newOptions[0].value,
            });
        }
    }, [JSON.stringify(companyCarePlansList), JSON.stringify(residentsList)]);

    useEffect(() => {
        if (dialogType === 'Edit' && storedCompanyCarePlanId && storedResidentId) {
            setForm({
                companyCarePlanId: storedCompanyCarePlanId,
                residentId: storedResidentId,
            });
        }
    }, [dialogType, storedCompanyCarePlanId, storedResidentId]);

    const isPotentialFraudUser = POSSIBLE_FRAUD_USER_IDS.includes(userId);
    const isAddButtonDisabled =
        !form.residentId || !form.companyCarePlanId || (isPotentialFraudUser && (!startTime || !endTime));

    if (residentsListIsLoading) {
        return <Loading />;
    }

    if (residentsListIsError || !residentsList) {
        return null;
    }

    return (
        <CustomDialog
            closeable
            open={isOpen}
            title={home.addTask}
            width="100%"
            onClose={onClose}
            content={
                <>
                    <CustomAutocomplete
                        label={shared.resident}
                        value={form.residentId}
                        options={residentOptions}
                        fullWidth
                        required
                        onChange={handleDropdownChange('residentId')}
                    />
                    <CustomAutocomplete
                        label={home.serviceProvided}
                        value={form.companyCarePlanId}
                        options={taskOptions}
                        fullWidth
                        required
                        onChange={handleDropdownChange('companyCarePlanId')}
                    />
                    {isPotentialFraudUser && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: pxToRem(8),
                                }}
                            >
                                <DesktopTimePicker
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        />
                                    )}
                                    value={startTime}
                                    label="Start Time"
                                    onChange={(newValue) => {
                                        setStartTime(newValue);
                                    }}
                                />
                                <DesktopTimePicker
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        />
                                    )}
                                    label="End Time"
                                    value={endTime}
                                    onChange={(newValue) => {
                                        setEndTime(newValue);
                                    }}
                                    minTime={startTime}
                                />
                            </Box>
                        </LocalizationProvider>
                    )}
                </>
            }
            actions={
                <>
                    <Button variant="outlined" color="secondary" onClick={onClose}>
                        {shared.cancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isAddButtonDisabled}
                        onClick={handleSubmitClick}
                    >
                        {shared.add}
                    </Button>
                </>
            }
        />
    );
};

const mapStateToProps = ({ companyCarePlans, language, session }) => {
    const { companyCarePlansList } = companyCarePlans;
    const { dictionary } = language;
    const {
        sessionData: { userId },
    } = session;

    return {
        companyCarePlansList,
        dictionary,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadCompanyCarePlans: (params: CompanyCarePlansListReadParams) => dispatch(readCompanyCarePlans(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedAddedTaskDialog: any = connect(mapStateToProps, mapDispatchToProps)(AddedTaskDialog);

export default ConnectedAddedTaskDialog;
