import { Button, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    content: string;
    dictionary: LanguageStructure;
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
};

const InfoReport = (props: Props) => {
    const {
        content,
        dictionary: { shared },
        isOpen,
        onClose,
        onDelete,
    } = props;

    return (
        <CustomDialog
            closeable
            open={isOpen}
            width="100%"
            maxHeight={`calc(100% - ${pxToRem(200)})`}
            onClose={onClose}
            content={<Typography variant="subtitle1">{content}</Typography>}
            actions={
                <>
                    <Button variant="outlined" color="secondary" onClick={onClose}>
                        {shared.no}
                    </Button>
                    <Button variant="contained" color="primary" onClick={onDelete}>
                        {shared.yes}
                    </Button>
                </>
            }
        />
    );
};

const mapStateToProps = ({ language }: ReduxStore) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedInfoReport: any = connect(mapStateToProps)(InfoReport);

export default ConnectedInfoReport;
