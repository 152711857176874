import { format, parse } from 'date-fns';
import { find, orderBy } from 'lodash';
import Pluralize from 'pluralize';
import React, { useMemo } from 'react';

import {
    GroupedByResident,
    OperationsBranchStatsDataset,
    OperationsBranchStatsResponse,
    ResidentDataset,
    ResidentWithDetails,
    SectionDetail,
} from 'types/operationsDashboard';

import BranchDocumentationRateStatsCards from '../components/Tables/BranchDocumentationRateStatsCards';
import BranchStatsTable from '../components/Tables/BranchStatsTable';
import BranchStatsTableWithCollapsibleRows from '../components/Tables/BranchStatsTableWithCollapsibleRows';
import {
    DATATYPES_WITH_COLLAPSIBLE_ROWS,
    DETAILS_COLUMNS_PROPS,
    DYNAMIC_COLUMNS,
    DynamicColumnSection,
    EXTRA_COLLAPSIBLE_ROW_COLUMNS,
    TOTAL_ROW_LABEL,
} from '../components/Tables/constants';

import { BRANCH_SECTIONS_MAP, BranchSection, Section, StandardDataset } from '../constants';
import { calculatePercentage } from '../helpers';

const getDatasets = (data: OperationsBranchStatsDataset[], branchId: number, startDate?: string) => {
    let selectedDate = startDate;

    const branchDatasets = data.filter((dataset) => dataset.branchId === branchId);

    if (!startDate) {
        const dates = Array.from(
            new Set<Date>(branchDatasets.map((dataset) => parse(dataset.startDate, 'yyyy-MM-dd', new Date())))
        );

        dates.sort((a, b) => a.getTime() - b.getTime());

        selectedDate = format(dates[dates.length - 2], 'yyyy-MM-dd');
    }

    return branchDatasets.find((dataset) => dataset.startDate === selectedDate)!;
};

const groupByResident = (datasets: ResidentDataset[], currentDataType: Section): ResidentWithDetails[] => {
    const grouped: GroupedByResident = {};

    datasets.forEach((dataset) => {
        const { id } = dataset.resident;

        if (!grouped[id]) {
            grouped[id] = {
                resident: dataset.resident,
                details: [],
            };
        }

        const detailKeys = DETAILS_COLUMNS_PROPS[currentDataType];

        const detailObject = detailKeys.reduce((acc, key) => {
            acc[key] = dataset[key];
            return acc;
        }, {} as SectionDetail);

        grouped[id].details.push(detailObject);
    });

    return Object.values(grouped);
};

const processedTableBranchData = (
    data: OperationsBranchStatsResponse | undefined,
    dataType: Section,
    branchId: string,
    startDate: string | undefined
) => {
    if (!data) {
        return null;
    }

    const sectionData = find(data.sections, (o) => o.section === dataType)!;

    const datasets = getDatasets(sectionData.datasets, Number(branchId), startDate);

    if (dataType === 'DOCUMENTATION_RATE') {
        const { assistedLivingShifts, memoryCareShifts } = datasets;
        return {
            assistedLivingShifts: {
                completionPercentage: calculatePercentage(
                    assistedLivingShifts.tasksCompleteCount,
                    assistedLivingShifts.totalTasksCount
                ),
                shifts: assistedLivingShifts.shifts,
            },
            memoryCareShifts: {
                completionPercentage: calculatePercentage(
                    memoryCareShifts.tasksCompleteCount,
                    memoryCareShifts.totalTasksCount
                ),
                shifts: memoryCareShifts.shifts,
            },
            rows: [],
            simpleRows: [],
            dynamicColumns: [],
        };
    }

    const hasCollapsibleRows = DATATYPES_WITH_COLLAPSIBLE_ROWS.includes(dataType);

    const datasetsGroupedByResident = hasCollapsibleRows ? groupByResident(datasets.datasets, dataType) : [];

    const simpleDatasets = hasCollapsibleRows ? [] : datasets.datasets;

    const totalLabel = TOTAL_ROW_LABEL[dataType];

    const getTotalRowLabel = (currentDataset: ResidentWithDetails, currentDataType: Section) => {
        if (currentDataType === 'UNSCHEDULED_CARE') {
            const label = currentDataset.details.length > 1 ? `${totalLabel}s` : totalLabel;

            return `${currentDataset.details.length} ${label}`;
        }

        return totalLabel
            ? `${currentDataset.details.length} ${Pluralize(totalLabel, currentDataset.details.length)}`
            : '';
    };

    const extraCollapsibleRowCols = EXTRA_COLLAPSIBLE_ROW_COLUMNS[dataType];

    const getExtraCollapsibleRowCols = (currentDataset: ResidentWithDetails) =>
        extraCollapsibleRowCols
            ? extraCollapsibleRowCols.reduce((acc, key) => {
                  acc[key] = currentDataset.details[0][key];

                  if (dataType === 'FALLS' && key === 'last30FallCount') {
                      acc[key] = currentDataset.details.some((item) => item[key] >= 2) ? 'Yes' : 'No';
                  }

                  return acc;
              }, {})
            : {};

    const createRowFromDataset = (currentDataset: ResidentWithDetails, currentDataType: Section) => ({
        id: `${currentDataset.resident.id}`,
        residentName: currentDataset.resident.name,
        residentRoomNumber: currentDataset.resident.roomNumber,
        details: currentDataset.details,
        ...(TOTAL_ROW_LABEL[currentDataType] ? { total: getTotalRowLabel(currentDataset, currentDataType) } : {}),
        ...(extraCollapsibleRowCols ? getExtraCollapsibleRowCols(currentDataset) : {}),
    });

    const sortedDatasetsGroupedByResident = datasetsGroupedByResident.sort(
        (a, b) => b.details.length - a.details.length
    );

    const rows =
        sortedDatasetsGroupedByResident.length > 0 && hasCollapsibleRows
            ? sortedDatasetsGroupedByResident.map((dataset) => createRowFromDataset(dataset, dataType))
            : [];

    const simpleRows =
        simpleDatasets.length > 0 && !hasCollapsibleRows
            ? simpleDatasets.map((dataset) => ({
                  ...dataset,
                  id: `${dataset.resident.id}`,
                  residentName: dataset.resident.name,
                  residentRoomNumber: dataset.resident.roomNumber,
              }))
            : [];

    return {
        rows,
        simpleRows,
        dynamicColumns: DYNAMIC_COLUMNS[dataType as DynamicColumnSection],
    };
};

const processedChartBranchData = (data: OperationsBranchStatsResponse | undefined, dataType: Section) => {
    if (!data) {
        return null;
    }

    const sectionDatasets = find(data.sections, (o) => o.section === dataType)!;

    const { chartValue, chartValue2 } = BRANCH_SECTIONS_MAP[dataType];

    let dataset: StandardDataset[];
    let dataset2: StandardDataset[] | undefined;

    if (dataType === 'DOCUMENTATION_RATE') {
        dataset = sectionDatasets.datasets.map((item) => ({
            startDate: item.startDate,
            value: calculatePercentage(item[chartValue], item[chartValue2]),
        }));
    } else {
        dataset = sectionDatasets.datasets.map((item) => ({
            startDate: item.startDate,
            value: item[chartValue],
        }));

        dataset2 = chartValue2
            ? sectionDatasets.datasets.map((item) => ({
                  startDate: item.startDate,
                  value: item[chartValue2],
              }))
            : [];
    }

    const orderedDatasets = orderBy(dataset, 'startDate', 'asc');

    const orderedDatasets2 = orderBy(dataset2, 'startDate', 'asc');

    return {
        data: orderedDatasets,
        data2: orderedDatasets2.length > 0 ? orderedDatasets2 : undefined,
        section: dataType,
    };
};

const getStartDateOptions = (data: OperationsBranchStatsResponse | undefined, dataType: Section) => {
    if (!data) {
        return null;
    }

    const sectionData = find(data.sections, (o) => o.section === dataType)!;

    return sectionData.datasets
        .map((dataset) => dataset.startDate)
        .reverse()
        .slice(0, -1);
};

const getOverallStat = (data: OperationsBranchStatsResponse | undefined, dataType: Section) => {
    if (!data) {
        return null;
    }

    const branchData = find(data.sections, (o) => o.section === dataType)!.datasets;

    const chartValueAmount = branchData[branchData.length - 2][BRANCH_SECTIONS_MAP[dataType].chartValue] || 0;

    const chartValue2Amount = branchData[branchData.length - 2][BRANCH_SECTIONS_MAP[dataType].chartValue2] || 0;

    if (dataType === 'CHANGE_OF_CONDITION') {
        return chartValueAmount + chartValue2Amount;
    }

    if (dataType === 'DOCUMENTATION_RATE') {
        return `${calculatePercentage(chartValueAmount, chartValue2Amount)}%`;
    }

    return chartValueAmount;
};

export const useBranchDataItems = (
    data: OperationsBranchStatsResponse | undefined,
    dataType: Section,
    branchId: string,
    startDate: string | undefined
) => {
    //  temporary hack to handle Branch level not having the same sections as the other levels
    const isValidSection = Object.keys(BRANCH_SECTIONS_MAP).includes(dataType);

    const ChartComponent = BRANCH_SECTIONS_MAP[dataType as BranchSection]?.ChartComponent;

    const chartProps = useMemo(
        () => (isValidSection ? processedChartBranchData(data, dataType) : null),
        [JSON.stringify(data), dataType]
    );

    const tableProps = useMemo(
        () => (isValidSection ? processedTableBranchData(data, dataType, branchId, startDate) : null),
        [JSON.stringify(data), dataType, branchId, startDate]
    );

    const dateOptions = useMemo(
        () => (isValidSection ? getStartDateOptions(data, dataType) : null),
        [JSON.stringify(data), dataType]
    );

    const branchStat = useMemo(
        () => (isValidSection ? getOverallStat(data, dataType) : null),
        [JSON.stringify(data), dataType]
    );

    if (!data || chartProps === null || tableProps === null) {
        return {};
    }

    const hasCollapsibleRows = DATATYPES_WITH_COLLAPSIBLE_ROWS.includes(dataType);

    // not super happy with this different approach from overall. Will revisit
    let renderTableComponent: () => JSX.Element;

    if (dataType === 'DOCUMENTATION_RATE') {
        renderTableComponent = () => (
            <BranchDocumentationRateStatsCards
                assistedLivingShifts={tableProps.assistedLivingShifts!}
                memoryCareShifts={tableProps.memoryCareShifts!}
            />
        );
    } else {
        renderTableComponent = hasCollapsibleRows
            ? () => (
                  <BranchStatsTableWithCollapsibleRows
                      rows={tableProps.rows}
                      dynamicColumns={tableProps.dynamicColumns}
                      dataType={dataType}
                  />
              )
            : () => <BranchStatsTable rows={tableProps.simpleRows} dynamicColumns={tableProps.dynamicColumns} />;
    }

    return {
        BranchChartComponent: ChartComponent,
        branchChartProps: chartProps,
        branchDateOptions: dateOptions,
        renderTableComponent,
        branchStat,
    };
};
