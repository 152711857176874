import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';

type Props = {
    selectedTaskIds: number[];
    onToggleRejectDialog: (taskId: number) => void;
    confirmSelectedTasks: (taskStatusId: number) => void;
};

export const BulkCompleteTray = (props: Props) => {
    const { selectedTaskIds, onToggleRejectDialog, confirmSelectedTasks } = props;

    const { singularTasksSelected: singularTasksSelectedTxt, pluralTasksSelected: pluralTasksSelectedTxt } =
        useSelector((state: ReduxStore) => state.language.dictionary.home);

    const styles = {
        height: pxToRem(54),
        width: pxToRem(54),
    };

    return (
        <Box
            sx={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                backgroundColor: '#2C2D3B',
                color: 'primary.contrastText',
                paddingTop: pxToRem(15),
                paddingLeft: pxToRem(12),
                paddingRight: pxToRem(12),
                paddingBottom: pxToRem(15),
            }}
        >
            <Typography
                sx={{
                    textAlign: 'left',
                    flexGrow: 1,
                    margin: 'auto',
                }}
            >
                {`${selectedTaskIds.length} ${
                    selectedTaskIds.length === 1 ? singularTasksSelectedTxt : pluralTasksSelectedTxt
                }`}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <CheckCircleOutlined sx={styles} onClick={() => confirmSelectedTasks(2)} />
                <CancelOutlined sx={styles} onClick={() => onToggleRejectDialog(-1)} />
            </Box>
        </Box>
    );
};
