import { Box } from '@mui/material';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { IoLanguage } from 'react-icons/io5';

import { MenuMap, MenuOption } from 'types/common';

import { OperationsIcon } from 'components/Svg';
import { CaregiversIcon } from 'components/Svg/CaregiversIcon';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { ModernHomeIcon } from 'components/Svg/ModernHomeIcon';
import { ResidentsIcon } from 'components/Svg/ResidentsIcon';
import { TaskListIcon } from 'components/Svg/TaskListIcon';
import { pxToRem } from 'components/theme/typography';

export const APP_NAME = 'Allie Health';
export const APP_VERSION = '1.0.0';
export const GOOGLE_ID = '1051442496999-bseknog5v80253i65is83qtghpb4jpoq.apps.googleusercontent.com';
export const EMAIL_JS_SERVICE_ID = 'service_5f363uk';
export const EMAIL_JS_INVITATION_TEMPLATE_ID = 'template_c915gtt';
export const EMAIL_JS_PASSWORD_TEMPLATE_ID = 'template_bm7akeb';
export const EMAIL_JS_PUBLIC_KEY = '5_V4HhFicSA0w1Nnu';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const US_DATE_SHORT_FORMAT = 'MM/DD';
export const GREETING_DATE_FORMAT = 'ddd MMMM D, YYYY';

export const PASSWORD_GENERATOR_VALID_CHARS =
    '0123456789abcdefghijklmnñopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';

// App Paths
export const BASE_PATH = '/';
export const LOGIN_PATH = 'login';
export const LOGIN_VERIFY_PATH = '/verify';
export const LOGIN_USERNAME_PATH = '/username';
export const SIGNUP_PATH = 'signup';
export const SIGNUP_VERIFY_PATH = '/verify';
export const FORGOT_PASSWORD_PATH = 'forgot-password';
export const CODE_PATH = '/:encodedLink';
export const PRIVACY_POLICY = 'privacy-policy';
export const TERMS_OF_SERVICE = 'terms-of-service';
export const HOME_PATH = 'home';
export const MANAGER_TASK_LIST = 'manager-task-list';
export const RESIDENTS_PATH = 'residents';
export const RESIDENT_DETAILS_PATH = '/:residentId';
export const RESIDENT_DASHBOARD_PATH = '/resident-details/:residentId';
export const MY_REWARDS_PATH = 'my-rewards';
export const MY_REWARDS_HISTORY_PATH = '/history';
export const OPERATIONS_SNAPSHOTS_PATH = 'operations-snapshots';
export const PERFORMANCE_SNAPSHOTS_PATH = '/performance-snapshots';
export const USERS_PROFILE_PATH = 'user-profile';

// Photo Paths
export const BASE_PHOTO_PATH = 'https://allie-health-user-images.s3.amazonaws.com/photos';
export const COMPANY_MANAGERS_PHOTO_PATH = `${BASE_PHOTO_PATH}/company_managers`;
export const EXECUTIVE_DIRECTORS_PHOTO_PATH = `${BASE_PHOTO_PATH}/executive_directors`;
export const MANAGERS_PHOTO_PATH = `${BASE_PHOTO_PATH}/managers`;
export const CAREGIVERS_PHOTO_PATH = `${BASE_PHOTO_PATH}/caregivers`;
export const FAMILY_MEMBERS_PHOTO_PATH = `${BASE_PHOTO_PATH}/family_members`;
export const RESIDENTS_PHOTO_PATH = `${BASE_PHOTO_PATH}/residents`;

// Components Heights
export const DESKTOP_HEADER_HEIGHT = 80;
export const MOBILE_HEADER_HEIGHT = 48;
export const MOBILE_BOTTOM_MENU_HEIGHT = 56;

export const SIDE_MENU_WIDTH = 280;

export const MENU_MAP: MenuMap = {
    home: `${BASE_PATH}${HOME_PATH}`,
    residents: `${BASE_PATH}${RESIDENTS_PATH}`,
    myRewards: `${BASE_PATH}${MY_REWARDS_PATH}`,
    managerTaskList: `${BASE_PATH}${MANAGER_TASK_LIST}`,
    operationsSnapshots: `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}`,
    performanceSnapshots: `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}${PERFORMANCE_SNAPSHOTS_PATH}`,
};

export const SUB_PAGES_MENU_MAP: MenuMap = {
    residentDetails: `${BASE_PATH}${RESIDENTS_PATH}${RESIDENT_DETAILS_PATH}`,
    managerResidentDetails: `${BASE_PATH}${OPERATIONS_SNAPSHOTS_PATH}${RESIDENT_DASHBOARD_PATH}`,
    userProfile: `${BASE_PATH}${USERS_PROFILE_PATH}`,
};

const MenuGoldCoin = () => <GoldCoin size={20} viewBox="0 0 20 20" />;
const MenuResidentsIcon = () => <ResidentsIcon size={24} viewBox="0 0 24 24" />;
const MenuModernHomeIcon = () => <ModernHomeIcon size={24} viewBox="0 0 24 24" />;
const MenuCaregiversIcon = () => <CaregiversIcon size={24} viewBox="0 0 24 24" />;

const TaskListMenuIcon = () => (
    <Box sx={{ width: pxToRem(24) }}>
        <TaskListIcon />
    </Box>
);

// TODO: figure out if we need MENU_MAP and SUB_PAGES_MENU_MAP
export const MENU_MAP_ALL_PAGES: MenuMap = {
    ...MENU_MAP,
    ...SUB_PAGES_MENU_MAP,
};

export const MOBILE_MENU_OPTIONS: MenuOption[] = [
    {
        id: 'userProfile',
        icon: FaUserCircle,
        visibleFor: [1, 2, 3, 4, 5],
    },
    {
        id: 'language',
        icon: IoLanguage,
        visibleFor: [1, 2, 3, 4, 5],
    },
];

export const MOBILE_BOTTOM_MENU_OPTIONS: MenuOption[] = [
    {
        id: 'home',
        icon: MenuModernHomeIcon,
        visibleFor: [5],
    },
    {
        id: 'operationsSnapshots',
        icon: OperationsIcon,
        visibleFor: [1, 2, 3, 4],
    },
    {
        id: 'performanceSnapshots',
        icon: MenuCaregiversIcon,
        visibleFor: [1, 2, 3, 4],
    },
    {
        id: 'managerTaskList',
        icon: TaskListMenuIcon,
        visibleFor: [1, 2, 3, 4],
    },
    {
        id: 'residents',
        icon: MenuResidentsIcon,
        visibleFor: [1, 2, 3, 4, 5],
    },
    {
        id: 'myRewards',
        icon: MenuGoldCoin,
        visibleFor: [5],
    },
];

export const DESKTOP_MENU_OPTIONS: MenuOption[] = [
    {
        id: 'operationsSnapshots',
        customIcon: OperationsIcon,
        visibleFor: [1, 2, 3, 4],
    },
    {
        id: 'performanceSnapshots',
        customIcon: MenuCaregiversIcon,
        visibleFor: [1, 2, 3, 4],
    },
    {
        id: 'separator1',
        isSeparator: true,
    },
    {
        id: 'home',
        icon: MenuModernHomeIcon,
        visibleFor: [5],
    },
    {
        id: 'managerTaskList',
        icon: TaskListMenuIcon,
        visibleFor: [1, 2, 3, 4],
    },
    {
        id: 'residents',
        icon: MenuResidentsIcon,
        visibleFor: [1, 2, 3, 4, 5],
    },
    {
        id: 'myRewards',
        icon: MenuGoldCoin,
        visibleFor: [5],
    },
    {
        id: 'separator2',
        isSeparator: true,
    },
    {
        id: 'userProfile',
        icon: FaUserCircle,
        visibleFor: [1, 2, 3, 4, 5],
    },
    {
        id: 'language',
        icon: IoLanguage,
        visibleFor: [1, 2, 3, 4, 5],
    },
];
