import { TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { isNil, isNull } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CustomDate } from 'types/common';
import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import { DATE_FORMAT } from 'constants/app';
import { validateDate } from 'lib/dashboard';

const DateField = styled(TextField)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        width: pxToRem(160),
        '& .MuiInputBase-root': {
            height: pxToRem(44),
        },
    })
);

type Props = {
    date: CustomDate;
    dateLabel: string;
    dictionary: LanguageStructure;
    disableFuture?: boolean;
    disableMaskedInput?: boolean;
    disablePast?: boolean;
    hideHelper?: boolean;
    inputFormat?: string;
    inputMask?: string;
    maxDate?: CustomDate;
    minDate?: CustomDate;
    marginLeft?: number | string;
    marginRight?: number | string;
    marginBottom?: number | string;
    fullWidth?: boolean;
    timezone: string;
    onBlur?: (dateHasError: boolean) => void;
    onChange: (newDate: string | null, dateHasError: boolean) => void;
};

const UnconnectedCustomDatePicker = (props: Props) => {
    const {
        dictionary: { shared, error: errorDictionary },
        disableFuture,
        disableMaskedInput,
        disablePast,
        date,
        dateLabel,
        hideHelper,
        inputFormat = 'MM/dd/yyyy',
        inputMask = '__/__/____',
        maxDate,
        minDate,
        marginLeft = pxToRem(8),
        marginRight = pxToRem(8),
        marginBottom = pxToRem(16),
        fullWidth,
        onBlur = () => null,
        onChange,
    } = props;
    const [selectedDate, setSelectedDate] = useState<CustomDate>(date);
    const [dateErrorMsg, setDateErrorMsg] = useState<string>('');
    const newEndDateLabel = dateLabel || shared.date;
    const datePickerStyle = {
        ml: marginLeft,
        mr: marginRight,
        mb: marginBottom,
        width: fullWidth ? '100%' : pxToRem(160),
    };

    const formatDate = (newDate: CustomDate): string | null => {
        const newValue =
            typeof newDate === 'object' && !isNil(newDate) && moment(newDate).isValid() && moment(newDate).year() > 1920
                ? moment(newDate).format(DATE_FORMAT)
                : newDate;
        const dateStringNull =
            typeof newValue === 'string' && newValue.length >= 10 ? `${newValue.slice(0, 10)}T12:00:00` : newValue;
        return dateStringNull as string;
    };

    const handleChange = (newDate: CustomDate) => {
        const newValue = formatDate(newDate);
        setSelectedDate(newValue);
        onChange(newValue, !!dateErrorMsg);
    };

    const handleOnBlur = () => {
        onBlur(!!dateErrorMsg);
    };

    const maxDateFormatted = maxDate ? moment(maxDate).toDate() : null;
    const minDateFormatted = minDate ? moment(minDate).toDate() : null;

    const handleError = (reason: DateValidationError, newDate: CustomDate) => {
        const newValue = formatDate(newDate);
        const errorMessage = validateDate(reason, dateLabel, errorDictionary, maxDateFormatted, minDateFormatted);

        setDateErrorMsg(errorMessage);
        onChange(newValue, !!errorMessage);
    };

    useEffect(() => {
        if (typeof date === 'string' && date.length === 10) {
            setSelectedDate(`${date}T12:00:00`);
        } else if (!isNull(date)) {
            setSelectedDate(date);
        }
    }, [date]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={newEndDateLabel}
                value={selectedDate}
                disableFuture={disableFuture}
                disableMaskedInput={disableMaskedInput}
                disablePast={disablePast}
                mask={inputMask}
                inputFormat={inputFormat}
                onChange={handleChange}
                onError={handleError}
                renderInput={(dateProps) => (
                    <Tooltip arrow placement="top" open={!!dateErrorMsg} title={dateErrorMsg}>
                        <DateField
                            {...dateProps}
                            helperText={!hideHelper ? dateProps?.inputProps?.placeholder : ''}
                            fullWidth
                            sx={datePickerStyle}
                            onBlur={handleOnBlur}
                        />
                    </Tooltip>
                )}
                // maxDate={maxDateFormatted}
                // minDate={minDateFormatted}
            />
        </LocalizationProvider>
    );
};

const mapStateToProps = ({ language, session }: ReduxStore) => {
    const { dictionary } = language;
    const { timezone } = session;

    return {
        dictionary,
        timezone,
    };
};

export const CustomDatePicker = connect(mapStateToProps)(UnconnectedCustomDatePicker);
