import { Autocomplete, FormControl, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { DropdownStructure, DropdownValue, InputSize, InputVariant } from 'types/inputs';
import { LanguageStructure } from 'types/language';

import { pxToRem } from 'components/theme/typography';

const fetchSelectedOption = (
    value: DropdownValue | null,
    options: DropdownStructure[],
    isMultiple = false
): DropdownStructure | DropdownStructure[] | null => {
    if (!value) {
        return isMultiple ? ([] as DropdownStructure[]) : null;
    }

    if (isMultiple && Array.isArray(value)) {
        return (options.filter((option) => value.includes(option.value)) || []) as DropdownStructure[];
    }

    return (options.find((option) => option.value === value) as DropdownStructure) || null;
};

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    autoWidth: {
        width: 'auto',
    },
});

const FormControlStyle = styled(FormControl)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        mb: pxToRem(16),
    })
);

type Props = {
    id?: string;
    className?: string;
    dictionary: LanguageStructure;
    fullWidth?: boolean;
    label: string;
    limitTags?: number;
    multiple?: boolean;
    options: DropdownStructure[];
    placeholder?: string;
    required?: boolean;
    size?: InputSize;
    startAdornment?: ReactNode;
    value?: DropdownValue | null;
    variant?: InputVariant;
    onChange: (optionValue: DropdownValue) => void;
};

const UnconnectedAutocomplete = (props: Props) => {
    const classes = useStyles();
    const {
        id,
        className,
        dictionary: { error },
        fullWidth,
        label,
        limitTags = 2,
        multiple,
        options,
        placeholder,
        required,
        size = 'small',
        startAdornment,
        value = null,
        variant = 'outlined',
        onChange,
    } = props;
    const [singleValue, setSingleValue] = useState<DropdownStructure | null>(null);
    const [multipleValue, setMultipleValue] = useState<DropdownStructure[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const inputProps = startAdornment ? { startAdornment } : {};

    const handleGetOptionLabel = (currentOption: DropdownStructure) => {
        const { label: optionLabel } = currentOption;
        return optionLabel;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (event: SyntheticEvent<Element, Event>, newValue: any) => {
        if (multiple) {
            setMultipleValue([...newValue]);
            const selectedValues = newValue.map((option) => option.value);
            onChange(selectedValues);
        } else {
            setSingleValue(newValue);
            onChange(newValue?.value);
        }
    };

    const validateMissingValue = (isInputDirty: boolean) => {
        if (!required) {
            return false;
        }

        if (multiple && Array.isArray(value)) {
            return !multipleValue.length && isInputDirty;
        }

        return isEmpty(singleValue) && isInputDirty;
    };

    const handleBlur = () => {
        const isValueMissing = validateMissingValue(true);

        if (!isValueMissing) {
            return;
        }

        setIsDirty(true);
        setErrorMessage(error.emptyValue);
    };

    useEffect(() => {
        if (multiple) {
            setMultipleValue(fetchSelectedOption(value as number[], options, multiple) as DropdownStructure[]);
        } else {
            setSingleValue(fetchSelectedOption(value as number, options) as DropdownStructure);
        }

        const isValueMissing = validateMissingValue(isDirty);

        if (isValueMissing) {
            setErrorMessage(error.emptyValue);
        } else {
            setErrorMessage('');
        }
    }, [value]);

    return (
        <FormControlStyle className={fullWidth ? classes.fullWidth : classes.autoWidth}>
            <Autocomplete
                id={id}
                multiple={multiple}
                limitTags={limitTags}
                className={className}
                value={multiple ? multipleValue : singleValue}
                options={options}
                size={size}
                autoComplete={false}
                getOptionLabel={handleGetOptionLabel}
                clearOnBlur
                clearOnEscape
                disableCloseOnSelect={multiple}
                openOnFocus
                selectOnFocus
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        size={size}
                        variant={variant}
                        InputProps={{
                            ...params.InputProps,
                            ...inputProps,
                        }}
                        required={required}
                        error={errorMessage !== ''}
                        helperText={errorMessage}
                    />
                )}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </FormControlStyle>
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

// eslint-disable-next-line import/prefer-default-export
export const CustomAutocomplete = connect(mapStateToProps)(UnconnectedAutocomplete);
