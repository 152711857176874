// below are the actions that need to be permission checked.
// This will then be expanded to include the resource ids before checking with Permit
export const USER_ACTIONS = [
    {
        action: 'operations-dashboard',
        resource: 'Company',
    },
    {
        action: 'read',
        resource: 'Company',
    },
    {
        action: 'operations-dashboard',
        resource: 'Region',
    },
    {
        action: 'read',
        resource: 'Region',
    },
    {
        action: 'operations-dashboard',
        resource: 'Community',
    },
    {
        action: 'read',
        resource: 'Community',
    },
    {
        action: 'resident-deep-dive',
        resource: 'Community',
    },
    {
        action: 'performance-dashboard',
        resource: 'Community',
    },
    {
        action: 'historical-task-update',
        resource: 'Community',
    },
    {
        action: 'caregiver-update',
        resource: 'Community',
    },
    {
        action: 'caregiver-delete',
        resource: 'Community',
    },
];
