import React, { useMemo } from 'react';
import { IconContext } from 'react-icons';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import { pxToRem } from 'components/theme/typography';

type CollapseIconProps = {
    isCollapsed: boolean;
};

export const CollapseIcon = (props: CollapseIconProps) => {
    const { isCollapsed } = props;

    const styles = useMemo(
        () => ({
            color: '#B1B2B7',
            size: pxToRem(30),
        }),
        []
    );

    return <IconContext.Provider value={styles}>{isCollapsed ? <FaAngleDown /> : <FaAngleUp />}</IconContext.Provider>;
};
