import React from 'react';

import Base from 'components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
};

export const IncreaseArrow = ({ className, size }: Props) => (
    <Base className={className} size={size} viewBox="0 0 16 16">
        <path
            d="M12.7519 4V10.5C12.7519 10.6989 12.6729 10.8897 12.5323 11.0303C12.3916 11.171 12.2008 11.25 12.0019 11.25C11.803 11.25 11.6122 11.171 11.4716 11.0303C11.3309 10.8897 11.2519 10.6989 11.2519 10.5V5.8125L4.53255 12.5306C4.39165 12.6715 4.20056 12.7507 4.0013 12.7507C3.80204 12.7507 3.61095 12.6715 3.47005 12.5306C3.32915 12.3897 3.25 12.1986 3.25 11.9994C3.25 11.8001 3.32915 11.609 3.47005 11.4681L10.1894 4.75H5.50193C5.30301 4.75 5.11225 4.67098 4.9716 4.53033C4.83094 4.38968 4.75193 4.19891 4.75193 4C4.75193 3.80109 4.83094 3.61032 4.9716 3.46967C5.11225 3.32902 5.30301 3.25 5.50193 3.25H12.0019C12.2008 3.25 12.3916 3.32902 12.5323 3.46967C12.6729 3.61032 12.7519 3.80109 12.7519 4Z"
            fill="currentColor"
        />
    </Base>
);
