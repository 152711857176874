import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { CustomMobileDialog } from 'components/Custom/CustomMobileDialog';

type Props = {
    isOpen: boolean;
    taskNote: string;
    onClose: () => void;
};

export const TaskNotesViewDialog = (props: Props) => {
    const { isOpen, taskNote, onClose } = props;

    const { taskNotes: taskNotesTxt } = useSelector((state: ReduxStore) => state.language.dictionary.home);

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={taskNotesTxt}
            width="100%"
            onClose={onClose}
            content={
                <Typography
                    sx={{
                        textAlign: 'left',
                    }}
                    variant="body2"
                >
                    {taskNote}
                </Typography>
            }
        />
    );
};
