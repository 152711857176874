import { Box, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';
import { parseAndFormatDate } from 'pages/ResidentDashboard/helpers';

import { DateAndTime } from './DateAndTime';
import { Note } from './Note';

type Props = {
    row: Visit;
};

export const VisitRowContent = ({ row }: Props) => {
    const { visitType, startDate, endDate, note, dayCount } = row;

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Typography
                    fontSize={{
                        xs: pxToRem(12),
                        md: pxToRem(14),
                    }}
                    fontWeight={700}
                    lineHeight={1.7}
                    mb={pxToRem(2)}
                >
                    {visitType}
                </Typography>
                {dayCount && (
                    <Typography
                        fontSize={{
                            xs: pxToRem(12),
                            md: pxToRem(14),
                        }}
                        fontWeight={700}
                        lineHeight={1.7}
                        mb={pxToRem(2)}
                    >
                        {dayCount}
                    </Typography>
                )}
            </Box>
            {startDate && (
                <Box>
                    <Typography
                        fontSize={{
                            xs: pxToRem(10),
                            md: pxToRem(11),
                        }}
                        lineHeight={1.3}
                        color="#6F6F79"
                        textTransform="capitalize"
                        component="span"
                    >
                        Started:&nbsp;
                    </Typography>
                    <DateAndTime timeStamp={parseAndFormatDate(startDate)} sx={{ display: 'inline' }} />
                </Box>
            )}
            {endDate && (
                <Box>
                    <Typography
                        fontSize={{
                            xs: pxToRem(10),
                            md: pxToRem(11),
                        }}
                        lineHeight={1.3}
                        color="#6F6F79"
                        textTransform="capitalize"
                        component="span"
                    >
                        Returned:&nbsp;
                    </Typography>
                    <DateAndTime timeStamp={parseAndFormatDate(endDate)} sx={{ display: 'inline' }} />
                </Box>
            )}
            {note && <Note note={note} sx={{ mt: pxToRem(8) }} />}
        </Box>
    );
};
