import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';

import { useAppDispatch } from 'constants/redux';
import { getToken } from 'lib/common';
import { throwError } from 'redux/actions/messages';
import { readUser } from 'redux/actions/users';

export const useCaregiverPhoneMutation = (userId: number) => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { phoneNumber: string; phoneNumberConfirmed: boolean }) => {
            await api.put(`/users`, params, {
                headers: { authorization: getToken() },
            });
        },
        onSuccess: () => {
            dispatch(readUser(userId));
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
