import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'constants/redux';
import { throwError } from 'redux/actions/messages';

export const useSignupMutation = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    type Params = {
        firstName: string;
        lastName: string;
        username: string;
        phoneNumber: string;
        email: string;
    };

    return useMutation({
        mutationFn: async (params: Params) => {
            await api.post(`/users`, params);

            return params.phoneNumber;
        },
        onSuccess: (phoneNumber) => {
            navigate(`/signup/verify?phoneNumber=${encodeURIComponent(phoneNumber)}`);
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
