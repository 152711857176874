import { Action, ResidentsState } from 'types/redux';

import { RESIDENTS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const residents = (state: ResidentsState = RESIDENTS_DEFAULT_STATE, action: Action) => {
    switch (action.type) {
        case actionTypes.RESIDENT_TOGGLE_DISPLAY_PARTY: {
            return {
                displayParty: !state.displayParty,
            };
        }
        default:
            return state;
    }
};

export default residents;
