import { DEFAULT_SHIFT_OPTIONS } from '@alliehealth/utils/dist/shifts';
import { Box } from '@mui/material';
import momentTz from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';

import { HistoryItemType } from 'types/myRewards';
import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import { createShiftNamesMap } from 'lib/common';

const ITEM_TYPE_TO_NAME: Record<HistoryItemType, string> = {
    DOCUMENTATION_COMPLIANCE: 'Documentation Compliance',
    UNSCHEDULED_TASK_ADDED: 'Unscheduled/PRN Task Added',
    RESIDENT_NOTE_SUBMITTED: 'Resident Note Submitted',
    SHIFT_CHECK_IN_COMPLETE: 'Shift Check-In Complete',
};

const SHIFT_NAME_TO_TEXT: Record<string, string> = createShiftNamesMap(DEFAULT_SHIFT_OPTIONS);

type Props = {
    itemType: HistoryItemType;
    name: string;
    shiftName: string;
    points: number;
    datetimeUtc?: string;
    residentName?: string;
    note?: string;
};

export const PointHistoryItem = (props: Props) => {
    const { itemType, datetimeUtc, name, residentName, points, shiftName, note } = props;

    const { timezone } = useSelector((state: ReduxStore) => state.session);

    let time: string | null = null;
    if (timezone) {
        time = datetimeUtc
            ? momentTz.utc(datetimeUtc).tz(timezone).format('h:mm A')
            : `${SHIFT_NAME_TO_TEXT[shiftName]} Shift`;
    }

    return (
        <Box
            sx={{
                backgroundColor: '#F8F8F9',
                padding: pxToRem(16),
                borderRadius: pxToRem(8),
                border: '1px solid #F4F4F5',
                marginBottom: pxToRem(12),
                display: 'flex',
                flexDirection: 'column',
                '&:last-of-type': {
                    marginBottom: 0,
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: pxToRem(8),
                }}
            >
                <Box
                    sx={{
                        color: '#CB7E63',
                        fontWeight: 700,
                        fontSize: pxToRem(12),
                    }}
                >
                    {ITEM_TYPE_TO_NAME[itemType]}
                </Box>
                <Box
                    sx={{
                        color: '#6F6F79',
                        fontWeight: 400,
                        fontSize: pxToRem(12),
                    }}
                >
                    {time}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            fontSize: pxToRem(14),
                            fontWeight: 700,
                            color: '#2C2D3B',
                            lineHeight: pxToRem(24),
                        }}
                    >
                        {name}
                    </Box>
                    {residentName && (
                        <Box
                            sx={{
                                fontSize: pxToRem(14),
                                fontWeight: 400,
                                color: '#2C2D3B',
                                lineHeight: pxToRem(24),
                            }}
                        >
                            {residentName}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        fontSize: pxToRem(14),
                        fontWeight: 700,
                        color: '#006B75',
                        lineHeight: pxToRem(24),
                    }}
                >
                    +{points}
                </Box>
            </Box>
            {note && (
                <Box
                    sx={{
                        backgroundColor: '#EFEFF1',
                        borderLeft: '2px solid #DEDEE0',
                        paddingTop: pxToRem(8),
                        paddingBottom: pxToRem(8),
                        paddingLeft: pxToRem(10),
                        paddingRight: pxToRem(10),
                        fontSize: pxToRem(12),
                        fontWeight: 500,
                        lineHeight: pxToRem(14),
                        color: '#595964',
                        marginTop: pxToRem(8),
                    }}
                >
                    {note}
                </Box>
            )}
        </Box>
    );
};
