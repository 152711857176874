import React, { useEffect, useMemo } from 'react';

import { OperationsLevel, OperationsPeriod } from 'types/operationsDashboard';

import { useBranchesByRegionQuery } from 'api/queries/companyInfo';
import { useOperationsBranchStatsQuery, useOperationsOverallStatsQuery } from 'api/queries/dashboard/operations';
import Loading from 'components/Shared/Loading';
import { OVERALL_SECTIONS_MAP, Section } from 'pages/OperationsDashboard/constants';
import { useBranchDataItems } from 'pages/OperationsDashboard/data-helpers/branch';
import { getStartDateOptions, useOverallDataItems } from 'pages/OperationsDashboard/data-helpers/overall';

import { DateGroupSelector } from '../DateGroupSelector';

import { SectionModule } from './SectionModule';

const useModuleData = (level, operationsPeriod, branchId) => {
    const {
        data: operationsOverallStatsData,
        isLoading: operationsOverallStatsIsLoading,
        isError: operationsOverallStatsIsError,
    } = useOperationsOverallStatsQuery({ groupBy: operationsPeriod });

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionDataIsLoading,
        isError: branchesByRegionDataIsError,
    } = useBranchesByRegionQuery();

    const {
        data: operationsBranchStatsData,
        isLoading: operationsBranchStatsDataIsLoading,
        isError: operationsBranchStatsDataIsError,
    } = useOperationsBranchStatsQuery(
        level === 'branch'
            ? {
                  groupBy: operationsPeriod,
                  branchId: Number(branchId),
              }
            : undefined
    );

    if (
        branchesByRegionDataIsLoading ||
        (level !== 'branch' && operationsOverallStatsIsLoading) ||
        (level === 'branch' && operationsBranchStatsDataIsLoading)
    ) {
        return {
            status: 'loading',
        };
    }

    if (
        branchesByRegionDataIsError ||
        !branchesByRegionData ||
        (level !== 'branch' && (operationsOverallStatsIsError || !operationsOverallStatsData)) ||
        (level === 'branch' && (operationsBranchStatsDataIsError || !operationsBranchStatsData))
    ) {
        return {
            status: 'error',
        };
    }

    return {
        status: 'success',
        overallData: operationsOverallStatsData,
        branchData: operationsBranchStatsData,
        branchesByRegion: branchesByRegionData,
    };
};

type SectionModuleProps = {
    level: OperationsLevel;
    operationsPeriod: OperationsPeriod;
    regionId: string;
    branchId: string;
    dataType: Section;
    miniDisplay?: 'chart' | 'table';
    onClick?: () => void;
};

export const SectionDataModule = ({
    level,
    operationsPeriod,
    regionId,
    branchId,
    dataType,
    miniDisplay,
    onClick,
}: SectionModuleProps) => {
    const [selectedStartDate, setSelectedStartDate] = React.useState<string>();
    const sectionTitle = OVERALL_SECTIONS_MAP[dataType].title;

    const {
        status: dataStatus,
        overallData,
        branchData,
        branchesByRegion,
    } = useModuleData(level, operationsPeriod, branchId);

    const overallDateOptions = useMemo(
        () => getStartDateOptions(overallData, dataType, regionId, level),
        [JSON.stringify(overallData), dataType, regionId, level]
    );

    console.log('selected operations period', operationsPeriod, JSON.stringify(overallDateOptions));

    // reset the selected start date when changing between day/week/month since different ranges
    // keep date range when changing between company/region/branch for seamless flow of data
    useEffect(() => {
        console.log('changing selected start date', overallDateOptions);
        setSelectedStartDate(overallDateOptions ? overallDateOptions[1] : undefined);
    }, [JSON.stringify(overallDateOptions), operationsPeriod]);

    const { OverallChartComponent, overallChartProps, OverallTableComponent, overallTableProps, overallStat } =
        useOverallDataItems(overallData, branchesByRegion, dataType, level, regionId, selectedStartDate);

    const { BranchChartComponent, branchChartProps, branchDateOptions, branchStat, renderTableComponent } =
        useBranchDataItems(branchData, dataType, branchId, selectedStartDate);

    if (dataStatus === 'loading') {
        return <Loading />;
    }

    if (dataStatus === 'error') {
        return null;
    }

    if (
        !overallChartProps ||
        !OverallChartComponent ||
        !overallTableProps ||
        !OverallTableComponent ||
        !overallDateOptions ||
        (level === 'branch' && (!branchChartProps || !BranchChartComponent || !branchDateOptions))
    ) {
        return null;
    }

    let renderChart = () => (
        <OverallChartComponent
            {...overallChartProps}
            setSelectedStartDate={setSelectedStartDate}
            selectedStartDate={selectedStartDate}
        />
    );
    let renderTable = () => <OverallTableComponent {...overallTableProps} timePeriod={operationsPeriod} />;
    const renderDateSelector = () =>
        typeof selectedStartDate !== 'undefined' ? (
            <DateGroupSelector
                sectionTitle={sectionTitle}
                selectedDate={selectedStartDate}
                dateOptions={level === 'branch' && branchDateOptions ? branchDateOptions : overallDateOptions}
                setSelectedStartDate={setSelectedStartDate}
                selectedTimePeriod={operationsPeriod}
            />
        ) : null;

    if (level === 'branch' && BranchChartComponent) {
        renderChart = () => (
            <BranchChartComponent
                {...branchChartProps}
                setSelectedStartDate={setSelectedStartDate}
                selectedStartDate={selectedStartDate}
            />
        );
        renderTable = renderTableComponent;
    }

    return (
        <SectionModule
            sectionTitle={sectionTitle}
            operationsPeriod={operationsPeriod}
            overallStat={level === 'branch' ? branchStat : overallStat}
            renderChart={renderChart}
            renderTable={renderTable}
            renderDateSelector={renderDateSelector}
            miniDisplay={miniDisplay}
            onClick={onClick}
        />
    );
};
