import * as Sentry from '@sentry/react';

import { ErrorProps } from 'types/messages';
import { AppDispatch } from 'types/redux';
import { SessionData } from 'types/session';

import { PROFILE } from 'constants/localStorage';
import store from 'redux/Store';
import actionTypes from 'redux/actionTypes';
import { handleError } from 'redux/actions/messages';

const setSentryUser = (id: string, username: string) => {
    Sentry.setUser({
        id,
        username,
    });
};

const identifyUserWithAnalytics = (id: string, username: string) => {
    try {
        window.freshpaint.identify(id, {
            username,
        });
    } catch (error) {
        Sentry.captureEvent(error);
    }
};

type Profile = {
    isSignedIn: boolean;
    sessionData: SessionData;
};

const storeSessionInLocalStorage = (profile: Profile) => {
    localStorage.setItem(PROFILE, JSON.stringify(profile));
};

const storeSessionInReduxStore = (profile: Profile) => {
    store.dispatch({
        type: actionTypes.SESSION_SIGN_IN,
        payload: profile,
    });
};

const handleRedirect = () => {
    const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');
    if (redirectUrl) {
        window.location.replace(redirectUrl);
    }
};

const getErrorMessage = (errorCode: number, errorShort: string) => {
    let errorMessage = 'You need to provide a Username and Password!';

    if (errorCode === 401 && errorShort === 'mismatch') {
        errorMessage = 'The credentials do not match! Please try again.';
    } else if (errorCode === 401 && errorShort === 'inactive') {
        errorMessage = 'Sorry, but your account has been deactivated. Please contact your manager.';
    } else if (errorCode === 404) {
        errorMessage = 'The Account you provided does not exist. Please check and try again.';
    }
    return errorMessage;
};

const handleErrors = (error: any) => {
    const errorMessage = getErrorMessage(error.response.data.code, error.response.data.short);

    const errorProps: ErrorProps = {
        error,
        consoleMessage: errorMessage,
        alertMessage: errorMessage,
    };

    (store.dispatch as AppDispatch)(handleError(errorProps));
};

export const signIn = async (data: SessionData) => {
    try {
        const { userId, username } = data;

        setSentryUser(userId.toString(), username);

        identifyUserWithAnalytics(userId.toString(), username);

        const profile: Profile = {
            isSignedIn: true,
            sessionData: data,
        };

        storeSessionInLocalStorage(profile);

        storeSessionInReduxStore(profile);

        handleRedirect();
    } catch (error) {
        handleErrors(error);
    }
};
