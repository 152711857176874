import { Box, Card, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    amount: string;
    label: string;
    icon: React.ReactNode;
};

const ICON_SIZE = 40;

export const AssessmentDetailsCard = ({ amount, label, icon }: Props) => (
    <Card
        variant="outlined"
        sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#FCFCFD',
        }}
    >
        <Box>
            <Typography variant="h6" lineHeight={1.6}>
                {amount}
            </Typography>
            <Typography fontSize={{ xs: pxToRem(12) }} color="#6F6F79" lineHeight={1.3} textAlign="left">
                {label}
            </Typography>
        </Box>
        <Box
            width={pxToRem(ICON_SIZE)}
            height={pxToRem(ICON_SIZE)}
            bgcolor="#F2F8F8"
            color="primary.main"
            p={pxToRem(10)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
        >
            {icon}
        </Box>
    </Card>
);
