import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { format, subDays } from 'date-fns';
import { snakeCase } from 'lodash';
import React from 'react';
import { BsArrowLeft as ReturnIcon } from 'react-icons/bs';
import { IoCloseOutline as CloseIcon, IoEllipsisHorizontal as OtherIcon } from 'react-icons/io5';

import { pxToRem } from 'components/theme/typography';
import { TASKS_MODULES, taskCategoryIconMap } from 'pages/ResidentDashboard/constants';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    tasksDialogData: TasksDialogData;
};

export const TasksDialog = ({ isOpen, onClose, tasksDialogData }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    let tasksDialogTitle = '';

    const yesterday = subDays(new Date(), 1);
    const defaultStartDate = subDays(yesterday, 30);
    const startDate = format(defaultStartDate, 'MM/dd/yy');
    const endDate = format(yesterday, 'MM/dd/yy');

    if (tasksDialogData.type === 'refused') {
        tasksDialogTitle = TASKS_MODULES[0].title;
    } else if (tasksDialogData.type === 'prn') {
        tasksDialogTitle = TASKS_MODULES[1].title;
    } else if (tasksDialogData.type === 'assist') {
        tasksDialogTitle = 'Assist Tasks';
    }

    if (!isOpen || tasksDialogData.data.length === 0) {
        return null;
    }

    const rows = [...tasksDialogData.data]
        .map((task, index) => ({
            ...task,
            id: index,
        }))
        .sort((a, b) => {
            const dateA = new Date(a.shiftDayDate);
            const dateB = new Date(b.shiftDayDate);
            return dateB.getTime() - dateA.getTime();
        });

    const columns: GridColDef[] = [
        {
            field: 'taskName',
            headerName: 'Task Name',
            width: 300,
        },
        {
            field: 'keyService',
            headerName: 'Category',
            width: 200,
            renderCell: (params: GridValueGetterParams) => (
                <Box display="flex" alignItems="center" gap={pxToRem(6)}>
                    <Box
                        color="#6F6F79"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width={pxToRem(20)}
                        height={pxToRem(20)}
                    >
                        {taskCategoryIconMap[snakeCase(params.value.toLowerCase()) as string] || <OtherIcon />}
                    </Box>
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'shiftName',
            headerName: 'Shift',
            width: 120,
        },
        {
            field: 'shiftDayDate',
            headerName: 'Date',
            width: 160,
            valueFormatter: (params: GridValueGetterParams) => format(new Date(params.value as string), 'MM/dd/yy'),
        },
        {
            field: 'caregiver',
            headerName: 'Caregiver',
            width: 250,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.caregiver.firstName || ''} ${params.row.caregiver.lastName || ''}`,
        },
    ];

    const mobileColumns: GridColDef[] = [
        { ...columns[0], width: 200 }, // Task Name
        { ...columns[3], width: 90 }, // Date
        { ...columns[1], width: 150 }, // Category
        { ...columns[2], width: 80 }, // Shift
        { ...columns[4], width: 180 }, // Caregiver
    ];

    return (
        <Dialog
            open={isOpen}
            maxWidth="xl"
            onClose={onClose}
            fullScreen={isMobile}
            sx={{
                '& .MuiDialog-paper': {
                    p: {
                        xs: pxToRem(16),
                        md: pxToRem(24),
                    },
                    pt: {
                        xs: pxToRem(24),
                    },
                },
                '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiTypography-root.MuiDialogTitle-root': {
                    p: 0,
                },
                '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root': {
                    p: 0,
                },
                '& .MuiDialog-container': {
                    '& .MuiPaper-root.MuiDialog-paper': {
                        m: {
                            xs: 0,
                            md: pxToRem(16),
                        },
                    },
                },
            }}
        >
            <DialogTitle mb={pxToRem(24)}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={pxToRem(12)}>
                        <IconButton onClick={onClose} size="large" sx={{ border: '1px solid #DEDEE0' }}>
                            <ReturnIcon size={isMobile ? pxToRem(12) : pxToRem(16)} color="#6F6F79" />
                        </IconButton>
                        <Box>
                            {tasksDialogTitle}
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                display="block"
                                fontSize={{ xs: pxToRem(12), md: pxToRem(14) }}
                            >
                                {startDate} - {endDate}
                            </Typography>
                        </Box>
                    </Box>
                    {!isMobile && (
                        <IconButton sx={{ border: '1px solid #DEDEE0' }} onClick={onClose}>
                            <CloseIcon color="#6F6F79" size={isMobile ? pxToRem(14) : pxToRem(20)} />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width="100%">
                    <Divider
                        sx={{
                            mb: pxToRem(32),
                        }}
                    />
                    <DataGrid
                        autoHeight
                        rows={rows}
                        columns={isMobile ? mobileColumns : columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#F4F4F5',
                            },
                            '& .MuiDataGrid-iconButtonContainer': {
                                visibility: 'visible',
                                ml: { xs: 0, md: pxToRem(6) },
                            },
                            '& .MuiDataGrid-sortIcon': {
                                opacity: '.6 !important',
                            },
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};
