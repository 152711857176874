import { Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { pxToRem } from 'components/theme/typography';

const StyledLink = ({ url, text }: { url: string; text: string }) => (
    <Link
        component={RouterLink}
        to={url}
        sx={{
            color: 'primary.dark',
            fontWeight: 400,
            textAlign: 'left',
            fontSize: {
                xs: pxToRem(12),
            },
            lineHeight: {
                xs: 1.7,
            },
            '&:hover': {
                fontWeight: 400,
            },
            '&:visited': {
                fontWeight: 400,
            },
            '&:focus': {
                fontWeight: 400,
            },
            '&:active': {
                fontWeight: 400,
            },
        }}
        target="_blank"
    >
        {text}
    </Link>
);

export const PhoneDisclaimer = () => (
    <Typography
        sx={{
            fontSize: {
                xs: pxToRem(12),
            },
            lineHeight: {
                xs: 1.7,
            },
            color: '#6F6F79',
            textAlign: 'left',
            mb: pxToRem(8),
        }}
    >
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(12),
                },
                lineHeight: {
                    xs: 1.7,
                },
                display: 'inline',
            }}
        >
            By continuing, you will receive an SMS for verification. Message and data rates may apply. You also agree to
            our
        </Typography>{' '}
        <StyledLink url="/terms-of-service" text="Terms of Service" /> &{' '}
        <StyledLink url="/privacy-policy" text="Privacy Policy" />. To Opt-out text STOP in response to the message.
    </Typography>
);
