import { api } from 'api';

import { BranchesByRegionResponse } from 'types/companyInfo';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { getToken } from 'lib/common';

export const useBranchesByRegionQuery = (enabled = true) =>
    useErrorHandledQuery<BranchesByRegionResponse>(
        ['branches-by-region'],
        async (): Promise<BranchesByRegionResponse> => {
            const { data } = await api.get('/company-info/branches-by-region', {
                headers: { authorization: getToken() },
            });
            return data.response;
        },
        {
            enabled,
            staleTime: 300000,
        }
    );
