import { useMediaQuery } from '@mui/material';
import React from 'react';

import { SelectStructure } from 'types/inputs';

import { CustomSelect } from 'components/Custom';

import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from '../constants';

type Props = {
    currentRegion: string;
    onChange: (newShift: string) => void;
    disabled?: boolean;
    regionOptions: SelectStructure[];
};

const RegionSelector = ({ currentRegion, onChange, disabled, regionOptions }: Props) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    return (
        <CustomSelect
            id="region"
            label="Region"
            value={currentRegion}
            fullWidth
            options={regionOptions}
            onChange={onChange}
            disabled={disabled}
            sx={isMobile ? { ...MOBILE_SELECTOR_STYLES, ...SELECTOR_STYLES } : SELECTOR_STYLES}
            truncate
        />
    );
};
export default RegionSelector;
