import { api } from 'api';

import {
    DailyTaskDownloadParams,
    DailyTasksCreateParams,
    DailyTasksCsvReadParams,
    DailyTasksShiftPeriod,
    ResidentTasksDownloadParams,
    UpdateDailyTasksParams,
} from 'types/dailyTasks';
import { MessageProps } from 'types/messages';

import { formatApiParams, getToken } from 'lib/common';
import { downloadCsv } from 'lib/csv';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { showAlert, throwError } from 'redux/actions/messages';
import 'redux/reducers/dailyTasks';

/**
 * Updates the Current Shift Period.
 * @param shiftPeriod
 */
export const updateShiftPeriod = (shiftPeriod: DailyTasksShiftPeriod) => ({
    type: actionTypes.DAILY_TASKS_UPDATE_SHIFT_PERIOD,
    payload: shiftPeriod,
});

/**
 * Creates an Added Daily Task Record based on the provided params.
 * @param branchId the User's Branch ID where to fetch the Daily Task Records after the creation for the new one.
 * @param date the Date where to fetch the Daily Task Records after the creation for the new one.
 * @param jsonParams the JSON object with the params for creating an Added Daily Task Record.
 */
export const createDailyTask = (jsonParams: DailyTasksCreateParams) => async (dispatch, getState) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DAILY_TASKS_LOADING, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Create the new Added Task.
        await api.post('/tasks', params, {
            headers: {
                authorization: token,
            },
        });

        // Get the Home dictionary for fetching the success message.
        const {
            language: {
                dictionary: { home },
            },
        } = getState();

        // Set the success message to display into the alert.
        const message: MessageProps = {
            open: true,
            message: home.createTaskSuccess,
            alertSeverity: 'success',
            status: 200,
        };

        dispatch(showAlert(message));
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DAILY_TASKS_LOADING, false));
    }
};

export const updateDailyTasks =
    ({ tasks }: UpdateDailyTasksParams) =>
    async (dispatch, getState) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.DAILY_TASKS_LOADING, true));

            // Get the token.
            const token = getToken();

            // Update the status for the Daily Task Record.
            await api.put(`/tasks`, tasks, {
                headers: {
                    authorization: token,
                },
            });

            // Get the Home dictionary for fetching the success message.
            const {
                language: {
                    dictionary: { home },
                },
            } = getState();

            // Set the success message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: home.updateTaskSuccess,
                alertSeverity: 'success',
                status: 200,
            };

            dispatch(showAlert(message));
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.DAILY_TASKS_LOADING, false));
        }
    };

/**
 * Downloads the list of Daily Task Records (based on a Company Branch) into a CSV file.
 * @param jsonParams the JSON object with the params for downloading the CSV file.
 */
export const downloadDailyTaskCsv =
    (
        downloadUrl: string,
        jsonParams: DailyTasksCsvReadParams | DailyTaskDownloadParams | ResidentTasksDownloadParams
    ) =>
    async (dispatch, getState) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.DAILY_TASKS_LOADING, true));

            // Get the token.
            const token = getToken();

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Fetch the CSV content and filename.
            const csvData = (
                await api.get(downloadUrl, {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data;

            dispatch({
                type: actionTypes.DAILY_TASKS_DOWNLOAD_CSV,
            });

            // Trigger the action for Downloading the CSV file.
            downloadCsv(csvData.csv, csvData.filename);

            // Get the Home dictionary for fetching the success message.
            const {
                language: {
                    dictionary: { home },
                },
            } = getState();

            // Set the success message to display into the alert.
            const message: MessageProps = {
                open: true,
                message: home.downloadCsvSuccess,
                alertSeverity: 'success',
                status: 200,
            };

            dispatch(showAlert(message));
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.DAILY_TASKS_LOADING, false));
        }
    };
