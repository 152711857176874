import { RemoveCircle as CheckboxPartialIcon } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent } from 'react';
import { BsFillCheckCircleFill as CheckboxActiveIcon, BsFillCircleFill as CheckboxInactiveIcon } from 'react-icons/bs';

import { pxToRem } from 'components/theme/typography';

const CheckboxStyle = styled(Checkbox)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        width: pxToRem(32),
    })
);

const ActiveIcon = styled(CheckboxActiveIcon)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.app.green.main,
        fontSize: pxToRem(16),
    })
);

const InactiveIcon = styled(CheckboxInactiveIcon)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.grey[400],
        fontSize: pxToRem(16),
    })
);

const PartialIcon = styled(CheckboxPartialIcon)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.app.green.main,
        fontSize: pxToRem(16),
    })
);

type Props = {
    checked: boolean;
    isPartial?: boolean;
    onChange: (newCheckedStatus: boolean) => void;
};

export const CustomCheckbox = (props: Props) => {
    const { checked, isPartial, onChange } = props;
    const uncheckedIcon = isPartial ? <PartialIcon /> : <InactiveIcon />;

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked: newCheckedStatus } = event.target;
        onChange(newCheckedStatus);
    };

    return (
        <CheckboxStyle
            checked={checked}
            checkedIcon={<ActiveIcon />}
            icon={uncheckedIcon}
            onChange={handleCheckboxChange}
        />
    );
};
