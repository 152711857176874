import React from 'react';

import { TERMS_OF_SERVICE } from 'constants/termsOfService';

function createMarkup() {
    return { __html: TERMS_OF_SERVICE };
}

const TermsOfService = () => (
    // eslint-disable-next-line react/no-danger
    <div style={{ padding: '12px' }} dangerouslySetInnerHTML={createMarkup()} />
);

export default TermsOfService;
