import { App, URLOpenListenerEvent } from '@capacitor/app';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AppUrlListener: React.FC<any> = () => {
    const navigate = useNavigate();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // Example url: https://dev.alliehealth.co/residents
            // slug = /residents
            const slug = event.url.split('.co').pop(); // TODO: Change
            if (slug) {
                navigate(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
