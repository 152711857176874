import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { api } from 'api';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { LanguageStructure } from 'types/language';
import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';
import { UserResponse } from 'types/users';

import audioWave from 'assets/audio-wave2.gif';
import { CustomTextField } from 'components/Custom';
import { MicrophoneIcon } from 'components/Svg';
import { pxToRem } from 'components/theme/typography';
import { PROFILE } from 'constants/localStorage';
import { getToken } from 'lib/common';
import { handleError } from 'redux/actions/messages';
import { readUser } from 'redux/actions/users';

import ActivateMicConfirmation from './ActivateMicConfirmation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    micIcon: {
        color: theme.palette.primary.dark,
    },
}));

interface StopRecordingProps {
    discard?: boolean;
}

type Props = {
    label: string;
    placeholder: string;
    showActivateMicConfirmation: boolean;
    text: string;
    analyticsIdText: string;
    dictionary: LanguageStructure;
    user: UserResponse;
    onChange: (text: string) => void;
    toggleShowActivateMicConfirmation: (show: boolean) => void;
    dispatchThrowError: (error: string) => void;
    dispatchReadUser: (userId: number) => void;
};

const TranscriptionTextField = (props: Props) => {
    const {
        label,
        placeholder,
        showActivateMicConfirmation,
        text,
        analyticsIdText,
        dictionary: { error: errorTxt },
        user,
        onChange,
        toggleShowActivateMicConfirmation,
        dispatchThrowError,
        dispatchReadUser,
    } = props;

    const displayTranscriptionButton = Capacitor.isNativePlatform() || process.env.REACT_APP_ENV !== 'production';

    const classes = useStyles();

    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviceLanguage, setDeviceLanguage] = useState<string>('en');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (errorMessage) {
            dispatchThrowError(errorMessage);
        }
    }, [errorMessage]);

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Get the user id from the stored data.
            const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
            const { userId: sessionUserId } = storedSession?.sessionData || {};

            // Fetch the information from the user.
            dispatchReadUser(sessionUserId);
        }

        Device.getLanguageCode().then(({ value }) => {
            setDeviceLanguage(value);
        });
    }, []);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        //
        return () => {
            stopRecording({ discard: true });
        };
    }, []);

    useEffect(() => {
        if (recording) {
            // Discard recording after 2 minutes to avoid accidental long recordings
            const timer = setTimeout(() => {
                stopRecording({ discard: true });
            }, 120000);

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [recording]);

    const checkPermission = async () => {
        const { value: hasPermission } = await VoiceRecorder.hasAudioRecordingPermission();

        if (hasPermission) {
            startRecording();
        } else {
            toggleShowActivateMicConfirmation(true);
        }
    };

    const requestPermission = async () => {
        const { value: grantedPermission } = await VoiceRecorder.requestAudioRecordingPermission();

        if (grantedPermission) {
            startRecording();
        } else {
            setErrorMessage(errorTxt.recordingPermission);
        }
    };

    const startRecording = () => {
        setRecording(true);
        VoiceRecorder.startRecording();
    };

    const stopRecording = async ({ discard = false }: StopRecordingProps) => {
        setRecording(false);

        const { status } = await VoiceRecorder.getCurrentStatus();
        if (status === 'NONE') {
            return;
        }

        const { recordDataBase64, mimeType } = (await VoiceRecorder.stopRecording()).value;

        if (discard) return;

        setLoading(true);

        // https://stackoverflow.com/a/36183085
        const blob = await fetch(`data:${mimeType};base64,${recordDataBase64}`).then((res) => res.blob());

        const form = new FormData();
        form.append('file', blob);
        form.append('language', deviceLanguage || 'en');

        try {
            const response = await api.post('/resident-shift-notes/transcribe-audio', form, {
                headers: {
                    authorization: getToken(),
                },
            });

            if (response.data.status !== 'OK') {
                setErrorMessage(errorTxt.transcription);
                return;
            }

            const newText = text?.length > 0 ? `${text} ${response.data.response.text}` : response.data.response.text;

            onChange(newText);
            setErrorMessage(null);
        } catch (err) {
            console.log(err);
            setErrorMessage(errorTxt.transcription);
        }

        setLoading(false);
    };

    const renderRecordButton = () => {
        if (!displayTranscriptionButton) {
            return null;
        }

        if (loading) {
            return <CircularProgress color="inherit" size={20} />;
        }

        if (recording) {
            return (
                <Button
                    sx={{
                        fontWeight: 600,
                    }}
                    color="primary"
                    onClick={() => {
                        stopRecording({ discard: false });
                    }}
                    data-analytics-id={`${analyticsIdText}-stop-recording`}
                >
                    <img src={audioWave} alt="transcribing" style={{ height: '20px', marginRight: '5px' }} />
                    End Recording
                </Button>
            );
        }

        return (
            <IconButton
                sx={{
                    color: '#6F6F79',
                    bgcolor: 'transparent',
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                onClick={() => {
                    checkPermission();
                }}
                data-analytics-id={`${analyticsIdText}-start-recording`}
            >
                <MicrophoneIcon className={classes.micIcon} />
            </IconButton>
        );
    };

    const handleAllowMicClick = () => {
        toggleShowActivateMicConfirmation(false);
        requestPermission();
    };

    if (showActivateMicConfirmation) {
        return <ActivateMicConfirmation showConfirmation={showActivateMicConfirmation} onAllow={handleAllowMicClick} />;
    }

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <CustomTextField
                    label={label}
                    placeholder={placeholder}
                    value={text}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={(newText: string) => {
                        onChange(newText);
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        right: pxToRem(5),
                        bottom: pxToRem(20),
                    }}
                >
                    {renderRecordButton()}
                </Box>
            </Box>
            {displayTranscriptionButton && (
                <Box
                    sx={{
                        bgcolor: (theme) => theme.palette.grey[200],
                        px: pxToRem(10),
                        py: pxToRem(6),
                        mb: pxToRem(18),
                        borderLeft: (theme) => `2px solid ${theme.palette.grey[300]}`,
                    }}
                >
                    <Typography
                        align="left"
                        sx={{
                            fontSize: `${pxToRem(11.5)} !important`,
                            lineHeight: 1.3,
                        }}
                    >
                        You can document your note by speaking to the phone (in English or other languages)!{' '}
                        <Box component="span" fontWeight="bold">
                            Click the green microphone button to start.
                        </Box>{' '}
                        Make sure to{' '}
                        <Box component="span" fontWeight="bold">
                            double check
                        </Box>{' '}
                        the text is correct before submitting.
                    </Typography>
                </Box>
            )}
        </>
    );
};

const mapStateToProps = ({ language, users }: ReduxStore) => {
    const { dictionary } = language;
    const { user } = users;
    return {
        dictionary,
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUser: (userId: number) => dispatch(readUser(userId)),
    dispatchThrowError: (error) =>
        dispatch(
            handleError({
                alertMessage: error,
                consoleMessage: error,
                error,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptionTextField);
