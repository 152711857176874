import { useMediaQuery } from '@mui/material';
import React from 'react';

import { SelectStructure } from 'types/inputs';

import { CustomSelect } from 'components/Custom';

import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from '../constants';

type Props = {
    currentBranch: string;
    onChange: (newShift: string) => void;
    disabled?: boolean;
    branchesOptions: SelectStructure[];
};

const BranchSelector = ({ currentBranch, onChange, disabled, branchesOptions }: Props) => {
    const isMobile = useMediaQuery('(max-width:1024px)');
    return (
        <CustomSelect
            id="Branch"
            label="Community"
            value={currentBranch}
            fullWidth
            options={branchesOptions}
            onChange={onChange}
            disabled={disabled}
            sx={isMobile ? { ...MOBILE_SELECTOR_STYLES, ...SELECTOR_STYLES } : SELECTOR_STYLES}
            truncate
        />
    );
};

export default BranchSelector;
