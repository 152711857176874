import { Box, useMediaQuery } from '@mui/material';
import React from 'react';

import { OperationsLevel, OperationsPeriod } from 'types/operationsDashboard';

import { pxToRem } from 'components/theme/typography';
import { BRANCH_SECTIONS_MAP, OVERALL_SECTIONS_MAP, Section } from 'pages/OperationsDashboard/constants';

import MobileDashboardTabPanels from '../Mobile/MobileDashbardTabPanels';
import { SectionDataModule } from '../Module/SectionDataModule';

type Props = {
    mobileTabSelected: 0 | 1;
    region: string;
    branch: string;
    operationsPeriod: OperationsPeriod;
    hasCompanyAccess: boolean;
    hasRegions: boolean;
    onMobileTabChange: (newValue: number) => void;
};

export const OperationsDashboardMain = ({
    mobileTabSelected,
    region,
    branch,
    operationsPeriod,
    hasCompanyAccess,
    hasRegions,
    onMobileTabChange,
}: Props) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    let level: OperationsLevel;

    if (hasRegions && hasCompanyAccess && region === 'all') {
        level = 'company';
    } else if (hasRegions && branch === 'all') {
        level = 'region';
    } else {
        level = 'branch';
    }

    return (
        <Box
            sx={{
                minHeight: '100vh',
                backgroundColor: { xs: 'common.white', lg: 'app.grey.main' },
                mt: { xs: pxToRem(160), lg: 0 },
                p: { lg: pxToRem(32) },
            }}
        >
            {isMobile ? (
                <MobileDashboardTabPanels
                    mobileTabSelected={mobileTabSelected}
                    onMobileTabChange={onMobileTabChange}
                    region={region}
                    branch={branch}
                    operationsPeriod={operationsPeriod}
                    level={level}
                />
            ) : (
                <Box
                    sx={{
                        display: 'grid',
                        gap: pxToRem(32),
                    }}
                >
                    {Object.keys(level === 'branch' ? BRANCH_SECTIONS_MAP : OVERALL_SECTIONS_MAP).map((section) => (
                        <SectionDataModule
                            key={section}
                            dataType={section as Section}
                            level={level}
                            operationsPeriod={operationsPeriod}
                            regionId={region}
                            branchId={branch}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};
