import React, { useEffect, useState } from 'react';

import { BranchesByRegionResponseBranch, BranchesByRegionResponseRegion } from 'types/companyInfo';
import { SelectStructure } from 'types/inputs';
import { OperationsPeriod } from 'types/operationsDashboard';

import { OperationsDashboardHeader } from './components/OperationsDashboardHeader';
import { OperationsDashboardMain } from './components/OperationsDashboardMain';
import { useBranchesByRegionQuery } from 'api/queries/companyInfo';
import Loading from 'components/Shared/Loading';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

export const Operations = () => {
    const [region, setRegion] = useState<string>('all');
    const [branch, setBranch] = useState<string>('all');
    const [operationsPeriod, setOperationsPeriod] = useState<OperationsPeriod>('week');
    const [mobileTabSelected, setMobileTabSelected] = useState<0 | 1>(0);

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionDataIsLoading,
        isError: branchesByRegionDataIsError,
    } = useBranchesByRegionQuery();

    const accessibleRegions: BranchesByRegionResponseRegion[] | [] =
        branchesByRegionData?.regions.filter(({ hasAccess }) => hasAccess) || [];

    const accessibleBranches: BranchesByRegionResponseBranch[] =
        branchesByRegionData?.branches.filter(({ hasAccess }) => hasAccess) || [];

    useEffect(() => {
        if (!branchesByRegionData || branchesByRegionDataIsLoading) {
            return;
        }
        if (branchesByRegionData?.hasCompanyAccess && accessibleRegions.length > 0) {
            setBranch('all');
            setRegion('all');
        } else if (accessibleRegions.length > 0) {
            setBranch('all');
            setRegion(accessibleRegions[0].regionId.toString());
        } else {
            setBranch(accessibleBranches[0].branchId.toString());
            if (accessibleBranches[0].regionId) {
                setRegion(accessibleBranches[0].regionId.toString());
            }
        }
    }, [branchesByRegionData]);

    if (branchesByRegionDataIsLoading) {
        return <Loading />;
    }

    if (branchesByRegionDataIsError || !branchesByRegionData) {
        return null;
    }

    const { hasCompanyAccess } = branchesByRegionData;

    let regionOptions: SelectStructure[] =
        hasCompanyAccess && accessibleRegions.length > 0 ? [{ label: 'All', value: 'all' }] : [];
    if (accessibleRegions.length > 0) {
        regionOptions = [
            ...regionOptions,
            ...accessibleRegions.map((item) => ({
                label: item.regionName,
                value: item.regionId.toString(),
            })),
        ];
    }

    let branchesOptions: SelectStructure[] = accessibleBranches.length > 1 ? [{ label: 'All', value: 'all' }] : [];
    if (region !== 'all') {
        branchesOptions = [
            ...branchesOptions,
            ...accessibleBranches
                .filter((item) => item.regionId === Number(region))
                .map((item) => ({
                    label: item.branchName,
                    value: item.branchId.toString(),
                })),
        ];
    } else {
        branchesOptions = [
            ...branchesOptions,
            ...accessibleBranches.map((item) => ({
                label: item.branchName,
                value: item.branchId.toString(),
            })),
        ];
    }

    const handleTabChange = (newValue: 0 | 1) => {
        setMobileTabSelected(newValue);
    };

    return (
        <PageStructure>
            <OperationsDashboardHeader
                region={region}
                branch={branch}
                operationsPeriod={operationsPeriod}
                onRegionChange={setRegion}
                onBranchChange={setBranch}
                onPeriodChange={setOperationsPeriod}
                mobileTabSelected={mobileTabSelected}
                onMobileTabChange={handleTabChange}
                regionOptions={regionOptions}
                branchesOptions={branchesOptions}
                hasCompanyAccess={hasCompanyAccess}
            />
            <OperationsDashboardMain
                mobileTabSelected={mobileTabSelected}
                onMobileTabChange={handleTabChange}
                region={region}
                branch={branch}
                operationsPeriod={operationsPeriod}
                hasCompanyAccess={hasCompanyAccess}
                hasRegions={accessibleRegions.length > 0}
            />
        </PageStructure>
    );
};

export const OperationsDashboard = AccessControl(Operations);
