import { googleLogout } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { api } from 'api';

import { ErrorProps } from 'types/messages';
import { GoogleSignInParams, SessionData, SessionReadParams, StoredSession } from 'types/session';

import { APP_MAJOR_VERSION } from 'constants/appVersioning';
import { HOME_FILTERS, NAVIGATION, PROFILE } from 'constants/localStorage';
import { formatApiParams, getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import { updateFilters } from 'redux/actions/dashboards';
import { handleError } from 'redux/actions/messages';
import 'redux/reducers/session';

export const validateSession = () => async (dispatch, getState) => {
    try {
        const storedProfile: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string) || {};
        const { isSignedIn, sessionData = {} as SessionData } = storedProfile;

        dispatch({
            type: actionTypes.SESSION_VALIDATE_SESSION,
            payload: {
                isSignedIn,
                sessionData,
            },
        });

        if (isSignedIn) {
            // Get the current Branch Id.
            const { branchId = 0 } = sessionData;

            // Get the current Dashboard Filters.
            const {
                dashboards: { filters },
            } = getState();

            // Update the Dashboard filters.
            const newFilters = {
                ...filters,
                branchId,
            };
            dispatch(updateFilters(newFilters));
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Signs In a user by using the Google API
 * @param jsonParams the JSON object with the params for signing-in a user.
 */
export const signIn =
    (jsonParams: SessionReadParams, redirectUrl: null | string = null, onError: null | (() => void) = null) =>
    async (dispatch, getState) => {
        try {
            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Sign-in the User with the provided credentials.
            const serverResponse: SessionData = (await api.post('sessions', params)).data.response;

            Sentry.setUser({
                id: serverResponse.userId.toString(),
                username: serverResponse.username,
            });

            try {
                window.freshpaint.identify(serverResponse.userId.toString(), {
                    username: serverResponse.username,
                });
            } catch (error) {
                Sentry.captureEvent(error);
            }

            // Define the values to send to Redux and the localStorage.
            const profile = {
                isSignedIn: true,
                sessionData: serverResponse,
            };

            // Store the session data into the localStorage.
            localStorage.setItem(PROFILE, JSON.stringify(profile));

            // Store the Session Data into Redux.
            dispatch({
                type: actionTypes.SESSION_SIGN_IN,
                payload: profile,
            });

            // Get the current Branch Id.
            const { branchId = 0 } = serverResponse;

            // Get the current Dashboard Filters.
            const {
                dashboards: { filters },
            } = getState();

            // Update the Dashboard filters.
            const newFilters = {
                ...filters,
                branchId,
            };
            dispatch(updateFilters(newFilters));

            const { majorAppVersion, ignoreAppVersion, userId } = serverResponse;
            if (APP_MAJOR_VERSION < majorAppVersion && ignoreAppVersion === false) {
                Sentry.captureMessage(`App Version Mismatch for ${userId}. Current version is ${APP_MAJOR_VERSION}`);
                window.location.reload();
            }

            if (redirectUrl) {
                window.location.replace(redirectUrl);
            }
        } catch (error) {
            // Get the Error dictionary from the language state.
            const {
                language: {
                    dictionary: { error: errorDictionary },
                },
            } = getState();

            const errorCode = error.response.data.code;
            const errorShort = error.response.data.short;
            let errorMessage = errorDictionary.missingCredentials;

            if (errorCode === 401 && errorShort === 'mismatch') {
                errorMessage = errorDictionary.crendentialsMismatch;
            } else if (errorCode === 401 && errorShort === 'inactive') {
                errorMessage = errorDictionary.inactiveUser;
            } else if (errorCode === 404) {
                errorMessage = errorDictionary.userNotFound;
            }

            const errorProps: ErrorProps = {
                error,
                consoleMessage: errorMessage,
                alertMessage: errorMessage,
            };

            dispatch(handleError(errorProps));
            if (onError) {
                onError();
            }
        }
    };

/**
 * Signs In a user by using the Google API
 * @param {Object} userData
 */
export const googleSignIn = (jsonParams: GoogleSignInParams) => async (dispatch, getState) => {
    try {
        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Sign-in the User with the Gmail account.
        const serverResponse: SessionData = (await api.post('sessions/goauth/signig', params)).data.response;

        // Define the values to send to Redux and the localStorage.
        const profile = {
            isSignedIn: true,
            sessionData: serverResponse,
        };

        localStorage.setItem(PROFILE, JSON.stringify(profile));

        // Store the Session Data into Redux.
        dispatch({
            type: actionTypes.SESSION_SIGN_IN,
            payload: profile,
        });

        // Get the current Branch Id.
        const { branchId = 0 } = serverResponse;

        // Get the current Dashboard Filters.
        const {
            dashboards: { filters },
        } = getState();

        // Update the Dashboard filters.
        const newFilters = {
            ...filters,
            branchId,
        };

        dispatch(updateFilters(newFilters));
    } catch (error) {
        // Get the Error dictionary from the language state.
        const {
            language: {
                dictionary: { error: errorDictionary },
            },
        } = getState();

        const errorCode = error.response.data.code;
        const errorShort = error.response.data.short;
        let errorMessage = errorDictionary.missingCredentials;

        if (errorCode === 401 && errorShort === 'inactive') {
            errorMessage = errorDictionary.inactiveUser;
        } else if (errorCode === 404) {
            errorMessage = errorDictionary.userNotFound;
        }

        const errorProps: ErrorProps = {
            error,
            consoleMessage: errorMessage,
            alertMessage: errorMessage,
        };

        dispatch(handleError(errorProps));
    }
};

/**
 * Signs Out a user.
 */
export const signOut = () => async (dispatch, getState) => {
    try {
        // Get the token.
        const token = getToken();

        // Sign-out the User.
        await api.delete('sessions', {
            headers: {
                authorization: token,
            },
        });

        googleLogout();
    } catch (error) {
        const errorMessage = error.response.data.error;

        // Log the error into the console.
        console.error(`${errorMessage}: ${error}`);
    } finally {
        // Destroy the session locally.
        const profile: StoredSession = {
            isSignedIn: false,
            sessionData: {} as SessionData,
        };

        localStorage.removeItem(PROFILE);
        localStorage.removeItem(HOME_FILTERS);
        localStorage.removeItem(NAVIGATION);

        dispatch({
            type: actionTypes.SESSION_SIGN_OUT,
            payload: profile,
        });

        Sentry.setUser(null);
    }

    window.location.href = '/login';
};

export const updateTimezone = (timezone: string) => ({
    type: actionTypes.SESSION_UPDATE_TIMEZONE,
    payload: timezone,
});

export const updatePriorParentPage = (newParentPage: string) => {
    const navigation = {
        parentPage: newParentPage,
    };

    // Save the new parent page into the storage.
    localStorage.setItem(NAVIGATION, JSON.stringify(navigation));

    return {
        type: actionTypes.SESSION_UPDATE_PRIOR_PARENT_PAGE,
        payload: newParentPage,
    };
};
