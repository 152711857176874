import {
    ArrowBack as ArrowBackAndroidIcon,
    ArrowBackIos as ArrowBackIosIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import {
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { languageOptions } from 'languages';
import { camelCase, isNull } from 'lodash';
import moment from 'moment-timezone';
import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { GoSignOut } from 'react-icons/go';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageStructure, Languages } from 'types/language';
import { LocationState } from 'types/redux';
import { StoredSession } from 'types/session';
import { UserRoleId, UserRoleNames } from 'types/users';

import { useRewardsSummaryQuery } from 'api/queries/caregiverRewards';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';
import {
    GREETING_DATE_FORMAT,
    MENU_MAP,
    MENU_MAP_ALL_PAGES,
    MOBILE_BOTTOM_MENU_OPTIONS,
    MOBILE_MENU_OPTIONS,
    OPERATIONS_SNAPSHOTS_PATH,
} from 'constants/app';
import { NAVIGATION, PROFILE } from 'constants/localStorage';
import { ROLE_VALUES, USER_ROLES } from 'constants/users';
import { getInitials } from 'lib/common';
import { getPathTitle, isSubpage } from 'lib/routes';
import { toggleLanguage } from 'redux/actions/language';
import { updateLocation } from 'redux/actions/location';
import { signOut, updatePriorParentPage } from 'redux/actions/session';

import DatePickerContainer from './DatePickerContainer';
import { ListItemSupportStyle, PrivacyStyle } from './DesktopSideMenu';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    icon: {
        marginRight: pxToRem(4),
        display: 'inline-flex',
        color: theme.palette.app.green.main,
        fontSize: pxToRem(20),
    },
    topIcon: {
        color: '#FCD7B1',
        fontSize: pxToRem(32),
    },
    privacyText: {
        fontSize: `${pxToRem(14)} !important`,
        textDecoration: 'underline',
        paddingLeft: pxToRem(4),
    },
    supportText: {
        fontSize: `${pxToRem(14)} !important`,
    },
}));

const RootStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        maxWidth: { xs: '100%', xl: pxToRem(1680) },
        zIndex: 11,
    })
);

const MobileHeaderContainer = styled(Paper)(({ theme }) =>
    theme.unstable_sx({
        display: { xs: 'flex', lg: 'none' }, // style that shows the mobile header
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        paddingTop: 'env(safe-area-inset-top)',
        width: '100%',
        boxShadow: 'none',
        borderBottomStyle: 'none',
        borderRadius: 0,
        backgroundColor: '#006B75',
        color: '#fff',
    })
);

const MobileHeaderTitleStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
    })
) as typeof Typography;

const MobileHeaderRewardsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        borderColor: theme.palette.grey[200],
        borderStyle: 'solid',
        borderWidth: pxToRem(1),
        borderRadius: pxToRem(100),
        marginRight: pxToRem(12),
        paddingLeft: pxToRem(8),
        paddingRight: pxToRem(8),
        color: '#FCD7B1',
    })
);

const MobileRewardsAmountStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: pxToRem(22),
        marginLeft: pxToRem(2),
        color: '#FCD7B1',
    })
) as typeof Typography;

const MobileMenuContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 'env(safe-area-inset-top)',
        width: pxToRem(250),
        height: '100%',
    })
);

const MobileGreetingContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(16),
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.app.green.main,
        width: '100%',
    })
);

const AvatarStyle = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(16),
        color: theme.palette.common.black,
        backgroundColor: theme.palette.app.grey.main,
        width: pxToRem(40),
        height: pxToRem(40),
    })
);

const MobileGreetingBoxStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    })
);

const MobileListContainer = styled(List)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    })
);

const MobileListItemIcon = styled(ListItemIcon)(({ theme }) =>
    theme.unstable_sx({
        minWidth: pxToRem(32),
    })
);

const MobileNavigationContainer = styled(Paper)(({ theme }) =>
    theme.unstable_sx({
        display: { xs: 'flex', lg: 'none' },
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 'auto',
        boxShadow: '0px -6px 40px rgba(0, 0, 0, 0.15)',
        padding: '6px 0 calc(env(safe-area-inset-bottom) - 12px) 0',
        borderRadius: 0,
    })
);

const MobileBottomNavigation = styled(BottomNavigation)(({ theme }) =>
    theme.unstable_sx({
        backgroundColor: '#fff',
    })
);

type Props = {
    dictionary: LanguageStructure;
    isSignedIn: boolean;
    languageId: Languages;
    priorParentPage: string;
    timezone: string;
    totalLanguages: number;
    userRole: UserRoleNames;
    userRoleId: UserRoleId;
    userId: number;
    dispatchSignOut: () => void;
    dispatchToggleLanguage: (languageId: Languages) => void;
    dispatchUpdateLocation: (location: LocationState) => void;
    dispatchUpdatePriorParentPage: (parentPage: string) => void;
};

const MobileMenu = (props: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation() || {};
    const { pathname } = location;
    const {
        dictionary,
        dictionary: { login, navigator },
        isSignedIn,
        languageId,
        priorParentPage,
        timezone,
        totalLanguages,
        userRole,
        userRoleId,
        userId,
        dispatchSignOut,
        dispatchToggleLanguage,
        dispatchUpdateLocation,
        dispatchUpdatePriorParentPage,
    } = props;
    const [selectedOption, setSelectedOption] = useState<string>();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [greetingLabel, setGreetingLabel] = useState<string>();
    const [greetingDate, setGreetingDate] = useState<string>();
    const pageTitle = getPathTitle(dictionary, pathname);
    const BackIcon = isIOS ? ArrowBackIosIcon : ArrowBackAndroidIcon;

    // Get the user name from the stored data.
    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const { firstName, picture: userPicture, lastName } = storedSession?.sessionData || {};
    const userInitials = getInitials(firstName, lastName);

    const pagesWithDateRangeSelector = [OPERATIONS_SNAPSHOTS_PATH];
    const shouldShowDateRange = !!pagesWithDateRangeSelector.find(
        (page) =>
            pathname.includes(page) &&
            !pathname.includes('resident-details') &&
            !pathname.includes('operations-snapshots')
    );

    let currentLanguageIndex = languageOptions.findIndex((opt) => opt.id === languageId);

    const handleGoBackClick = () => {
        navigate(priorParentPage);
    };

    const navigateToPage = (newPage: string) => {
        // Store the current parent page.
        dispatchUpdatePriorParentPage(pathname);

        // Set the new selected option (new page).
        setSelectedOption(newPage);

        // Move to the desired page.
        navigate(MENU_MAP_ALL_PAGES[newPage]);
    };

    const handleMenuToggle = (isMenuOpen: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (isMenuOpen) {
            moment.locale(languageId);
            const currentDateTime = moment.tz(new Date(), timezone);
            const dateFormatted = currentDateTime.format(GREETING_DATE_FORMAT);
            const currentHour = Number(currentDateTime.format('HH'));
            let newGreeting = navigator.goodAfternoon;

            if (currentHour < 12) {
                newGreeting = navigator.goodMorning;
            } else if (currentHour >= 19) {
                newGreeting = navigator.goodEvening;
            }

            setGreetingLabel(newGreeting);
            setGreetingDate(dateFormatted);
        }

        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setMenuOpen(isMenuOpen);
    };

    const handleMenuChange = (event, newOption: string) => {
        setSelectedOption(newOption);

        // Move to the selected page.
        navigate(MENU_MAP[newOption]);
    };

    const navigateToMyRewards = () => {
        handleMenuChange(null, 'myRewards');
    };

    const handleLanguageToggle = () => {
        currentLanguageIndex++;
        const index = currentLanguageIndex % totalLanguages;
        const newLanguage: Languages = languageOptions[index].id;

        // Update the new Languga into Redux.
        dispatchToggleLanguage(newLanguage);
    };

    const handleSignOut = () => {
        dispatchSignOut();
    };

    useEffect(() => {
        if (isSubpage(pathname) && !priorParentPage) {
            const storedNavigation = JSON.parse(localStorage.getItem(NAVIGATION) as string);
            const newPriorParentPage = storedNavigation?.parentPage || MENU_MAP.home;
            dispatchUpdatePriorParentPage(newPriorParentPage);
        }

        let newSelectedOption = '';

        if (!isSubpage(pathname)) {
            const pathParts = pathname.split('/');

            newSelectedOption = camelCase(pathParts[pathParts.length - 1]);
        }

        setSelectedOption(newSelectedOption);

        dispatchUpdateLocation(location);
    }, [pathname]);

    const { data: rewardsSummaryData } = useRewardsSummaryQuery(userId);

    if (!isSignedIn || pathname.includes('/privacy-policy')) {
        return null;
    }

    return (
        <RootStyle>
            <MobileHeaderContainer>
                {isSubpage(pathname) && (
                    <IconButton size="large" aria-haspopup="false" onClick={handleGoBackClick} color="inherit">
                        <BackIcon />
                    </IconButton>
                )}
                {!isSubpage(pathname) && (
                    <IconButton
                        size="large"
                        aria-controls="appbar-menu"
                        aria-haspopup="true"
                        onClick={handleMenuToggle(true)}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <MobileHeaderTitleStyle variant="subtitle1">{pageTitle}</MobileHeaderTitleStyle>
                {userRole === USER_ROLES.CAREGIVER && (
                    <MobileHeaderRewardsContainer onClick={navigateToMyRewards}>
                        <Box
                            sx={{
                                width: pxToRem(20),
                                display: 'flex',
                            }}
                        >
                            <GoldCoin className={classes.topIcon} size="100%" viewBox="0 0 20 20" />
                        </Box>
                        <MobileRewardsAmountStyle variant="caption" component="p">
                            {!rewardsSummaryData ? '' : rewardsSummaryData.totalPoints}
                        </MobileRewardsAmountStyle>
                    </MobileHeaderRewardsContainer>
                )}
                {shouldShowDateRange && <DatePickerContainer showFullDateRange={false} />}
                <Drawer anchor="left" open={menuOpen} onClose={handleMenuToggle(false)}>
                    <MobileMenuContainer
                        role="presentation"
                        onClick={handleMenuToggle(false)}
                        onKeyDown={handleMenuToggle(false)}
                    >
                        <MobileGreetingContainer>
                            <AvatarStyle alt={`${firstName} ${lastName}`} src={userPicture}>
                                {userInitials}
                            </AvatarStyle>
                            <MobileGreetingBoxStyle>
                                <Typography variant="subtitle1">{greetingLabel}</Typography>
                                <Typography variant="body2">{greetingDate}</Typography>
                            </MobileGreetingBoxStyle>
                        </MobileGreetingContainer>
                        <MobileListContainer>
                            {MOBILE_MENU_OPTIONS.map((option) => {
                                if (
                                    (option.id !== 'language' || totalLanguages > 1) &&
                                    (isNull(option.visibleFor) || option.visibleFor?.includes(userRoleId))
                                ) {
                                    return (
                                        <ListItem key={option.id} disablePadding>
                                            <ListItemButton>
                                                <MobileListItemIcon>
                                                    {option.icon && <option.icon className={classes.icon} />}
                                                </MobileListItemIcon>
                                                {option.id === 'language' && (
                                                    <ListItemText
                                                        primary={navigator[option.id]}
                                                        onClick={handleLanguageToggle}
                                                    />
                                                )}
                                                {option.id !== 'language' && (
                                                    <ListItemText
                                                        primary={navigator[option.id]}
                                                        onClick={() => navigateToPage(option.id)}
                                                    />
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                }
                                return null;
                            })}
                        </MobileListContainer>
                        <ListItemSupportStyle>
                            {navigator.supportEmail}
                            <PrivacyStyle
                                className={classes.privacyText}
                                component="a"
                                href="/privacy-policy"
                                target="_blank"
                            >
                                {navigator.privacy}
                            </PrivacyStyle>
                        </ListItemSupportStyle>
                        <Divider />
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleSignOut}>
                                    <MobileListItemIcon>
                                        <GoSignOut className={classes.icon} />
                                    </MobileListItemIcon>
                                    <ListItemText primary={login.signOut} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </MobileMenuContainer>
                </Drawer>
            </MobileHeaderContainer>
            <MobileNavigationContainer>
                <MobileBottomNavigation showLabels value={selectedOption} onChange={handleMenuChange}>
                    {MOBILE_BOTTOM_MENU_OPTIONS.map((option) => {
                        if (isNull(option.visibleFor) || option.visibleFor?.includes(userRoleId)) {
                            return (
                                <BottomNavigationAction
                                    key={option.id}
                                    label={
                                        option.id === 'residents' && userRoleId !== 5
                                            ? 'Resident Deep Dive'
                                            : navigator[option.id]
                                    }
                                    icon={option.icon ? <option.icon /> : undefined}
                                    value={option.id}
                                />
                            );
                        }
                        return null;
                    })}
                </MobileBottomNavigation>
            </MobileNavigationContainer>
        </RootStyle>
    );
};

const mapStateToProps = ({ language, session }) => {
    const { id: languageId, dictionary, totalLanguages } = language;
    const {
        isSignedIn,
        priorParentPage,
        timezone,
        sessionData: { roleLevelId, userId },
    } = session;

    return {
        dictionary,
        isSignedIn,
        languageId,
        priorParentPage,
        timezone,
        totalLanguages,
        userRole: ROLE_VALUES[roleLevelId],
        userRoleId: roleLevelId,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchSignOut: () => dispatch(signOut()),
    dispatchToggleLanguage: (languageId: Languages) => dispatch(toggleLanguage(languageId)),
    dispatchUpdateLocation: (location: LocationState) => dispatch(updateLocation(location)),
    dispatchUpdatePriorParentPage: (parentPage: string) => dispatch(updatePriorParentPage(parentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
