import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React from 'react';

import { SectionDetail } from 'types/operationsDashboard';

export const formatDateTime = (dateString: string) => {
    const date = parseISO(dateString);

    return format(date, 'MM/dd, h:mm a');
};

export const formatDay = (dateString: string) => {
    const date = parseISO(dateString);

    return format(date, 'EEE, MMM d');
};

export const DETAIL_ITEM = {
    CHANGE_OF_CONDITION: (item: SectionDetail) => (
        <>
            <Box>{item.type}</Box>
            <Box>{item.value}</Box>
        </>
    ),
    FALLS: (item: SectionDetail) => (
        <>
            <Box>{formatDateTime(item.fallDateTime)}</Box>
            <Box>{item.last30FallCount} in previous 30d</Box>
            <Box>{item.reporterName}</Box>
        </>
    ),
    SHOWER: (item: SectionDetail) => (
        <>
            <Box>{formatDay(item.date)}</Box>
            <Box>{item.reason}</Box>
            <Box>{item.caregiverName}</Box>
        </>
    ),
    UNSCHEDULED_CARE: (item: SectionDetail) => (
        <>
            <Box>{item.prnName}</Box>
            <Box>{item.caregiver}</Box>
            <Box>{formatDateTime(item.prnDateTime)}</Box>
        </>
    ),
    RESIDENT_NOTES: (item: SectionDetail) => (
        <>
            <Box>{item.note}</Box>
        </>
    ),
};

export const DYNAMIC_COLUMNS = {
    CHANGE_OF_CONDITION: [
        {
            field: 'total',
            headerName: 'Total Changes',
        },
    ],
    FALLS: [
        {
            field: 'total',
            headerName: 'Total Falls',
        },
        {
            field: 'last30FallCount',
            headerName: 'Multiple in 30d',
        },
    ],
    SHOWER: [
        {
            field: 'total',
            headerName: 'Total not shower',
        },
    ],
    HOSPITAL_TRANSFER: [
        {
            field: 'date',
            headerName: 'Date',
        },
    ],
    FALL_DOCUMENTATION_INCOMPLETE: [
        {
            field: 'fallDateTime',
            headerName: 'Time',
        },
        {
            field: 'reporter',
            headerName: 'Reporter',
        },
    ],
    OVERDUE_ASSESSMENT: [
        {
            field: 'overdueDate',
            headerName: 'Expiration Date',
        },
    ],
    UNSCHEDULED_CARE: [
        {
            field: 'total',
            headerName: 'Total PRNs',
        },
    ],
    RESIDENT_NOTES: [
        {
            field: 'dateTime',
            headerName: 'Date',
        },
        {
            field: 'category',
            headerName: 'Category',
        },
        {
            field: 'reporterName',
            headerName: 'Caregiver',
        },
    ],
};

export type DynamicColumnSection = keyof typeof DYNAMIC_COLUMNS;

export const TOTAL_ROW_LABEL = {
    CHANGE_OF_CONDITION: 'change',
    FALLS: 'fall',
    SHOWER: 'time',
    UNSCHEDULED_CARE: 'PRN',
};

export const EXTRA_COLLAPSIBLE_ROW_COLUMNS = {
    RESIDENT_NOTES: ['dateTime', 'category', 'reporterName'],
    FALLS: ['last30FallCount'],
};

export const DETAILS_COLUMNS_PROPS = {
    CHANGE_OF_CONDITION: ['type', 'value'],
    FALLS: ['fallDateTime', 'last30FallCount', 'reporterName'],
    SHOWER: ['date', 'reason', 'caregiverName'],
    UNSCHEDULED_CARE: ['prnName', 'caregiver', 'prnDateTime'],
    RESIDENT_NOTES: ['note', 'dateTime', 'category', 'reporterName'],
};

export const DATATYPES_WITH_COLLAPSIBLE_ROWS = [
    'CHANGE_OF_CONDITION',
    'FALLS',
    'SHOWER',
    'UNSCHEDULED_CARE',
    'RESIDENT_NOTES',
];
