import { AccessTimeOutlined as ClockIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import moment from 'moment';
import React from 'react';

import { CustomDate } from 'types/common';
import { DashboardNotesResponse, DashboardUnscheduledTasksResponse } from 'types/dashboard';
import { LanguageProperty } from 'types/language';
import { Column, Row } from 'types/table';

import { pxToRem } from 'components/theme/typography';
import { US_DATE_FORMAT, US_DATE_SHORT_FORMAT } from 'constants/app';
import { DATE_RANGE_ERROR_REASONS } from 'constants/dashboards';
import { compareDates, formatDateTimeShortUS } from 'lib/common';

export const displayDateRange = (startDate: CustomDate, endDate: CustomDate, showShortDate?: boolean) => {
    const dateFormat = showShortDate ? US_DATE_SHORT_FORMAT : US_DATE_FORMAT;

    const startDateFormatted = moment(startDate).format(dateFormat);
    const endDateFormatted = moment(endDate).format(dateFormat);
    const areEquals = compareDates(startDateFormatted, 'isSame', endDateFormatted);

    if (areEquals) {
        return startDateFormatted;
    }

    return `${startDateFormatted} - ${endDateFormatted}`;
};

export const validateDate = (
    errorReason: DateValidationError,
    dateLabel: string,
    dictionary: LanguageProperty,
    maxDate: Date | null,
    minDate: Date | null
) => {
    switch (errorReason) {
        case DATE_RANGE_ERROR_REASONS.disableFuture:
            return `${dateLabel} ${dictionary.futureDateError}`;
        case DATE_RANGE_ERROR_REASONS.disablePast:
            return `${dateLabel} ${dictionary.pastDateError}`;
        case DATE_RANGE_ERROR_REASONS.invalidDate:
            return `${dateLabel} ${dictionary.invalidDateError}`;
        case DATE_RANGE_ERROR_REASONS.maxDate:
            return `${dateLabel} ${dictionary.maxDateError.replace('{DATE}', maxDate ? moment(maxDate).format() : '')}`;
        case DATE_RANGE_ERROR_REASONS.minDate:
            return `${dateLabel} ${dictionary.minDateError.replace('{DATE}', maxDate ? moment(minDate).format() : '')}`;
        default:
            return '';
    }
};

export const formatNotesColumns = (columns: Column[], timezone: string, dictionary: LanguageProperty): Column[] =>
    columns.map((column) => {
        const label = dictionary[column.id];
        let { content } = column;

        if (column.id === 'time') {
            content = (time) => (
                <>
                    <ClockIcon
                        sx={{
                            mr: pxToRem(8),
                            color: useTheme().palette.green.main,
                        }}
                    />
                    {formatDateTimeShortUS(time, timezone)}
                </>
            );
        }

        const newColumn: Column = {
            ...column,
            label,
            content,
        };

        return newColumn;
    });

export const formatUnscheduledTasksColumns = (
    columns: Column[],
    timezone: string,
    dictionary: LanguageProperty
): Column[] =>
    columns.map((column) => {
        const label = dictionary[column.id];
        let { content } = column;

        if (column.id === 'time') {
            content = (time) => (
                <>
                    <ClockIcon
                        sx={{
                            mr: pxToRem(8),
                            color: useTheme().palette.green.main,
                        }}
                    />
                    {formatDateTimeShortUS(time, timezone)}
                </>
            );
        }

        const newColumn: Column = {
            ...column,
            label,
            content,
        };

        return newColumn;
    });

export const formatNotesRows = (notes: DashboardNotesResponse[]): Row[] =>
    notes.map((noteData, index) => ({
        id: index,
        resident: {
            firstName: noteData.residentFirstName,
            lastName: noteData.residentLastName,
            fullname: noteData.residentName,
            photo: noteData.residentPhoto,
        },
        room: noteData.room,
        caregiver: noteData.caregiverName,
        time: noteData.noteTimestamp,
        submissionCategory: noteData.submissionCategory,
        note: noteData.note,
    }));

export const formatUnscheduledTasksRows = (unscheduledTasks: DashboardUnscheduledTasksResponse[]): Row[] =>
    unscheduledTasks.map((taskData, index) => ({
        id: index,
        resident: {
            firstName: taskData.residentFirstName,
            lastName: taskData.residentLastName,
            fullname: taskData.residentName,
            photo: taskData.residentPhoto,
        },
        room: taskData.room,
        caregiver: taskData.caregiverName,
        time: taskData.taskTimestamp,
        companyCareplanName: taskData.companyCareplanName,
    }));

export const formatPotentialContactsReportContent = (content: string, dictionary: LanguageProperty): string => {
    const regexExtract = content.match(/\[(.*?)\]/);
    let keyService = '';

    if (regexExtract) {
        keyService = regexExtract[1];
    }

    return content
        .replace('<<<refused>>>', dictionary.residentRefused)
        .replace(`[${keyService}]`, dictionary[keyService]);
};
