import { Box, Card, Divider, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';
import { ASSESSMENT_MODULES_STATUS, RowComponentProps, RowData } from 'pages/ResidentDashboard/constants';

import { ModuleContent } from './ModuleContent';
import { ModuleHeader } from './ModuleHeader';

type Props = {
    title: string;
    subtitle: string;
    timeRange?: string;
    hasTimeline?: boolean;
    status?: string;
    completeTasksCount?: number;
    totalTaskCount?: number;
    visitsCount?: number;
    fallsCount?: number;
    assistLevelChangesCount?: number;
    rowData: RowData[];
    RowComponent: React.ComponentType<RowComponentProps>;
    SeeAllButton?: React.ReactNode;
    Chart?: React.ReactNode;
    hasChart?: boolean;
    hasWeightChart?: boolean;
    tasksOrServicesCount?: number;
    medicationChangesCount?: number;
    weightAboveThresholdCount?: number;
    latestMeasurementDate?: string | null;
    order: number;
};

export const ResidentDashboardModule = ({
    title,
    subtitle,
    timeRange,
    hasTimeline,
    status,
    completeTasksCount,
    totalTaskCount,
    visitsCount,
    fallsCount,
    assistLevelChangesCount,
    rowData,
    RowComponent,
    SeeAllButton,
    Chart,
    hasChart,
    hasWeightChart,
    tasksOrServicesCount,
    medicationChangesCount,
    weightAboveThresholdCount,
    latestMeasurementDate,
    order,
}: Props) => {
    let badgeContent = '';
    let color = '';
    let icon: React.ReactElement = <></>;
    let iconBgColor = '';

    if (status && ASSESSMENT_MODULES_STATUS[status]) {
        ({ badgeContent, color, icon, iconBgColor } = ASSESSMENT_MODULES_STATUS[status]);
    }

    const isNeedsAttention = status === 'needs_attention';

    const calculateAmount = () => {
        if (visitsCount !== undefined) {
            return visitsCount;
        }
        if (tasksOrServicesCount !== undefined) {
            return tasksOrServicesCount;
        }
        if (fallsCount !== undefined) {
            return fallsCount;
        }
        if (assistLevelChangesCount !== undefined) {
            return assistLevelChangesCount;
        }
        if (medicationChangesCount !== undefined) {
            return medicationChangesCount;
        }
        if (weightAboveThresholdCount !== undefined) {
            return weightAboveThresholdCount;
        }
        if (totalTaskCount === undefined || completeTasksCount === undefined) {
            return '';
        }
        return `${completeTasksCount}/${totalTaskCount}`;
    };

    return (
        <Box order={order} display={{ lg: 'flex' }} flexDirection={{ lg: 'column' }}>
            <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Typography
                    fontSize={{ xs: pxToRem(18), md: pxToRem(20) }}
                    display="inline"
                    lineHeight={1.6}
                    textAlign="left"
                    whiteSpace="nowrap"
                >
                    {title}
                </Typography>
                <Typography
                    color="common.white"
                    textTransform="uppercase"
                    fontWeight={500}
                    bgcolor={color}
                    px={pxToRem(8)}
                    py={pxToRem(4)}
                    borderRadius={pxToRem(4)}
                    display="inline-block"
                    fontSize={{ xs: pxToRem(10), xl: pxToRem(12) }}
                    lineHeight={1.2}
                    minWidth="max-content"
                >
                    {badgeContent}
                </Typography>
                {SeeAllButton && SeeAllButton}
            </Box>

            <Card
                variant="outlined"
                sx={[
                    isNeedsAttention && { borderColor: color },
                    {
                        flex: { lg: 1 },
                        display: 'flex',
                        flexDirection: 'column',
                    },
                ]}
            >
                <ModuleHeader
                    amount={calculateAmount()}
                    icon={icon}
                    iconBgColor={iconBgColor}
                    subtitle={subtitle}
                    timeRange={timeRange}
                    latestMeasurementDate={latestMeasurementDate}
                />
                {Chart && Chart}
                <Divider />
                <ModuleContent
                    RowComponent={RowComponent}
                    rowData={rowData}
                    hasTimeline={hasTimeline}
                    hasChart={hasChart}
                    hasWeightChart={hasWeightChart}
                />
            </Card>
        </Box>
    );
};
