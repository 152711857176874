import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useSignupMutation } from 'api/queries/auth/signup';
import AuthButton from 'components/Auth/AuthButton';
import AuthCtaLink from 'components/Auth/AuthCtaLink';
import AuthInput from 'components/Auth/AuthInput';
import AuthTitle from 'components/Auth/AuthTitle';
import FormContainer from 'components/Auth/FormContainer';
import MainContainer from 'components/Auth/MainContainer';
import Return from 'components/Auth/Return';
import { pxToRem } from 'components/theme/typography';
import { EMAIL_REGEX } from 'constants/regex';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

export type SignupFormValues = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email?: string;
};

const defaultValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
};

const SignupPage = () => {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
    } = useForm<SignupFormValues>({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        delayError: 2000,
    });

    const { mutate: signupMutation } = useSignupMutation();

    const currentFirstName = watch('firstName').replace(/\s+/g, '').toLowerCase();
    const currentLastName = watch('lastName').replace(/\s+/g, '').toLowerCase();

    const currentUsername = currentFirstName && currentLastName ? `${currentFirstName}.${currentLastName}` : '';

    const onSubmit = (data: SignupFormValues) => {
        signupMutation({
            firstName: data.firstName,
            lastName: data.lastName,
            username: currentUsername,
            phoneNumber: data.phoneNumber,
            email: data.email || '',
        });
    };

    return (
        <PageStructure>
            <MainContainer>
                <FormContainer>
                    <Box>
                        <Return returnToUrl="login-new" />
                        <Box
                            sx={{
                                mt: {
                                    xs: pxToRem(20),
                                    lg: pxToRem(12),
                                },
                                mb: {
                                    xs: pxToRem(36),
                                },
                            }}
                        >
                            <AuthTitle title="User Information" subtitle="Set your profile" />
                        </Box>
                        <Stack spacing={2} sx={{ mb: pxToRem(44) }}>
                            <AuthInput
                                name="firstName"
                                label="First Name"
                                control={control}
                                placeholder="Enter first name"
                                type="text"
                                error={errors.firstName}
                                rules={{
                                    required: 'First Name is required',
                                    minLength: {
                                        value: 2,
                                        message: 'First Name must be at least 2 characters',
                                    },
                                }}
                            />
                            <Box>
                                <AuthInput
                                    name="lastName"
                                    label="Last Name"
                                    control={control}
                                    placeholder="Enter last name"
                                    type="text"
                                    error={errors.lastName}
                                    rules={{
                                        required: 'Last Name is required',
                                        minLength: {
                                            value: 2,
                                            message: 'Last Name must be at least 2 characters',
                                        },
                                    }}
                                />
                                {currentUsername && !errors.lastName && (
                                    <Box
                                        sx={{
                                            color: 'primary.dark',
                                            borderRadius: `0 0 ${pxToRem(8)} ${pxToRem(8)}`,
                                            px: pxToRem(16),
                                            pt: pxToRem(10),
                                            pb: pxToRem(4),
                                            bgcolor: '#F2F8F8',
                                            fontSize: pxToRem(14),
                                            lineHeight: 1.45,
                                            marginTop: pxToRem(-6),
                                        }}
                                    >
                                        Your username will be{' '}
                                        <Box
                                            component="span"
                                            sx={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            {currentUsername}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Box>
                                <AuthInput
                                    name="phoneNumber"
                                    label="Phone Number"
                                    control={control}
                                    placeholder="Enter Phone Number"
                                    type="text"
                                    error={errors.phoneNumber}
                                    rules={{
                                        required: 'Phone Number is required',
                                        minLength: {
                                            value: 8,
                                            message: 'Phone Number must be at least 8 characters',
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: '#6F6F79',
                                        fontSize: { xs: pxToRem(14) },
                                        lineHeight: { xs: 1.45 },
                                        marginTop: pxToRem(4),
                                    }}
                                >
                                    We will send you a verification code.
                                </Typography>
                            </Box>
                            <AuthInput
                                name="email"
                                label="Email (optional)"
                                control={control}
                                placeholder="Enter email"
                                type="text"
                                error={errors.email}
                                rules={{
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: 'Invalid email format',
                                    },
                                }}
                            />
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: pxToRem(12),
                            pb: { xs: pxToRem(30), lg: 0 },
                        }}
                    >
                        <AuthCtaLink
                            question="By clicking Sign up, I agree to the"
                            linkLabel="AllieHealth Privacy Policy"
                            linkUrl="privacy-policy"
                            isLink
                        />
                        <AuthButton label="Sign up" isDisabled={!isValid} onClick={handleSubmit(onSubmit)} />
                    </Box>
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export const Signup = AccessControl(SignupPage);
