import { Box, Card, Typography } from '@mui/material';
import React from 'react';

import { OperationsPeriod } from 'types/operationsDashboard';

import { pxToRem } from 'components/theme/typography';

import { ModuleSubtitle } from './ModuleSubtitle';

type Props = {
    overallStat: string;
    operationsPeriod: OperationsPeriod;
    sectionTitle: string;
    miniDisplay?: 'chart' | 'table';
    renderChart: () => JSX.Element;
    renderTable: () => JSX.Element;
    renderDateSelector: () => JSX.Element | null;
    onClick?: () => void;
};

export const SectionModule = ({
    overallStat,
    sectionTitle,
    miniDisplay,
    operationsPeriod,
    renderChart,
    renderTable,
    renderDateSelector,
    onClick,
}: Props) => {
    if (miniDisplay === 'table') {
        return (
            <>
                {renderDateSelector()}
                {renderTable()}
            </>
        );
    }

    return (
        <Box
            sx={{
                '&:not(:first-of-type)': {
                    mt: { lg: 0, xs: pxToRem(24) },
                },
            }}
            onClick={onClick}
        >
            <Typography
                sx={{
                    fontSize: { lg: pxToRem(20), xs: pxToRem(16) },
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    mb: pxToRem(14),
                }}
            >
                {sectionTitle}
            </Typography>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: '12px',
                    display: 'grid',
                    gridTemplateRows: 'min-content 1fr',
                }}
            >
                <ModuleSubtitle
                    amount={overallStat}
                    subtitle={
                        <Box component="span" sx={{ textTransform: 'lowercase' }}>
                            {sectionTitle}{' '}
                            {operationsPeriod === 'day' ? (
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    yesterday
                                </Box>
                            ) : (
                                'in '
                            )}
                            {operationsPeriod !== 'day' && (
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    last {operationsPeriod}
                                </Box>
                            )}
                        </Box>
                    }
                />
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { lg: `1fr minmax(400px, 1fr)` },
                    }}
                >
                    {(miniDisplay === 'chart' || !miniDisplay) && (
                        <Box
                            sx={
                                !miniDisplay
                                    ? {
                                          borderRight: '1px solid #ECECF3',
                                          p: pxToRem(20),
                                          display: { lg: 'grid', xs: 'block' },
                                          placeItems: 'center',
                                      }
                                    : {
                                          p: pxToRem(10),
                                          pb: pxToRem(5),
                                      }
                            }
                        >
                            {renderChart()}
                        </Box>
                    )}
                    {!miniDisplay && (
                        <Box
                            sx={{
                                p: pxToRem(20),
                            }}
                        >
                            {renderDateSelector()}
                            {renderTable()}
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};
