import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { pxToRem } from 'components/theme/typography';
import { Section, StandardDataset } from 'pages/OperationsDashboard/constants';
import { calculatePercentage } from 'pages/OperationsDashboard/helpers';

import CustomLineChartTooltip from './CustomLineChartTooltip';
import { useChartClick } from './helpers';

interface CustomLineChartProps {
    data: StandardDataset[];
    data2?: StandardDataset[];
    section: Section;
    setSelectedStartDate?: (date: string) => void;
    selectedStartDate?: string;
}

const prepareChartData = (data: StandardDataset[]) =>
    data.map((dataset) => ({
        period: dataset.startDate,
        value: dataset.value,
    }));

const CustomLineChart = ({ data, data2, section, setSelectedStartDate, selectedStartDate }: CustomLineChartProps) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const hasPercentage = section === 'DOCUMENTATION_RATE';

    const yAxisWidth = {
        mobile: {
            hasPercentage: 35,
            noPercentage: 25,
        },
        desktop: {
            hasPercentage: 45,
            noPercentage: 35,
        },
    };

    const getYAxisWidth = (mobile: boolean, percentage: boolean) => {
        if (mobile) {
            return percentage ? yAxisWidth.mobile.hasPercentage : yAxisWidth.mobile.noPercentage;
        }

        return percentage ? yAxisWidth.desktop.hasPercentage : yAxisWidth.desktop.noPercentage;
    };

    let chartData = prepareChartData(data);

    if (data2) {
        chartData = chartData.map((dataset, index) => ({
            ...dataset,
            value: calculatePercentage(dataset.value, data2[index].value),
        }));
    }

    const handlePointClick = !isMobile ? useChartClick(setSelectedStartDate) : undefined;

    return (
        <Box width={1} height={{ xs: pxToRem(158), lg: pxToRem(336) }}>
            <ResponsiveContainer width="99%" height="100%">
                <LineChart data={chartData} onClick={isMobile ? undefined : handlePointClick}>
                    <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.5} />
                    <XAxis
                        dataKey="period"
                        fontSize={isMobile ? 9 : 10}
                        fontFamily="Open Sans"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={7}
                        tickCount={8}
                        tickFormatter={(date) => {
                            const [, month, day] = date.split('-');
                            return `${month}/${day}`;
                        }}
                    />
                    <YAxis
                        dataKey="value"
                        fontSize={isMobile ? 10 : 12}
                        fontFamily="Open Sans"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={isMobile ? 3 : 6}
                        tickFormatter={(value) => `${value}${hasPercentage && value !== 0 ? '%' : ''}`}
                        width={getYAxisWidth(isMobile, hasPercentage)}
                    />
                    <Tooltip content={<CustomLineChartTooltip section={section} />} />
                    <Line
                        type="linear"
                        dataKey="value"
                        stroke="#006B75"
                        strokeWidth={2}
                        dot={{
                            r: 2,
                        }}
                        cursor="pointer"
                    />
                    {selectedStartDate && !isMobile && (
                        <ReferenceLine
                            x={selectedStartDate}
                            stroke="#C2DBDE"
                            ifOverflow="extendDomain"
                            strokeWidth={2}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CustomLineChart;
