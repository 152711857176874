import { Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    title: string;
    subtitle: string;
    align?: 'left' | 'center';
};

const AuthTitle = ({ title, subtitle, align = 'left' }: Props) => (
    <>
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(24),
                },
                lineHeight: {
                    xs: 1.2,
                },
                fontWeight: 700,
                color: '#2C2D3B',
                mb: { xs: pxToRem(4) },
                textAlign: align,
            }}
        >
            {title}
        </Typography>
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(14),
                },
                lineHeight: {
                    xs: 1.7,
                },
                color: '#6F6F79',
                textAlign: align,
            }}
        >
            {subtitle}
        </Typography>
    </>
);

export default AuthTitle;
