import { Box, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';
import { parseAndFormatDate } from 'pages/ResidentDashboard/helpers';

import { DateAndTime } from './DateAndTime';
import { Note } from './Note';

type Props = {
    row: MedicationChange;
};

export const MedAndInfectionRowContent = ({ row }: Props) => {
    const { status, medicationName, datetime, instructions } = row;

    const isRemoved = status === 'Removed';

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="baseline">
                <Typography
                    fontSize={{
                        xs: pxToRem(12),
                        md: pxToRem(14),
                    }}
                    fontWeight={700}
                    lineHeight={1.7}
                    color={isRemoved ? '#EC3232' : 'primary.main'}
                >
                    {status}
                </Typography>
                <DateAndTime timeStamp={parseAndFormatDate(datetime)} />
            </Box>
            <Typography
                fontSize={{
                    xs: pxToRem(12),
                    md: pxToRem(14),
                }}
                fontWeight={700}
                lineHeight={1.7}
            >
                {medicationName}
            </Typography>
            {instructions && <Note note={instructions} sx={{ mt: pxToRem(8) }} />}
        </>
    );
};
