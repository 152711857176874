import { dictionaryList, languageOptions } from 'languages';
import { useDispatch } from 'react-redux';

import { ShiftNotesCategoryResponse } from 'types/ShiftNotesCategories';
import { BranchResponse } from 'types/branches';
import { CompanyResponse } from 'types/companies';
import { CompanyCarePlanResponse } from 'types/companyCarePlans';
import { DailyTasksShiftPeriod } from 'types/dailyTasks';
import {
    DashboardAllTasksResponse,
    DashboardCountersResponse,
    DashboardFilters,
    DashboardIncompleteByCaregiversResponse,
    DashboardIncompletedOverdueByResidentsResponse,
    DashboardIncompletedOverdueTasksResponse,
    DashboardKeyServicesResponse,
    DashboardPerformanceResponse,
} from 'types/dashboard';
import { FiltersState } from 'types/filters';
import {
    AppDispatch,
    BranchesState,
    CompaniesState,
    CompanyCarePlansState,
    DailyTasksState,
    DashboardState,
    LanguageState,
    MyRewardsState,
    PhotoState,
    ResidentShiftNotesState,
    ResidentsState,
    SessionState,
    ShiftNotesCategoriesState,
    TaskStatusesState,
    UsersState,
    ZonesState,
} from 'types/redux';
import { ResidentShiftNotesResponse } from 'types/residentShiftNotes';
import { SessionData } from 'types/session';
import { TaskStatusesResponse } from 'types/taskStatuses';
import { ZoneResponse } from 'types/zones';

import { DEFAULT_TIMEZONE } from 'constants/app';

export const LANGUAGE_DEFAULT_STATE: LanguageState = {
    id: languageOptions[0].id,
    dictionary: dictionaryList[languageOptions[0].id],
    label: languageOptions[0].label,
    totalLanguages: 1,
};

export const SESSION_DEFAULT_STATE: SessionState = {
    isSignedIn: null,
    sessionData: {} as SessionData,
    timezone: DEFAULT_TIMEZONE,
};

export const COMPANIES_DEFAULT_STATE: CompaniesState = {
    loading: false,
    company: {} as CompanyResponse,
    companiesList: [],
};

export const BRANCHES_DEFAULT_STATE: BranchesState = {
    loading: false,
    branch: {} as BranchResponse,
    branchesList: [],
};

export const DAILY_TASKS_DEFAULT_STATE: DailyTasksState = {
    loading: false,
    dailyTasksList: [],
    shiftPeriod: {} as DailyTasksShiftPeriod,
};

export const USERS_DEFAULT_STATE: UsersState = {
    loading: false,
};

export const ZONES_DEFAULT_STATE: ZonesState = {
    loading: false,
    zone: {} as ZoneResponse,
    zonesList: [],
};

export const COMPANY_CARE_PLANS_DEFAULT_STATE: CompanyCarePlansState = {
    loading: false,
    companyCarePlan: {} as CompanyCarePlanResponse,
    companyCarePlansList: [],
};

export const MY_REWARDS_DEFAULT_STATE: MyRewardsState = {
    loading: false,
    detailedList: [],
    mostAboveAndBeyondBadge: false,
    mostCommunicateBadge: false,
    mostResidentServedBadge: false,
    sum: 0,
    topPerformanceBadge: false,
    totalAboveAndBeyond: 0,
    totalAddedTasks: 0,
    totalNotesSubmitted: 0,
    totalPoints: 0,
    totalResidentsNotesSubmitted: 0,
    totalResidentsServed: 0,
    totalResidentsWithAddedTasks: 0,
    totalResidentsWithScheduledTasks: 0,
    totalScheduledTasks: 0,
};

export const RESIDENTS_DEFAULT_STATE: ResidentsState = {
    displayParty: false,
};

export const INFO_REPORT_CATEGORIES_DEFAULT_STATE: ShiftNotesCategoriesState = {
    loading: false,
    shiftNotesCategoriesList: [],
    shiftNoteCategory: {} as ShiftNotesCategoryResponse,
};

export const RESIDENT_SHIFT_NOTESS_DEFAULT_STATE: ResidentShiftNotesState = {
    loading: false,
    residentShiftNotesList: [],
    residentShiftNotes: {} as ResidentShiftNotesResponse,
};

export const TASK_STATUSES_DEFAULT_STATE: TaskStatusesState = {
    loading: false,
    taskStatusesList: [],
    taskStatus: {} as TaskStatusesResponse,
};

export const PHOTO_DEFAULT_STATE: PhotoState = {
    loading: false,
};

export const DASHBOARD_DEFAULT_STATE: DashboardState = {
    allTasks: {} as DashboardAllTasksResponse,
    counters: {} as DashboardCountersResponse,
    fallenResidents: [],
    filters: {} as DashboardFilters,
    incompletedTasks: {} as DashboardIncompletedOverdueTasksResponse,
    incompletedByResidents: {} as DashboardIncompletedOverdueByResidentsResponse,
    incompletedByCaregivers: {} as DashboardIncompleteByCaregiversResponse,
    keyServices: {} as DashboardKeyServicesResponse,
    loading: false,
    notes: [],
    unscheduledTasks: [],
    overdueTasks: {} as DashboardIncompletedOverdueTasksResponse,
    overdueByResidents: {} as DashboardIncompletedOverdueByResidentsResponse,
    overdueByCaregivers: {} as DashboardIncompleteByCaregiversResponse,
    performance: {} as DashboardPerformanceResponse,
    potentialContacts: [],
};

export const FILTERS_DEFAULT_STATE: FiltersState = {
    caregiverApp: {},
};

export const useAppDispatch: () => AppDispatch = useDispatch;
