import { Box, useMediaQuery, useTheme } from '@mui/material';
import { format, max, min, parse, subDays } from 'date-fns';
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { pxToRem } from 'components/theme/typography';

import CustomTooltip from './CustomTooltip';

type Props = {
    weightData: WeightMeasurement[] | [];
};

const WeightChart = ({ weightData }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!weightData.length) {
        return null;
    }

    const dataWithParsedDates = weightData.map((weightMeasurement: WeightMeasurement) => ({
        ...weightMeasurement,
        recordedDate: parse(weightMeasurement.recordedDate, 'yyyy-MM-dd', new Date()),
    }));

    const today = new Date();

    const minDate = subDays(today, 91);
    const maxDate = subDays(today, 1);

    const numTicks = isMobile ? 4 : 6;

    const tickValues = Array.from(
        { length: numTicks },
        (_, index) => new Date(minDate.getTime() + (index / (numTicks - 1)) * (maxDate.getTime() - minDate.getTime()))
    );

    return (
        <Box position="relative" width={1} height={pxToRem(165)} mb={pxToRem(24)}>
            <Box
                height={1}
                mr={{ xs: pxToRem(24) }}
                position="absolute"
                sx={{
                    inset: 0,
                    left: '-15px',
                }}
            >
                <ResponsiveContainer width="99%" height="100%">
                    <LineChart data={dataWithParsedDates}>
                        <CartesianGrid strokeDasharray="4 4" vertical={false} strokeOpacity={0.5} />
                        <XAxis
                            dataKey="recordedDate"
                            fontSize={12}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={11}
                            interval="preserveStartEnd"
                            type="number"
                            domain={[minDate.getTime(), maxDate.getTime()]}
                            ticks={weightData.length === 1 ? undefined : tickValues.map((tick) => tick.getTime())}
                            tickFormatter={(value) => format(value, 'M/d')}
                        />
                        <YAxis dataKey="weight" fontSize={12} axisLine={false} tickLine={false} tickMargin={11} />
                        <Tooltip content={<CustomTooltip />} />
                        <Line type="linear" dataKey="weight" stroke="#006B75" dot={{ fill: '#006B75' }} />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default WeightChart;
