import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { InputSize, InputVariant, SelectStructure, SelectValue } from 'types/inputs';

import { pxToRem } from 'components/theme/typography';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    autoWidth: {
        width: 'auto',
    },
});

const FormControlStyle = styled(FormControl)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        mb: pxToRem(16),
    })
);

type Props = {
    id: string;
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    label: string;
    multiple?: boolean;
    options: SelectStructure[];
    size?: InputSize;
    value: SelectValue;
    variant?: InputVariant;
    inputProps?: { [key: string]: string };
    onChange: (newValue: SelectValue) => void;
    sx?: SxProps<Theme>;
    truncate?: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomSelect = (props: Props) => {
    const classes = useStyles();
    const {
        id,
        className,
        disabled,
        fullWidth,
        label,
        multiple,
        options,
        size = 'small',
        value,
        variant = 'outlined',
        inputProps = {},
        onChange,
        sx = [],
        truncate = false,
    } = props;
    const [singleValue, setSingleValue] = useState<string>();
    const [multipleValue, setMultipleValue] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<SelectValue>) => {
        const { value: selectedValue } = event.target;
        const newValue = multiple && typeof selectedValue === 'string' ? selectedValue.split(',') : selectedValue;

        if (multiple) {
            setMultipleValue(newValue as string[]);
        } else {
            setSingleValue(newValue as string);
        }

        onChange(newValue);
    };

    useEffect(() => {
        if (multiple) {
            setMultipleValue(value as string[]);
        } else {
            setSingleValue(value as string);
        }
    }, [value]);

    if (!singleValue || !multipleValue) {
        return null;
    }

    return (
        <FormControlStyle
            className={fullWidth ? classes.fullWidth : classes.autoWidth}
            variant={variant}
            size={size}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
        >
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                className={className}
                label={label}
                value={multiple ? multipleValue : singleValue}
                multiple={multiple}
                size={size}
                disabled={disabled}
                inputProps={inputProps}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {truncate ? (
                            <Box
                                component="span"
                                sx={{
                                    overflow: 'hidden',
                                    maxWidth: { xs: '100%', lg: '200px' },
                                    textOverflow: 'ellipsis',
                                    width: { xs: '100%', lg: '200px' },
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {option.label}
                            </Box>
                        ) : (
                            option.label
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControlStyle>
    );
};
