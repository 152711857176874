import { Box, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';

import { pxToRem } from 'components/theme/typography';
import { parseAndFormatDate } from 'pages/ResidentDashboard/helpers';

import { AvatarWithName } from './AvatarWithName';
import { DateAndTime } from './DateAndTime';
import { Note } from './Note';

type Props = {
    row: AssessmentModuleDefaultRow | TaskNote | ResidentNote;
};

export const DefaultRowContent = ({ row }: Props) => {
    const { caregiver, note } = row;
    const { status, isComplete, shiftDayDate, shiftName } = row as AssessmentModuleDefaultRow;
    const { taskName } = row as AssessmentModuleDefaultRow | TaskNote;
    const { datetime, category } = row as ResidentNote;
    const { completedDatetime, taskStatus } = row as TaskNote;

    const createTimeStamp = () => {
        if (datetime) {
            return parseAndFormatDate(datetime);
        }

        if (completedDatetime) {
            return parseAndFormatDate(completedDatetime);
        }

        if (shiftDayDate) {
            const parsedDate = parse(shiftDayDate, 'yyyy-MM-dd', new Date());
            const formattedDate = format(parsedDate, 'MM/dd/yy');
            return `${formattedDate} (${shiftName})`;
        }

        return '';
    };

    const statusToShow = status || taskStatus || '';
    const isCompleteToShow = isComplete || taskStatus === 'Completed';
    const taskNameOrCategory = taskName || category || '';

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                {statusToShow && (
                    <>
                        <Typography
                            fontSize={{
                                xs: pxToRem(12),
                                md: pxToRem(14),
                            }}
                            fontWeight={700}
                            lineHeight={1.7}
                            color={isCompleteToShow ? 'primary.main' : '#EC3232'}
                        >
                            {statusToShow}
                        </Typography>
                        {caregiver && <AvatarWithName caregiver={caregiver} />}
                    </>
                )}
            </Box>
            {taskNameOrCategory && (
                <Box display="flex" justifyContent="space-between">
                    <Typography
                        fontSize={{
                            xs: pxToRem(12),
                            md: pxToRem(14),
                        }}
                        fontWeight={700}
                        lineHeight={1.7}
                        mb={pxToRem(2)}
                        textAlign="left"
                    >
                        {taskNameOrCategory}
                    </Typography>
                    {caregiver && !statusToShow && <AvatarWithName caregiver={caregiver} />}
                </Box>
            )}
            <DateAndTime timeStamp={createTimeStamp()} />
            {note && <Note note={note} sx={{ mt: pxToRem(8) }} />}
        </>
    );
};
