import { useCallback } from 'react';

export const useChartClick = (setSelectedStartDate: ((date: string) => void) | undefined) =>
    useCallback(
        (e) => {
            if (
                !setSelectedStartDate ||
                typeof e !== 'object' ||
                e === null ||
                !('activeLabel' in e) ||
                e.activeTooltipIndex === 0
            ) {
                return;
            }
            setSelectedStartDate(e.activeLabel);
        },
        [setSelectedStartDate]
    );
