import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

import { NoDataIcon } from 'components/Svg';
import { pxToRem } from 'components/theme/typography';
import { RowComponentProps, RowData } from 'pages/ResidentDashboard/constants';

import { DefaultRowContent } from './DefaultRowContent';

type Props = {
    RowComponent?: React.ComponentType<RowComponentProps>;
    rowData: RowData[];
    hasTimeline?: boolean;
    hasChart?: boolean;
    hasWeightChart?: boolean;
    isNotesModule?: boolean;
};

const CONTENT_HEIGHT = 438;

const NOTES_MODULE_CONTENT_HEIGHT = CONTENT_HEIGHT + 100;

const TIMELINE_HEIGHT = 66;

const TASKS_CHART_HEIGHT = 220;

const WEIGHT_CHART_HEIGHT = 189;

const ICON_SIZE = 48;

export const ModuleContent = ({
    RowComponent = DefaultRowContent,
    rowData,
    hasTimeline,
    hasChart,
    hasWeightChart,
    isNotesModule,
}: Props) => {
    let contentHeight = CONTENT_HEIGHT;
    if (hasTimeline) {
        contentHeight -= TIMELINE_HEIGHT;
    }
    if (hasChart) {
        contentHeight -= TASKS_CHART_HEIGHT;
    }
    if (isNotesModule) {
        contentHeight = NOTES_MODULE_CONTENT_HEIGHT;
    }
    if (hasWeightChart) {
        contentHeight -= WEIGHT_CHART_HEIGHT;
    }

    return (
        <Box
            py={3}
            px={pxToRem(20)}
            height={{
                xl: !rowData?.length ? pxToRem(CONTENT_HEIGHT) : pxToRem(contentHeight),
            }}
            overflow="auto"
            sx={[
                !rowData?.length && {
                    display: 'grid',
                    placeContent: 'center',
                    justifyItems: 'center',
                },
            ]}
        >
            {rowData?.length ? (
                rowData.map((row, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index}>
                        <RowComponent
                            row={row}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            id={(row as any)?.id || ''}
                        />
                        {index < rowData.length - 1 && (
                            <Divider
                                sx={{
                                    my: pxToRem(12),
                                }}
                            />
                        )}
                    </Box>
                ))
            ) : (
                <>
                    <Box
                        width={pxToRem(ICON_SIZE)}
                        height={pxToRem(ICON_SIZE)}
                        bgcolor="grey.200"
                        color="#424350"
                        display="grid"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius={1}
                        mb={pxToRem(20)}
                        mt={{ xs: pxToRem(20), xl: 0 }}
                    >
                        <NoDataIcon />
                    </Box>

                    <Typography
                        fontStyle="italic"
                        color="#85858E"
                        fontSize={{ xs: pxToRem(12), lg: pxToRem(14) }}
                        mb={{ xs: pxToRem(20), xl: 0 }}
                    >
                        There is no data during this time period
                    </Typography>
                </>
            )}
        </Box>
    );
};
