import { Box, Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useLoginInitMutation } from 'api/queries/auth/login';
import allieLogo from 'assets/allie_logo.svg';
import AuthButton from 'components/Auth/AuthButton';
import AuthCtaLink from 'components/Auth/AuthCtaLink';
import AuthDivider from 'components/Auth/AuthDivider';
import AuthInput from 'components/Auth/AuthInput';
import AuthTitle from 'components/Auth/AuthTitle';
import FormContainer from 'components/Auth/FormContainer';
import MainContainer from 'components/Auth/MainContainer';
import { pxToRem } from 'components/theme/typography';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

import { PhoneDisclaimer } from './PhoneDisclaimer';

export type LoginInitFormValues = {
    phoneNumber: string;
};

const defaultValues = {
    phoneNumber: '',
};

const Login = () => {
    const navigate = useNavigate();

    const { mutate: loginInitMutation } = useLoginInitMutation();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<LoginInitFormValues>({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        delayError: 2000,
    });

    const onSubmit = (data: LoginInitFormValues) => {
        const phoneNumber = data.phoneNumber.replace(/(?!^\+)\D/g, '');
        loginInitMutation({
            phoneNumber,
        });
    };

    const handleUsernameSignInClick = () => {
        navigate('/login/username');
    };

    return (
        <PageStructure>
            <MainContainer>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            lg: 'flex',
                        },
                        justifyContent: 'center',
                    }}
                >
                    <Box component="img" src={allieLogo} alt="AllieHealth Logo" width={pxToRem(176)} />
                </Box>
                <FormContainer>
                    <Box
                        sx={{
                            mb: {
                                lg: pxToRem(44),
                            },
                        }}
                    >
                        <Box
                            component="img"
                            src={allieLogo}
                            alt="AllieHealth Logo"
                            width={pxToRem(144)}
                            sx={{
                                display: {
                                    lg: 'none',
                                },
                            }}
                        />
                        <Box
                            sx={{
                                mt: {
                                    xs: pxToRem(48),
                                    lg: 0,
                                },
                                mb: {
                                    xs: pxToRem(36),
                                },
                            }}
                        >
                            <AuthTitle title="Welcome Back!" subtitle="Sign in now to access your account" />
                        </Box>
                        <AuthInput
                            name="phoneNumber"
                            label="Phone Number"
                            control={control}
                            placeholder="Enter Phone Number"
                            type="text"
                            error={errors.phoneNumber}
                            rules={{
                                required: 'Phone Number is required',
                                minLength: {
                                    value: 8,
                                    message: 'Phone Number must be at least 8 characters',
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            pb: {
                                xs: pxToRem(24),
                                lg: 0,
                            },
                        }}
                    >
                        <PhoneDisclaimer />
                        <AuthButton label="Continue" onClick={handleSubmit(onSubmit)} isDisabled={!isValid} />
                        <AuthDivider />
                        <Button
                            variant="outlined"
                            onClick={handleUsernameSignInClick}
                            fullWidth
                            disableRipple
                            sx={{
                                py: pxToRem(16),
                                mb: {
                                    xs: pxToRem(48),
                                },
                                fontSize: {
                                    xs: pxToRem(16),
                                },
                                lineHeight: {
                                    xs: 1.5,
                                },
                                fontWeight: 400,
                                color: '#6F6F79',
                                borderColor: '#DEDEE0',
                                textTransform: 'none',
                                '&:hover': {
                                    bgcolor: 'transparent',
                                },
                                '&:focus-visible': {
                                    borderColor: 'transparent',
                                    outline: '2px solid #006B75',
                                },
                            }}
                        >
                            Sign in with Username
                        </Button>
                        <AuthCtaLink
                            question="Don't have an account yet?"
                            linkLabel="Sign up here"
                            linkUrl="signup"
                            isLink
                        />
                    </Box>
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export const LoginNew = AccessControl(Login);
