import isDeepEqual from 'fast-deep-equal/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ResidentTasks } from 'types/dailyTasks';

type Props = {
    residentTasksList: ResidentTasks[];
    setSelectedResidentIdForAddedTaskDialog: (id?: number) => void;
    render: (
        openedResidentId: number | null,
        selectedResidentId: number | null,
        selectedTaskIds: Set<number>,
        onResidentToggle: (number) => void,
        onResidentSelect: (number) => void,
        onResidentTaskSelect: (number) => void
    ) => JSX.Element;
};

// TODO: refactor this and use context instead of render props and passing down props for a simplified data flow
export const MultiSelectProvider = (props: Props) => {
    const { residentTasksList, render, setSelectedResidentIdForAddedTaskDialog } = props;

    // to reduce the number of renders we are using useRef and comparing the object values directly
    const residentTasksListRef = useRef(residentTasksList);

    if (!isDeepEqual(residentTasksListRef.current, residentTasksList)) {
        residentTasksListRef.current = residentTasksList;
    }

    const [openedResidentId, setOpenedResidentId] = useState<number | null>(null);
    const [selectedResidentId, setSelectedResidentId] = useState<number | null>(null);
    const [selectedTaskIds, setSelectedTaskIds] = useState<Set<number>>(new Set());

    useEffect(() => {
        if (openedResidentId) {
            setSelectedResidentIdForAddedTaskDialog(openedResidentId);
        } else {
            setSelectedResidentIdForAddedTaskDialog();
        }
    }, [openedResidentId]);

    // when the resident task list changes reset what is selected
    useEffect(() => {
        setSelectedResidentId(null);
        setSelectedTaskIds(new Set());
    }, [residentTasksListRef.current]);

    const taskIdsByResidentId = useMemo(
        () =>
            residentTasksListRef.current.reduce((acc, { id, tasks }) => {
                acc[id] = tasks.map((task) => task.dailyTaskRecordId);

                return acc;
            }, {}),
        [residentTasksListRef.current]
    );

    const taskIdToResidentId = useMemo(
        () =>
            residentTasksListRef.current.reduce((acc, { id, tasks }) => {
                tasks.forEach((task) => {
                    acc[task.dailyTaskRecordId] = id;
                });

                return acc;
            }, {}),
        [residentTasksListRef.current]
    );

    const onResidentToggle = (residentId) => {
        if (openedResidentId === residentId) {
            setOpenedResidentId(null);
        } else {
            setOpenedResidentId(residentId);
        }
    };

    const onResidentSelect = (residentId) => {
        if (residentId === selectedResidentId) {
            // if resident was already selected - unselect them.
            setSelectedResidentId(null);
            setSelectedTaskIds(
                (previousState) =>
                    new Set(
                        [...previousState].filter((currentTaskId) => taskIdToResidentId[currentTaskId] !== residentId)
                    )
            );
        } else {
            const previousResidentId = selectedResidentId;

            setSelectedResidentId(residentId);
            const residentTaskIds = taskIdsByResidentId[residentId];
            setSelectedTaskIds(new Set(residentTaskIds));
        }

        // uncollapse the resident tasks if they are collapsed
        setOpenedResidentId(residentId);
    };

    const onResidentTaskSelect = (taskId) => {
        if (taskIdToResidentId[taskId] !== selectedResidentId) {
            setSelectedResidentId(taskIdToResidentId[taskId]);
            setSelectedTaskIds(new Set([taskId]));
        } else if (selectedTaskIds.has(taskId)) {
            setSelectedTaskIds(
                (previousState) => new Set([...previousState].filter((currentId) => currentId !== taskId))
            );
            // if this is the last selected task then deselect the resident
            if (selectedTaskIds.size === 1) {
                setSelectedResidentId(null);
            }
        } else {
            setSelectedTaskIds((previousState) => new Set([...previousState, taskId]));
        }
    };

    return render(
        openedResidentId,
        selectedResidentId,
        selectedTaskIds,
        onResidentToggle,
        onResidentSelect,
        onResidentTaskSelect
    );
};
