import { DEFAULT_SHIFT_OPTIONS, get24hPeriodEndingAtEndOfCurrentShift } from '@alliehealth/utils/dist/shifts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSound from 'use-sound';

import { MessageProps } from 'types/messages';
import { ReduxStore } from 'types/redux';
import { ResidentShiftNotesReadParams } from 'types/residentShiftNotes';

import { useResidentDailyTasksQuery, useResidentQuery } from 'api/queries/residents';
import notificationSound from 'assets/notification-sound.mp3';
import yaySound from 'assets/yay-sound.mp3';
import CustomConfetti from 'components/Custom/CustomConfetti';
import Loading from 'components/Shared/Loading';
import AccessControl from 'helpers/AccessControl';
import { getFormattedDateTimeMinusOneMinute } from 'lib/common';
import PageStructure from 'pages/PageStructure';
import ResidentChipsContainer from 'pages/Residents/Details/ChipsContainer';
import ResidentPersonalInfoContainer from 'pages/Residents/Details/PersonalInfoContainer';
import ShiftNotesContainer from 'pages/Residents/Details/ShiftNotesContainer';
import ResidentTasksContainer from 'pages/Residents/Details/TasksContainer';
import ResidentTopContainer from 'pages/Residents/Details/TopContainer';
import { hideAlert, showAlert } from 'redux/actions/messages';
import { readResidentShiftNotes } from 'redux/actions/residentShiftNotes';
import { toggleResidentParty } from 'redux/actions/residents';

import { RecentEvents } from './RecentEvents';

type Props = {
    displayParty: boolean;
    timezone: string;
    dispatchReadResidentShiftNotes: (params: ResidentShiftNotesReadParams) => void;
    dispatchToggleResidentParty: () => void;
};

const ResidentDetails = (props: Props) => {
    const { residentId } = useParams() || {};
    const { displayParty, timezone, dispatchReadResidentShiftNotes, dispatchToggleResidentParty } = props;
    const [party, setParty] = useState<boolean>(false);
    const [playYaySound, { stop }] = useSound(yaySound);
    const { startPeriodInclusive, endPeriodExclusive } = get24hPeriodEndingAtEndOfCurrentShift(
        timezone,
        DEFAULT_SHIFT_OPTIONS
    );
    const [playCoinSound] = useSound(notificationSound);

    const { refetch: residentDailyTasksRefetch } = useResidentDailyTasksQuery(Number(residentId));

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(Number(residentId));

    const handleTasksComplete = () => {
        if (displayParty) {
            playYaySound();
            setParty(true);
            dispatchToggleResidentParty();
        }
    };

    const triggerResidentActions = () => {
        residentDailyTasksRefetch();
    };

    useEffect(() => {
        if (residentId) {
            triggerResidentActions();
        }
    }, [residentId]);

    useEffect(() => {
        // Stop the Yay sound if it has been reproducing.
        stop();

        const updatedEndPeriodExclusive = getFormattedDateTimeMinusOneMinute(endPeriodExclusive);

        // Define the params for fetching the Resident's Info Reports.
        const params = {
            residentId: Number(residentId),
            startPeriod: startPeriodInclusive,
            endPeriod: updatedEndPeriodExclusive,
        };
        dispatchReadResidentShiftNotes(params);
    }, []);

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const residentName = `${residentData?.firstName} ${residentData?.lastName}`;

    return (
        <PageStructure>
            <ResidentTopContainer residentData={residentData} />
            <ResidentChipsContainer
                residentId={residentId}
                displayPartyOnSubmit={() => {
                    playCoinSound();
                    setParty(true);
                }}
            />
            <ShiftNotesContainer residentId={residentId} />
            <ResidentPersonalInfoContainer personalInfo={residentData?.personalInfo} />
            <RecentEvents events={residentData?.recentEvents} />
            <ResidentTasksContainer
                residentId={Number(residentId)}
                onComplete={handleTasksComplete}
                residentName={residentName}
            />
            <CustomConfetti activate={party} stop={() => setParty(false)} />
        </PageStructure>
    );
};

const mapStateToProps = ({ residents, session }: ReduxStore) => {
    const { displayParty } = residents;
    const { timezone } = session;

    return {
        displayParty,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadResidentShiftNotes: (params: ResidentShiftNotesReadParams) => dispatch(readResidentShiftNotes(params)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
    dispatchShowAlert: (message: MessageProps) => dispatch(showAlert(message)),
    dispatchHideAlert: () => dispatch(hideAlert()),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentDetails: any = AccessControl(connect(mapStateToProps, mapDispatchToProps)(ResidentDetails));

export default ConnectedResidentDetails;
