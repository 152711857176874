import { Box } from '@mui/material';
import React, { useState } from 'react';
import { FaMobile } from 'react-icons/fa';

import { useLoginVerifyMutation } from 'api/queries/auth/login';
import AuthButton from 'components/Auth/AuthButton';
import AuthCtaLink from 'components/Auth/AuthCtaLink';
import AuthIcon from 'components/Auth/AuthIcon';
import AuthTitle from 'components/Auth/AuthTitle';
import CodeInput from 'components/Auth/CodeInput';
import FormContainer from 'components/Auth/FormContainer';
import MainContainer from 'components/Auth/MainContainer';
import Return from 'components/Auth/Return';
import { pxToRem } from 'components/theme/typography';
import AccessControl from 'helpers/AccessControl';
import PageStructure from 'pages/PageStructure';

const CodeVerification = () => {
    const [verificationCode, setVerificationCode] = useState('');

    const { mutate: loginVerifyMutation, isLoading } = useLoginVerifyMutation();

    const handleCodeChange = (code: string) => {
        setVerificationCode(code);
    };

    const handleVerifyCode = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const phoneNumber = decodeURIComponent(searchParams.get('phoneNumber') || '');

        loginVerifyMutation({
            phoneNumber: phoneNumber || '',
            token: verificationCode,
        });
    };

    const handleResendCodeClick = () => {
        handleVerifyCode();
    };

    return (
        <PageStructure>
            <MainContainer>
                <FormContainer>
                    <Box
                        sx={{
                            mb: {
                                lg: pxToRem(44),
                            },
                        }}
                    >
                        <Return returnToUrl="login" />
                        <AuthIcon icon={<FaMobile size={36} />} />
                        <Box
                            sx={{
                                mt: {
                                    xs: pxToRem(48),
                                },
                                mb: {
                                    xs: pxToRem(24),
                                },
                            }}
                        >
                            <AuthTitle
                                title="Code Verification"
                                subtitle="A verification code has been sent to your phone
                            number"
                                align="center"
                            />
                        </Box>
                        <CodeInput onCodeChange={handleCodeChange} code={verificationCode} />
                        <AuthCtaLink
                            question="Didn't receive the code?"
                            linkLabel="Send again."
                            onClick={handleResendCodeClick}
                        />
                    </Box>
                    <AuthButton
                        label="Verify"
                        onClick={handleVerifyCode}
                        isDisabled={verificationCode.length !== 4 || isLoading}
                    />
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export const CodeVerificationStep = AccessControl(CodeVerification);
