import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { SelectStructure } from 'types/inputs';
import { LanguageStructure } from 'types/language';

import { CustomSelect } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    dictionary: LanguageStructure;
    fullWidth?: boolean;
    maxWidth?: number | string;
    width?: number | string;
    onChange: (newStatus: string) => void;
};

const ResidentStatusSelectorFilter = (props: Props) => {
    const {
        dictionary: { residents },
        fullWidth,
        maxWidth = pxToRem(264),
        width = '48%',
        onChange,
    } = props;
    const [statusOptions, setStatusOptions] = useState<SelectStructure[]>([]);
    const [currentStatus, setCurrentStatus] = useState<string>('all');

    const handleFilterChange = (newStatus: string) => {
        setCurrentStatus(newStatus);
        onChange(newStatus);
    };

    useEffect(() => {
        setStatusOptions([
            {
                label: 'All',
                value: 'all',
            },
            {
                label: residents.statusInBuilding,
                value: 'In Building',
            },
            {
                label: residents.statusOutOfBuilding,
                value: 'Out Of Building',
            },
            {
                label: residents.statusLeft,
                value: 'Left',
            },
        ]);
    }, []);

    return (
        <Box
            sx={{
                width,
                maxWidth,
            }}
        >
            <CustomSelect
                id="status"
                label={residents.buildingStatus}
                value={currentStatus as string}
                options={statusOptions}
                fullWidth={fullWidth}
                onChange={handleFilterChange}
            />
        </Box>
    );
};

const mapStateToProps = ({ language }) => {
    const { dictionary } = language;

    return {
        dictionary,
    };
};

export const ResidentStatusSelector = connect(mapStateToProps)(ResidentStatusSelectorFilter);
