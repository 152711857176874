import { api } from 'api';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { getToken } from 'lib/common';

export const useDocumentationActionsQuery = (params) =>
    useErrorHandledQuery(
        ['documentation-actions', params],
        async () => {
            const { data } = await api.get(`/tasks/documentation-actions`, {
                headers: { authorization: getToken() },
                params,
            });
            return data.response;
        },
        { enabled: !!params.residentId, staleTime: 300000 }
    );
