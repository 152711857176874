import { Add, CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';

import { FormattedText } from 'components/Shared/FormattedText';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';

const CheckIcon = () => <CheckCircleOutlined sx={{ fontSize: pxToRem(20), transform: 'translateY(3px)' }} />;

const RejectIcon = () => <CancelOutlined sx={{ fontSize: pxToRem(20), transform: 'translateY(3px)' }} />;

const CollapseIcon = () => <FaAngleDown style={{ fontSize: pxToRem(20), transform: 'translateY(5px)' }} />;

const GoldCoinIcon = () => (
    <Box component="span" sx={{ '& > *': { transform: 'translateY(3px)' } }}>
        <GoldCoin size={20} viewBox="0 0 20 20" />
    </Box>
);

const AddIcon = () => (
    <Box
        component="span"
        sx={{
            backgroundColor: 'primary.main',
            width: pxToRem(20),
            height: pxToRem(20),
            borderRadius: '50%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translateY(2px)',
        }}
    >
        <Add
            sx={{
                fontSize: pxToRem(16),
                color: 'common.white',
            }}
        />
    </Box>
);

const CheckInButton = () => (
    <Box
        component="span"
        sx={{
            borderRadius: pxToRem(4),
            fontWeight: 700,
            fontSize: pxToRem(14),
            color: 'primary.main',
            px: pxToRem(6),
            py: pxToRem(2),
            mx: pxToRem(4),
            outline: '1px solid #BDBDC3',
        }}
    >
        Check In
    </Box>
);

export const REWARD_TYPE_DETAILS_DIALOG_DATA = {
    DOCUMENTATION_ON_TIME: {
        title: 'Documentation On-Time',
        items: [
            {
                subtitle: (
                    <>
                        How to earn <FormattedText text="Documentation Completion" underline /> points:
                    </>
                ),
                list: [
                    'Complete documentation for all scheduled tasks of the residents you take care of.',
                    "AND finish the current shift's documentation BEFORE the end of the shift.",
                ],
            },
            {
                subtitle: 'How to Document a Task:',
                list: [
                    <>
                        To document a task, simply click <CheckIcon /> or <RejectIcon />. If the task is incomplete,
                        please click <RejectIcon /> and select a reason.
                    </>,
                    <>
                        Pro tip: on the Home tab, you can document multiple tasks of a resident at once. Simply select
                        multiple tasks or all tasks of a resident. Click on the <CheckIcon /> or <RejectIcon /> at the
                        bottom of this resident&apos;s task list
                    </>,
                ],
            },
            {
                subtitle: 'Where to Document Tasks:',
                content: [
                    <>
                        You can document tasks on the <FormattedText text="Home tab" bold={700} /> or on{' '}
                        <FormattedText text="the individual resident's page" bold={700} />. After you document a task,
                        the task will move from the <FormattedText text="pending queue" bold={700} /> to the{' '}
                        <FormattedText text="confirmed queue" bold={700} />.
                    </>,
                ],
            },
        ],
    },
    ON_TIME_CHECK_IN: {
        title: 'On-Time Check-In',
        items: [
            {
                subtitle: 'How to earn Shift Check-in points:',
                content: [
                    <>
                        Simply tap the <CheckInButton /> button on the MY REWARDS Tab (top right corner) within the
                        first 15 minutes of your shift.
                    </>,
                    <Box
                        component="span"
                        sx={{
                            display: 'block',
                            mt: pxToRem(8),
                        }}
                    >
                        For example, if your shift starts at 6:00 am, you should check in between 6:00 am and 6:15 am.
                    </Box>,
                ],
            },
            {
                subtitle: 'To be eligible for check-in points (20 points per shift check-in), make sure to:',
                list: [
                    'Check in within the first 15 minutes of your shift.',
                    'Be physically present in the community.',
                    'Check in only one time per shift, for the shift you are currently on.',
                ],
            },
            {
                subtitle:
                    'Thank you for serving your community, documenting your care, and being kind. We appreciate you.',
            },
        ],
    },
    UNSCHEDULED_TASKS_RECORDED: {
        title: 'Unscheduled Tasks Recorded',
        items: [
            {
                subtitle: (
                    <>
                        How to earn <FormattedText text="PRN Care" underline /> points:
                    </>
                ),
                content: [
                    'You earn points by recording the PRN care tasks you provided to the residents you served this shift. You will earn points for every valid PRN care task you record.',
                ],
            },
            {
                subtitle: 'Two ways to record a PRN Care for a resident:',
                list: [
                    <>
                        On the Home page or the Resident page – Click on <CollapseIcon /> on the right side of the
                        screen to expand the resident info. Click on the green “
                        <GoldCoinIcon /> Add Unscheduled/PRN Task” button. Search or select a PRN task. Submit.
                    </>,
                    <>
                        On the Home page – Click on the <AddIcon /> button on the bottom right of the screen. Select a
                        resident and a PRN care task. Submit.
                    </>,
                ],
            },
            {
                subtitle: 'How are those points calculated:',
                content: [
                    "The points per PRN task varies based on the complexity of the task. We want to appreciate your hard work. That's it.",
                ],
            },
        ],
    },
    RESIDENT_NOTES_SHARED: {
        title: 'Resident Notes Shared',
        items: [
            {
                subtitle: (
                    <>
                        What to do to earn <FormattedText text="Resident Note" underline /> points:
                    </>
                ),
                content: [
                    'You earn points by submitting a Resident Note to share information regarding a resident (e.g. health concerns, behavior changes, etc.). Please use full sentence(s). Make sure the note is clear and comprehensive for others to understand.',
                ],
            },
            {
                subtitle: 'Where to Add a Resident Note:',
                list: [
                    <>
                        On the Home page – Click on <CollapseIcon /> on the right side of the screen to expand the
                        resident info. Click on the green “Add Resident Note” button. Select a note category. Type or
                        voice record a note.
                    </>,
                    <>
                        On the Resident page – Click on the resident’s photo. Click on the green “Add Resident Note”
                        button. Select a note category. Type or voice record a note.
                    </>,
                ],
            },
            {
                subtitle: 'How to Add a Resident Note:',
                content: [
                    'You can add a Resident Note through typing or voice recording. Yes! You can speak to the phone to document a note in English or other languages!',
                    'To voice record a note: Click the green microphone button to start. Allow the app to activate the microphone for recording. Make sure to double-check that the script is correct before submitting.',
                ],
            },
        ],
    },
} as const;

export const ON_TIME_CHECK_IN_POINTS = 20;
