import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { TooltipProps } from 'recharts';

import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { pxToRem } from 'components/theme/typography';

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
        const { weight, recordedDate } = payload[0].payload;

        const formattedDate = format(recordedDate, 'MM/dd/yy');

        return (
            <Box
                sx={{
                    border: 'none',
                    backgroundColor: '#FFF',
                    borderRadius: pxToRem(8),
                    boxShadow: '0px 4px 4px rgba(139, 139, 139, 0.25)',
                    fontFamily: 'Open Sans',
                    color: '#6F6F79',
                    px: pxToRem(8),
                    py: pxToRem(4),
                }}
            >
                <Typography
                    sx={{
                        fontSize: {
                            xs: '12px',
                        },
                        fontWeight: 700,
                    }}
                >
                    {weight} lbs
                </Typography>
                <Typography
                    sx={{
                        fontSize: {
                            xs: '10px',
                        },
                    }}
                >
                    Date: {formattedDate}
                </Typography>
            </Box>
        );
    }

    return null;
};

export default CustomTooltip;
