import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { SectionDetail } from 'types/operationsDashboard';

import { pxToRem } from 'components/theme/typography';

type DetailsRowProps = {
    detailsData: SectionDetail[];
    renderDetailItem: (item: SectionDetail) => ReactNode;
};

export const DetailsRow = ({ detailsData, renderDetailItem }: DetailsRowProps) => (
    <Box sx={{ width: '100%' }}>
        {detailsData.map((item, index) => (
            <Box
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(50px, 1fr))',
                    columnGap: pxToRem(10),
                    borderBottom: '1px solid #E3E3E3',
                    '&:last-child': {
                        borderBottom: 'none',
                    },
                    '& > div': {
                        py: pxToRem(10),
                        color: '#6F6F79',
                        textAlign: 'left',
                        fontSize: {
                            xs: pxToRem(12),
                            lg: pxToRem(14),
                        },
                        fontWeight: 400,
                    },
                }}
            >
                {renderDetailItem(item)}
            </Box>
        ))}
    </Box>
);
