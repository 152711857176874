import { Dialog } from '@capacitor/dialog';
import { ActionPerformed, PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReduxStore } from 'types/redux';
import { StoredSession } from 'types/session';

import { useUserDeviceMetadataMutation } from 'api/queries/user';
import { PROFILE } from 'constants/localStorage';

type NotificationData = {
    navigateTo: string;
};

const NotificationsHandler = () => {
    const {
        isSignedIn,
        sessionData: { roleLevelId },
    } = useSelector((state: ReduxStore) => state.session);

    const storedSession: StoredSession = JSON.parse(localStorage.getItem(PROFILE) as string);
    const storedIsSignedIn = storedSession?.isSignedIn;
    const isUserLogged = isSignedIn || storedIsSignedIn;
    const isManager = roleLevelId ? roleLevelId === 4 : false;

    const navigate = useNavigate();
    const { mutate: mutateUserDeviceMetadata } = useUserDeviceMetadataMutation();

    const [pushNotificationToken, setPushNotificationToken] = useState<string | null>(null);

    useEffect(() => {
        PushNotifications.addListener('registration', (token) => {
            console.log('User push notification token:', token.value);
            setPushNotificationToken(token.value);
        });

        PushNotifications.addListener('registrationError', (err) => {
            Sentry.captureMessage('Push notification registration error');
        });
    }, []);

    useEffect(() => {
        if (pushNotificationToken) {
            mutateUserDeviceMetadata(
                { token: pushNotificationToken },
                {
                    onError: (error) => {
                        Sentry.captureException(error);
                    },
                }
            );
        }
    }, [pushNotificationToken]);

    useEffect(() => {
        if (isUserLogged && isManager) {
            registerNotifications();
        }
    }, [isUserLogged, isManager]);

    // always listen for push notifications so that we don't have to wait
    // for permissions to load to react to a push notification deep link
    useEffect(() => {
        addNewPushNotificationListeners();
    }, []);

    const registerNotifications = async () => {
        const permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            await PushNotifications.requestPermissions();

            // This behavior is odd. When the user grants the permission, it seems like it takes a few moments
            // in order for the system to register that. So we wait a few seconds before checking again
            // and finishing the push notification setup
            setTimeout(async () => {
                const newPermStatus = await PushNotifications.checkPermissions();

                if (newPermStatus.receive === 'granted') {
                    await PushNotifications.register();
                }

                if (newPermStatus.receive !== 'granted') {
                    console.log('Push notifications not granted');
                }
            }, 3000);
        }
    };

    const addNewPushNotificationListeners = () => {
        // Notification received with the app running
        PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
            const { title, body } = notification;
            const { data }: { data: NotificationData } = notification;

            let message = body || '';

            if (data.navigateTo) {
                message += '\nNavigate to related page?';
                const { value: confirmed } = await Dialog.confirm({
                    title,
                    message,
                });

                if (confirmed) {
                    if (data.navigateTo) {
                        navigate(data.navigateTo);
                    }
                }
                return;
            }

            Dialog.alert({
                title,
                message,
            });
        });

        // Notification received with the app closed or in background
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
            const { data } = notification.notification;
            if (data.navigateTo) {
                navigate(data.navigateTo);
            }
        });
    };

    return null;
};

export default NotificationsHandler;
