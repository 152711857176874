import { DEFAULT_SHIFT_OPTIONS, getShiftAtDateTime } from '@alliehealth/utils/dist/shifts';
import { Capacitor } from '@capacitor/core';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { AnalyticsProvider } from 'analytics';
import momentTZ from 'moment-timezone';
import { AbilityLoader } from 'permissions/ability';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { LoadingBar } from 'react-redux-loading';
import { BrowserRouter } from 'react-router-dom';

import { DailyTasksShiftPeriod } from 'types/dailyTasks';

import AppUrlListener from '../helpers/AppUrlListener';
import { queryClient } from 'api/queries';
import SideMenu from 'components/Navigation/DesktopSideMenu';
import Header from 'components/Navigation/Header';
import MobileNavigation from 'components/Navigation/MobileMenu';
import Routing from 'components/Routing';
import Messages from 'components/Shared/Messages';
import ThemeConfig from 'components/theme';
import GlobalStyles from 'components/theme/globalStyles';
import { pxToRem } from 'components/theme/typography';
import { APP_NAME, GOOGLE_ID, SIDE_MENU_WIDTH } from 'constants/app';
import NotificationsHandler from 'helpers/NotificationsHandler';
import { updateShiftPeriod } from 'redux/actions/dailyTasks';
import { updateTimezone, validateSession } from 'redux/actions/session';

import { GeolocationProvider } from '../contexts/GeolocationContext';

const MainContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    })
);

const BodyContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', lg: `calc(100% - ${pxToRem(SIDE_MENU_WIDTH)})` },
        paddingTop: 'env(safe-area-inset-top)',
    })
);

type Props = {
    isSignedIn: boolean;
    timezone: string;
    dispatchUpdateShiftPeriod: (shiftPeriod: DailyTasksShiftPeriod) => void;
    dispatchUpdateTimezone: (timezone: string) => void;
    dispatchValidateSession: () => void;
};

const App = (props: Props) => {
    const { isSignedIn, timezone, dispatchUpdateShiftPeriod, dispatchUpdateTimezone, dispatchValidateSession } = props;

    const {
        shift: { startTimeInclusive, endTimeExclusive },
    } = getShiftAtDateTime(new Date(), timezone, DEFAULT_SHIFT_OPTIONS);

    useEffect(() => {
        const userTimezone = momentTZ.tz.guess();

        if (!timezone || timezone !== userTimezone) {
            dispatchUpdateTimezone(userTimezone);
        }

        dispatchValidateSession();
        dispatchUpdateShiftPeriod({ startTimeInclusive, endTimeExclusive });
    }, []);

    return (
        <AnalyticsProvider>
            <GoogleOAuthProvider clientId={GOOGLE_ID}>
                <Helmet defaultTitle={APP_NAME}>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"
                    />
                    <meta name="robots" content="no-index" />
                    <link rel="shortcut icon" href="/favicon.icon" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Raleway:wght@400;700&display=swap"
                    />
                </Helmet>
                <ThemeConfig>
                    <GlobalStyles />
                    <GeolocationProvider>
                        <QueryClientProvider client={queryClient}>
                            <AbilityLoader>
                                <BrowserRouter>
                                    {Capacitor.isNativePlatform() && <NotificationsHandler />}
                                    <AppUrlListener />
                                    <LoadingBar />
                                    <Messages />
                                    <MainContainer>
                                        <SideMenu />
                                        <BodyContainer>
                                            <Header />
                                            <Routing />
                                            <MobileNavigation />
                                        </BodyContainer>
                                    </MainContainer>
                                </BrowserRouter>
                            </AbilityLoader>
                        </QueryClientProvider>
                    </GeolocationProvider>
                </ThemeConfig>
            </GoogleOAuthProvider>
        </AnalyticsProvider>
    );
};

const mapStateToProps = ({ session }) => {
    const { isSignedIn, timezone } = session;

    return {
        isSignedIn,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateShiftPeriod: (shiftPeriod: DailyTasksShiftPeriod) => dispatch(updateShiftPeriod(shiftPeriod)),
    dispatchUpdateTimezone: (timezone: string) => dispatch(updateTimezone(timezone)),
    dispatchValidateSession: () => dispatch(validateSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
