import { Box, TextField, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Control, Controller, FieldError, RegisterOptions } from 'react-hook-form';

import { pxToRem } from 'components/theme/typography';

type Props = {
    name: string;
    label: string;
    control: Control<any>;
    type: string;
    placeholder: string;
    endIcon?: ReactNode;
    error?: FieldError;
    rules?: RegisterOptions;
};

const AuthInput = ({ name, label, control, type, placeholder, endIcon, error, rules }: Props) => (
    <Box>
        <Typography
            component="label"
            htmlFor={name}
            sx={{
                fontSize: { xs: pxToRem(14) },
                color: '#6F6F79',
                fontWeight: 600,
                lineHeight: 1.45,
                mb: { xs: pxToRem(8) },
                display: 'block',
            }}
        >
            {label}
        </Typography>
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={name}
                    placeholder={placeholder}
                    type={type}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        endAdornment: endIcon || null,
                    }}
                    error={!!error}
                    helperText={error ? error.message : ''}
                    FormHelperTextProps={{
                        sx: {
                            fontSize: { xs: pxToRem(12), lg: pxToRem(13) },
                        },
                    }}
                    sx={{
                        autoCapitalize: 'none',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#DEDEE0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#DEDEE0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#006B75',
                            },
                        },
                        '& .MuiInputBase-input': {
                            fontSize: pxToRem(16),
                            p: pxToRem(16),
                            lineHeight: 1.5,
                            '&::placeholder': {
                                color: '#B1B2B7',
                                opacity: 1,
                            },
                        },
                    }}
                />
            )}
        />
    </Box>
);

export default AuthInput;
