import CustomAreaChart from './components/Charts/CustomAreaChart';
import CustomBarChart from './components/Charts/CustomBarChart';
import CustomLineChart from './components/Charts/CustomLineChart';

export const TIME_PERIOD_NAMES = {
    day: 'Selected day',
    week: 'Selected week',
    month: 'Selected month',
};

export const CHANGE_PERIOD_NAMES = {
    day: 'Day over day',
    week: 'Week over week',
    month: 'Month over month',
};

export const LEVEL_COLUMN_NAMES = {
    company: 'Region',
    region: 'Community',
};

export type Section =
    | 'DOCUMENTATION_RATE'
    | 'CHANGE_OF_CONDITION'
    | 'FALL_DOCUMENTATION_INCOMPLETE'
    | 'FALLS'
    | 'HOSPITAL_TRANSFER'
    | 'OVERDUE_ASSESSMENT'
    | 'SHOWER'
    | 'UNSCHEDULED_CARE'
    | 'RESIDENT_NOTES';

export const OVERALL_SECTIONS_MAP = {
    DOCUMENTATION_RATE: {
        title: 'Documentation Rate',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    CHANGE_OF_CONDITION: {
        title: 'Change of Condition',
        chartType: 'stackedBar',
        ChartComponent: CustomBarChart,
    },
    FALL_DOCUMENTATION_INCOMPLETE: {
        title: 'Fall Documentation Incomplete',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    FALLS: {
        title: 'Falls',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    HOSPITAL_TRANSFER: {
        title: 'Hospital Transfer',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    OVERDUE_ASSESSMENT: {
        title: 'Overdue Assessment',
        chartType: 'bar',
        ChartComponent: CustomBarChart,
    },
    SHOWER: {
        title: 'Shower',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    UNSCHEDULED_CARE: {
        title: 'PRN/Unscheduled Care',
        chartType: 'area',
        ChartComponent: CustomAreaChart,
    },
    RESIDENT_NOTES: {
        title: 'Resident Notes',
        chartType: 'bar',
        ChartComponent: CustomBarChart,
    },
};

export const BRANCH_SECTIONS_MAP = {
    DOCUMENTATION_RATE: {
        title: 'Documentation Rate',
        chartType: 'line',
        chartValue: 'tasksCompleteCount',
        chartValue2: 'totalTasksCount',
        ChartComponent: CustomLineChart,
    },
    CHANGE_OF_CONDITION: {
        title: 'Change of Condition',
        chartType: 'stackedBar',
        chartValue: 'withAdlChanges',
        chartValue2: 'withoutAdlChanges',
        ChartComponent: CustomBarChart,
    },
    FALLS: {
        title: 'Falls',
        chartType: 'line',
        chartValue: 'fallCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    FALL_DOCUMENTATION_INCOMPLETE: {
        title: 'Fall Documentation Incomplete',
        chartType: 'line',
        chartValue: 'incompleteCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    HOSPITAL_TRANSFER: {
        title: 'Hospital Transfer',
        chartType: 'line',
        chartValue: 'hospitalVisitCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    OVERDUE_ASSESSMENT: {
        title: 'Overdue Assessment',
        chartType: 'bar',
        chartValue: 'overdueAssessmentCount',
        chartValue2: '',
        ChartComponent: CustomBarChart,
    },
    SHOWER: {
        title: 'Shower',
        chartType: 'line',
        chartValue: 'residentMissedShowerCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    UNSCHEDULED_CARE: {
        title: 'PRN/Unscheduled Care',
        chartType: 'area',
        chartValue: 'unscheduledCareCount',
        chartValue2: '',
        ChartComponent: CustomAreaChart,
    },
    RESIDENT_NOTES: {
        title: 'Resident Notes',
        chartType: 'bar',
        chartValue: 'residentNoteCount',
        chartValue2: '',
        ChartComponent: CustomBarChart,
    },
};

export type BranchSection = keyof typeof BRANCH_SECTIONS_MAP;

export const MOBILE_SELECTOR_STYLES = {
    '& .MuiOutlinedInput-root': {
        color: { xs: '#fff' },
        '&.Mui-focused fieldset': {
            borderColor: { xs: '#DEDEE0' },
        },
        '& fieldset': {
            borderColor: { xs: '#DEDEE0' },
        },
        '&:hover fieldset': {
            borderColor: { xs: '#DEDEE0' },
        },
    },
    '& .MuiSelect-icon.Mui-disabled': {
        opacity: 0,
    },
    '& label, & .MuiSelect-icon': {
        color: { xs: '#DEDEE0' },
        '&.Mui-focused': {
            color: { xs: '#DEDEE0' },
        },
    },
    '& .MuiOutlinedInput-input': {
        '&.Mui-disabled': {
            textFillColor: '#DEDEE0',
        },
    },
};

export const SELECTOR_STYLES = {
    '& .MuiSelect-icon.Mui-disabled': {
        opacity: 0,
    },
};

export interface StandardDataset {
    startDate: string;
    value: number;
}
