import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useRef, useState } from 'react';

import { pxToRem } from 'components/theme/typography';

const PopperStyled = styled(Popper)(({ theme }) =>
    theme.unstable_sx({
        zIndex: 10,
    })
);

const ActionButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        width: `calc(100% - ${pxToRem(44)})`,
    })
);

const SelectorButton = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(44),
    })
);

type Props = {
    options: string[];
    width?: string | number;
    onChange?: (optionIndex: number) => void;
    onClick: (optionIndex: number) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomDropdownButton = (props: Props) => {
    const { options, width = 'auto', onChange = () => null, onClick } = props;
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const anchorRef = useRef<HTMLDivElement>(null);
    const buttonGroupSx = { width };

    const handleButtonClick = () => onClick(selectedIndex);

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
        setOpenDropdown(false);
        onChange(index);
    };

    const handleToggleMenuItem = () => setOpenDropdown((prevOpenDropdown) => !prevOpenDropdown);

    const handleCloseMenuItem = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenDropdown(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={buttonGroupSx}>
                <ActionButton onClick={handleButtonClick}>{options[selectedIndex]}</ActionButton>
                <SelectorButton
                    size="small"
                    aria-controls={openDropdown ? 'split-button-menu' : undefined}
                    aria-expanded={openDropdown ? 'true' : undefined}
                    aria-label="select strategy"
                    aria-haspopup="menu"
                    onClick={handleToggleMenuItem}
                >
                    <ArrowDropDownIcon />
                </SelectorButton>
            </ButtonGroup>
            <PopperStyled open={openDropdown} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center-bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseMenuItem}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </PopperStyled>
        </>
    );
};
