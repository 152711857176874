import { api } from 'api';

import {
    DashboardCountersResponse,
    DashboardFilters,
    DashboardIncompletedOverdueByResidentsResponse,
    DashboardNotesResponse,
    DashboardReadParams,
} from 'types/dashboard';
import { MessageProps } from 'types/messages';

import { formatApiParams, getToken } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { showAlert, throwError } from 'redux/actions/messages';
import 'redux/reducers/dashboards';

/**
 * Updates the filters for the Dashboards.
 * @param newFilters
 */
export const updateFilters = (newFilters: DashboardFilters) => ({
    type: actionTypes.DASHBOARD_UPDATE_FILTERS,
    payload: newFilters,
});

/**
 * Fetches the Tasks and Caregivers counters and percentages.
 * @param jsonParams the JSON object with the params for fetching the Data info.
 */
export const readCounters = (jsonParams: DashboardReadParams) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, true, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Fetch the counters.
        const dashboardResponse: DashboardCountersResponse = (
            await api.get('/dashboard/counters', {
                headers: {
                    authorization: token,
                },
                params,
            })
        ).data.response;

        dispatch({
            type: actionTypes.DASHBOARD_READ_COUNTERS,
            payload: dashboardResponse,
        });
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, false));
    }
};

/**
 * Fetches the data for the Incompleted Tasks by Residents.
 * @param jsonParams the JSON object with the params for fetching the Data info.
 */
export const readIncompletedByResidents = (jsonParams: DashboardReadParams) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, true, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Fetch the data for the Fallen Residents.
        const dashboardResponse: DashboardIncompletedOverdueByResidentsResponse = (
            await api.get('/dashboard/incompleted-by-residents', {
                headers: {
                    authorization: token,
                },
                params,
            })
        ).data.response;

        dispatch({
            type: actionTypes.DASHBOARD_READ_INCOMPLETED_TASKS_BY_RESIDENTS,
            payload: dashboardResponse,
        });
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, false));
    }
};

/**
 * Fetches the data for the Notes Dashboard.
 * @param jsonParams the JSON object with the params for fetching the Data info.
 */
export const readNotes = (jsonParams: DashboardReadParams) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, true, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Fetch the data for the Fallen Residents.
        const dashboardResponse: DashboardNotesResponse[] = (
            await api.get('/dashboard/notes', {
                headers: {
                    authorization: token,
                },
                params,
            })
        ).data.response;

        dispatch({
            type: actionTypes.DASHBOARD_READ_NOTES,
            payload: dashboardResponse,
        });
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, false));
    }
};

export const readUnscheduledTasks = (jsonParams: DashboardReadParams) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, true, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Fetch the data for the Fallen Residents.
        const dashboardResponse: DashboardNotesResponse[] = (
            await api.get('/dashboard/unscheduled-tasks', {
                headers: {
                    authorization: token,
                },
                params,
            })
        ).data.response;

        dispatch({
            type: actionTypes.DASHBOARD_READ_UNSCHEDULED_TASKS,
            payload: dashboardResponse,
        });
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, false));
    }
};

/**
 * Fetches the data for the Overdue Tasks by Residents.
 * @param jsonParams the JSON object with the params for fetching the Data info.
 */
export const readOverdueByResidents = (jsonParams: DashboardReadParams) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, true, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Fetch the data for the Fallen Residents.
        const dashboardResponse: DashboardIncompletedOverdueByResidentsResponse = (
            await api.get('/dashboard/overdue-by-residents', {
                headers: {
                    authorization: token,
                },
                params,
            })
        ).data.response;

        dispatch({
            type: actionTypes.DASHBOARD_READ_OVERDUE_TASKS_BY_RESIDENTS,
            payload: dashboardResponse,
        });
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, false));
    }
};

/**
 * Fetches the data for the Performance by Caregivers.
 * @param jsonParams the JSON object with the params for fetching the Data info.
 */
export const readPerformance = (jsonParams: DashboardReadParams) => async (dispatch) => {
    try {
        // Enable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, true, true));

        // Get the token.
        const token = getToken();

        // Encode the params to be sent to the API.
        const params = formatApiParams(jsonParams);

        // Fetch the data for the Fallen Residents.
        const dashboardResponse: DashboardIncompletedOverdueByResidentsResponse = (
            await api.get('/dashboard/performance', {
                headers: {
                    authorization: token,
                },
                params,
            })
        ).data.response;

        dispatch({
            type: actionTypes.DASHBOARD_READ_PERFORMANCE,
            payload: dashboardResponse,
        });
    } catch (error) {
        dispatch(throwError(error));
    } finally {
        // Disable the loading process.
        dispatch(loading(actionTypes.DASHBOARD_LOADING, false));
    }
};

// TODO: delete this
// This is garbage - the endpoint doesn't exist,
// but there are still uses of it in the code that are very tricky to remove
export const updateCaregiver = (
    dailyTaskRecordId: number,
    jsonParams: { user_id: number },
    callbackSuccess: () => void
) => null;
